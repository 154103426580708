import { ReactElement } from 'react';
import { TextField } from 'react-admin';
import renderMetaDataFields from '../common/helpers/renderMetaDataFields';
import DatagridConfigurableRbac from '../common/DatagridConfigurableRbac';
import FreeTextSearchFilter from '../common/FreeTextSearchFilter';
import StructureAssetGroupEdit from './StructureAssetGroupEdit';
import StructureAssetGroupShow from './StructureAssetGroupShow';
import ColumnActions from '../common/ColumnActions';
import ListWithTitle from '../common/ListWithTitle';
import ListActions from '../common/ListActions';
import AssetField from '../common/AssetField';

const StructureAssetGroupList: React.FC = (): ReactElement => {
  return (
    <ListWithTitle
      filters={<FreeTextSearchFilter />}
      actions={<ListActions />}
      sort={{ field: 'assetGroup', order: 'ASC' }}
    >
      <DatagridConfigurableRbac
        ShowComponent={<StructureAssetGroupShow withoutActions />}
        EditComponent={<StructureAssetGroupEdit syncWithLocation={false} />}
        defaultVisibleColumns={['id', 'assetGroup', 'assetId', 'description']}
      >
        <TextField source="id" label="ID" />
        <TextField source="assetGroup" label="Asset Group" />
        <TextField source="description" label="Description" />
        <AssetField label="Asset" source="assetId" />
        {renderMetaDataFields()}
        <ColumnActions label="Actions" />
      </DatagridConfigurableRbac>
    </ListWithTitle>
  );
};

export default StructureAssetGroupList;
