import React, { ReactElement, Fragment, useCallback } from 'react';
import { BulkDeleteButton, useUnselectAll } from 'react-admin';
import CustomBulkUpdateButton from '../../common/CustomBulkUpdateButton';
import BulkGeneratePdfButton from '../../common/BulkGeneratePdfButton';
import BulkEditButton from '../../common/BulkEditButton';
import useInvalidateResourceQueryCache from '../../common/hooks/useInvalidateResourceQueryCache';
import useCanAccess from '../../common/hooks/useCanAccess';
import { RESOURCE_COMPONENT_TEST } from '../../constants';
import ComponentTestForm from '../ComponentTestForm';
import { mobileTestStatuses } from '../constants';

const BulkActionButtons: React.FC = (): ReactElement => {
  const canAccessList = useCanAccess();
  const unselectAll = useUnselectAll(RESOURCE_COMPONENT_TEST);
  const invalidateQueries = useInvalidateResourceQueryCache(
    RESOURCE_COMPONENT_TEST,
    true
  );

  const clearCache = useCallback(async () => {
    await invalidateQueries();
    unselectAll();
  }, [invalidateQueries, unselectAll]);

  return canAccessList.edit ? (
    <Fragment>
      <BulkEditButton
        resource={RESOURCE_COMPONENT_TEST}
        form={<ComponentTestForm />}
      />
      <BulkGeneratePdfButton onSuccess={clearCache} />
      <CustomBulkUpdateButton
        data={{ isMobile: true }}
        label="Send to mobile"
        icon={null}
        resource={RESOURCE_COMPONENT_TEST}
        onUpdate={clearCache}
      />
      <CustomBulkUpdateButton
        data={{ isMobile: false }}
        label="Recall Digital ITRs"
        icon={null}
        resource={RESOURCE_COMPONENT_TEST}
        onUpdate={clearCache}
      />
      <CustomBulkUpdateButton
        data={{
          isMobile: false,
          formData: null,
          mobileTestStatus: mobileTestStatuses[0].id,
        }}
        label="Reset/Recall Digital ITRs"
        icon={null}
        resource={RESOURCE_COMPONENT_TEST}
        onUpdate={clearCache}
      />
      <BulkDeleteButton
        mutationMode="pessimistic"
        mutationOptions={{
          onSettled: clearCache,
        }}
      />
    </Fragment>
  ) : (
    <Fragment>
      {canAccessList.pdfGen && <BulkGeneratePdfButton onSuccess={clearCache} />}
      <CustomBulkUpdateButton
        data={{ isMobile: true }}
        label="Send to mobile"
        icon={null}
        resource={RESOURCE_COMPONENT_TEST}
        onUpdate={clearCache}
        disableAccessCheck={canAccessList.sendMobile}
      />
    </Fragment>
  );
};

export default BulkActionButtons;
