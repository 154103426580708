import React, { ReactElement, useState, useCallback } from 'react';
import { Edit, ReferenceManyField, useGetOne } from 'react-admin';
import { useParams } from 'react-router-dom';
import { Box, Tabs, Tab } from '@mui/material';
import PendingPunchCreateInDialog from '../pendingPunch/components/PendingPunchCreateInDialog';
import SmartPreservationItemActivityForm from './components/SmartPreservationItemActivityForm';
import PendingPunchFormTab from '../pendingPunch/components/PendingPunchFormTab';
import { SmartPreservationItemActivityType } from './types';
import AttachmentFormTab from '../common/AttachmentFormTab';
import sideFilterStyles from '../common/sideFilterStyles';
import { EditPropsWithLocation } from '../common/types';
import useCanAccess from '../common/hooks/useCanAccess';
import { ATTACHMENT_TYPE_ENUMS } from '../common/enums';
import CustomTabPanel from '../common/CustomTabPanel';
import { Originator } from '../submitPunch/constants';
import commonStyles from '../common/commonStyles';
import EditTitle from '../common/EditTitle';
import {
  RESOURCE_SMART_PRESERVATION_ITEM_ACTIVITY,
  RESOURCE_PRESERVATION_ITEM_ACTIVITY,
  RESOURCE_PENDING_PUNCH,
} from '../constants';

interface SmartPreservationItemActivityEditProps
  extends EditPropsWithLocation<SmartPreservationItemActivityType> {
  onCancel?: () => void;
}

const SmartPreservationItemActivityEdit: React.FC<
  SmartPreservationItemActivityEditProps
> = (props): ReactElement => {
  const { id } = useParams();
  const { onCancel } = props;
  const [value, setValue] = useState(0);
  const [pendingPunchTitle, setPendingPunchTitle] = useState(null);
  const [
    pendingPunchCreateDialogCloseDisabled,
    setPendingPunchCreateDialogCloseDisabled,
  ] = useState(false);
  const canAccessList = useCanAccess();
  // Use RESOURCE_PRESERVATION_ITEM_ACTIVITY for avoiding reloading on autosave
  const { data: record } = useGetOne(RESOURCE_PRESERVATION_ITEM_ACTIVITY, {
    id: props?.id ?? id,
  });

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleClose = useCallback(() => {
    setPendingPunchTitle(null);
  }, []);

  return (
    <Box sx={sideFilterStyles.sideEditCard}>
      <Edit
        {...props}
        mutationMode="pessimistic"
        actions={false}
        title={<EditTitle />}
      >
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange}>
            <Tab
              label="Form"
              id="form_tab"
              sx={{ ...commonStyles.formTabHeader, overflowX: 'auto' }}
            />
            <Tab
              label="Punch List"
              id="pending_punch_common_tab"
              sx={{ ...commonStyles.formTabHeader, overflowX: 'auto' }}
            />
            <Tab
              label="Attachment"
              id="attachment_tab"
              sx={commonStyles.formTabHeader}
            />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0} id="form_tab">
          <SmartPreservationItemActivityForm
            onCancel={onCancel}
            setPendingPunchTitle={setPendingPunchTitle}
            setPendingPunchCreateDialogCloseDisabled={
              setPendingPunchCreateDialogCloseDisabled
            }
          />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1} id="pending_punch_common_tab">
          <PendingPunchFormTab
            targetId="preservationItemActivityId"
            readonly={!canAccessList.edit}
            outerResource={RESOURCE_SMART_PRESERVATION_ITEM_ACTIVITY}
            originator={Originator.Preservation}
          />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2} id="attachment_tab">
          <AttachmentFormTab
            targetId="preservationItemActivityId"
            attachmentType={[ATTACHMENT_TYPE_ENUMS.GENERAL]}
            resourceName={RESOURCE_SMART_PRESERVATION_ITEM_ACTIVITY}
            simpleView={true}
          />
        </CustomTabPanel>
      </Edit>

      <ReferenceManyField
        reference={RESOURCE_PENDING_PUNCH}
        target="preservationItemActivityId"
        perPage={100}
        fullWidth={true}
      >
        <PendingPunchCreateInDialog
          record={record}
          showButton={false}
          pendingPunchDescription={pendingPunchTitle}
          disableClose={pendingPunchCreateDialogCloseDisabled}
          onClose={handleClose}
          targetId="preservationItemActivityId"
          originator={Originator.Preservation}
        />
      </ReferenceManyField>
    </Box>
  );
};

export default SmartPreservationItemActivityEdit;
