import React, { useCallback, useEffect, useState } from 'react';
import { TextInputProps, useInput, useListContext } from 'react-admin';
import { Box, TextField, IconButton, InputAdornment } from '@mui/material';
import { ReactComponent as SearchIcon } from '../../assets/icons/functionalIcons/Search.svg';
import { ReactComponent as CancelIcon } from '../../assets/icons/functionalIcons/CancelRound.svg';
import { isEmpty } from '../../utils/UtilityFunctions';

const SearchInput = (prop) => {
  const { field } = useInput({ source: prop.source });
  const [value, setValue] = useState(field.value || '');
  const { filterValues } = useListContext();

  useEffect(() => {
    if (isEmpty(filterValues['q'])) {
      setValue('');
    }
  }, [filterValues]);

  const clearHandler = useCallback(async () => {
    field.onChange('');
    setValue('');
  }, [field]);

  const handleSearch = useCallback(async () => {
    field.onChange(value.trim());
  }, [field, value]);

  const handleChange = useCallback(
    (ev) => {
      ev.persist();
      setValue(ev.target.value);
      if (!ev.target.value) {
        field.onChange('');
      }
    },
    [field]
  );

  const handkeEnterClick = useCallback(
    (ev) => {
      ev.persist();
      if (ev.key === 'Enter') {
        handleSearch();
      } else {
        return null;
      }
    },
    [handleSearch]
  );

  return (
    <span style={{ marginBottom: 5 }}>
      <div>
        <TextField
          variant={'outlined'}
          label={prop.label}
          size="small"
          className="freeTextSearchInput"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={clearHandler}>
                  <CancelIcon />
                </IconButton>
                <IconButton onClick={handleSearch}>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          value={value}
          onChange={handleChange}
          onKeyPress={handkeEnterClick}
        />
      </div>
    </span>
  );
};

const FreeTextSearchInput: React.FC<TextInputProps> = (props) => {
  return (
    <Box display={'flex'} alignItems={'center'}>
      <SearchInput {...props} />
    </Box>
  );
};

export default FreeTextSearchInput;
