import React, { ReactElement, useMemo } from 'react';
import AutocompleteReferenceInput from '../../common/AutocompleteReferenceInput';
import commonStyles from '../../common/commonStyles';
import { RESOURCE_PROJECT } from '../../constants';
import { getProjectValidator } from '../helpers';
import { required } from '../../../utils/UtilityFunctions';

interface ProjectInputProps {
  label: string;
  source: string;
  isRequired?: boolean;
}

const ProjectInput: React.FC<ProjectInputProps> = ({
  label,
  source,
  isRequired,
}): ReactElement => {
  const requestParams = useMemo(() => {
    return {
      sort: { field: 'id', order: 'ASC' as const },
    };
  }, []);
  return (
    <AutocompleteReferenceInput
      resource={RESOURCE_PROJECT}
      requestParams={requestParams}
      source={source}
      label={label}
      name="project_inc"
      optionText="project"
      validate={isRequired ? [required(), getProjectValidator()] : []}
      sx={commonStyles.flexBoxSelectItem}
      includeFields={['id', 'project']}
      additionalParams={{ skipListAdditionalData: true }}
    />
  );
};

export default ProjectInput;
