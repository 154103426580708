import {
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
  Button,
} from '@mui/material';
import { useState, useCallback } from 'react';
import { getErrorMessage } from '../../utils/UtilityFunctions';
import useCreateContact from './hooks/useCreateContact';

const ContactCreateDialog = ({
  listOfContacts,
  category,
  onCreate,
  value,
  changeValue,
  onCancel,
  open,
}) => {
  const handleOnCancel = () => {
    onCancel();
  };
  const [validationError, setValidationError] = useState('');
  const onSubmit = useCreateContact({
    category,
    changeValue,
    onCreate,
    getErrorMessage,
  });

  const handleTextValueChange = useCallback(
    (event) => {
      const { value } = event.target;
      if (listOfContacts?.find((item) => item.name === value)) {
        setValidationError('Contact already exists');
      } else {
        setValidationError('');
      }
      changeValue(value);
    },
    [changeValue, listOfContacts]
  );

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault();

      onSubmit(value);
    },
    [onSubmit, value]
  );

  return (
    <Dialog open={open} onClose={handleOnCancel}>
      <form>
        <DialogContent>
          <TextField
            label="New Contact"
            value={value}
            onChange={handleTextValueChange}
            autoFocus
            error={!!validationError}
            helperText={validationError}
          />
        </DialogContent>
        <DialogActions>
          <Button disabled={!!validationError} onClick={handleSubmit}>
            Save
          </Button>
          <Button onClick={handleOnCancel}>Cancel</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ContactCreateDialog;
