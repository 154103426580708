import React, { ReactElement } from 'react';
import { Labeled, TabbedShowLayout, TextField, DateField } from 'react-admin';
import Box from '@mui/material/Box';
import renderShowMetaDataFields from '../common/helpers/renderShowMetaDataFields';
import ShowComponent, { ShowComponentProps } from '../common/ShowComponent';
import DisciplineTestField from '../common/DisciplineTestField';
import CustomBooleanField from '../common/CustomBooleanField';
import TestTypeField from '../common/TestTypeField';
import commonStyles from '../common/commonStyles';
import AttachmentRevisionFormTab from '../attachmentRevision/AttachmentRevisionFormTab';
import PositionFormShow from './components/PositionFormShow';
import AuditListTab from '../audit/AuditListTab';

const TestShow: React.FC<ShowComponentProps> = ({
  syncWithLocation,
  ...rest
}): ReactElement => (
  <ShowComponent {...rest}>
    <TabbedShowLayout
      sx={commonStyles.bottomMargin}
      syncWithLocation={syncWithLocation}
    >
      <TabbedShowLayout.Tab label="Test">
        <Box>
          <Labeled label="Test Form No">
            <TextField source="testFormNo" sx={commonStyles.flexBoxItem} />
          </Labeled>
          <Labeled label="Test Name">
            <TextField source="testName" sx={commonStyles.flexBoxItem} />
          </Labeled>
          <Labeled label="Test Responsible">
            <TextField source="testResp" sx={commonStyles.flexBoxItem} />
          </Labeled>
        </Box>
        <Box>
          <Labeled sx={commonStyles.flexBoxItem}>
            <TestTypeField />
          </Labeled>
          <Labeled sx={commonStyles.flexBoxItem}>
            <DisciplineTestField />
          </Labeled>
        </Box>
        <Box>
          <Labeled sx={commonStyles.flexBoxItem}>
            <TextField label="Revision" source="attachmentRevision.revision" />
          </Labeled>
          <Labeled sx={commonStyles.flexBoxItem}>
            <DateField
              label="Revision Date"
              source="attachmentRevision.revDate"
            />
          </Labeled>
        </Box>
        <Box>
          <Labeled>
            <CustomBooleanField
              source="multiTag"
              label="Multi Tag"
              sx={commonStyles.flexBoxItem}
            />
          </Labeled>
          <Labeled>
            <CustomBooleanField
              source="isPreservation"
              label="Preservation"
              sx={commonStyles.flexBoxItem}
            />
          </Labeled>
          <Labeled>
            <CustomBooleanField
              source="batch"
              label="Batch"
              sx={commonStyles.flexBoxItem}
            />
          </Labeled>
        </Box>
        <Box>
          <Labeled label="Duration">
            <TextField source="duration" sx={commonStyles.flexBoxItem} />
          </Labeled>
          <Labeled label="Class">
            <TextField source="class" sx={commonStyles.flexBoxItem} />
          </Labeled>
        </Box>
        <Box>{renderShowMetaDataFields()}</Box>
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab label="Revision">
        <AttachmentRevisionFormTab
          targetId="testId"
          withoutProjectId
          onlyShow
        />
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab label="Details">
        <PositionFormShow />
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab label="History">
        <AuditListTab tableName="tbl_test" />
      </TabbedShowLayout.Tab>
    </TabbedShowLayout>
  </ShowComponent>
);

export default TestShow;
