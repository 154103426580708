import React, { useState, useCallback, useEffect, ReactElement } from 'react';
import { DatagridConfigurable } from 'react-admin';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { getContext, UserContext } from '../../../provider/userContext';
import FreeTextSearchFilter from '../../common/FreeTextSearchFilter';
import LogoField from '../../common/FileField';
import ListWithTitle from '../../common/ListWithTitle';
import { RESOURCE_PROJECT } from '../../constants';
import ProjectSideFilters from '../components/ProjectSideFilters';
import ProjectTextField from './ProjectTextField';
import FavoriteField from './FavoriteField';
import ColumnActions from './ColumnActions';
import CompanySwitch from './CompanySwitch';

interface ProjectSelectionProps {
  title: string;
  open: boolean;
  handleClose: (event?, reason?: string) => void;
}

const ProjectSelection: React.FC<ProjectSelectionProps> = ({
  title,
  open,
  handleClose,
}): ReactElement => {
  const ctx: UserContext = getContext();
  const [filter, setFilter] = useState(null);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const ctxProjectIds = ctx
    ? Object.keys(ctx?.companies?.[ctx.company]?.projects || {})
    : ['0'];
  const defaultFilter = {
    isActive: true,
    id: ctxProjectIds,
    divisionId: ctx?.companies?.[ctx.company]?.defaultDivisionId,
  };
  const [favoriteProjects, setFavoriteProjects] = useState([]);

  useEffect(() => {
    setFavoriteProjects(
      (ctx?.companies?.[ctx.company]?.favoriteProjects || []).filter((id) =>
        ctxProjectIds.includes(String(id))
      )
    );
  }, [ctx?.companies, ctx?.company, ctxProjectIds]);

  const handleFavorite = useCallback(
    (val) => {
      setFilter(val ? { id: favoriteProjects } : null);
    },
    [setFilter, favoriteProjects]
  );

  if (!ctx?.company) {
    return null;
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="xl"
      fullWidth
      disableEscapeKeyDown={true}
    >
      <DialogTitle
        sx={{
          background: '#2bab2b',
          color: 'white',
          paddingTop: '5px',
          paddingBottom: '5px',
        }}
      >
        {title}
      </DialogTitle>
      <DialogContent>
        <ListWithTitle
          resource={RESOURCE_PROJECT}
          pagination={false}
          actions={<CompanySwitch />}
          filters={<FreeTextSearchFilter />}
          filterDefaultValues={defaultFilter}
          filter={filter ? filter : {}}
          sort={{ field: 'project', order: 'ASC' }}
          storeKey="projectSelection"
          aside={
            <ProjectSideFilters
              isAdminList={false}
              handleFavorite={handleFavorite}
              defaultFilter={defaultFilter}
            />
          }
          sx={{ height: '100%' }}
        >
          <DatagridConfigurable
            preferenceKey="projectSelection"
            bulkActionButtons={false}
            rowClick={false}
          >
            <FavoriteField
              label="My Favorite"
              favoriteProjects={favoriteProjects}
            />
            <LogoField
              source="attachment"
              label="Logo"
              width={100}
              height={50}
              sortable={false}
            />
            <ProjectTextField label="Project" />
            <ColumnActions label="Actions" handleClose={handleClose} />
          </DatagridConfigurable>
        </ListWithTitle>
      </DialogContent>
    </Dialog>
  );
};

export default ProjectSelection;
