import { useCallback, useState } from 'react';
import { useDataProvider } from 'react-admin';
import { RecordWithAnyData } from '../types';

const useRevisionDetailsJsonImport = () => {
  const [importInProgress, setImportInProgress] = useState(false);
  const dataProvider = useDataProvider();

  const upload = useCallback(
    async (resource: string, data: RecordWithAnyData, file: File) => {
      return dataProvider.importJsonForRevisionDetails(resource, {
        id: data.id,
        file,
      });
    },
    [dataProvider]
  );

  const importJson = useCallback(
    async (resource: string, data) => {
      try {
        setImportInProgress(true);
        const { file, ...formValues } = data;
        return await upload(resource, formValues, file);
      } catch (err) {
        throw err;
      } finally {
        setImportInProgress(false);
      }
    },
    [upload]
  );

  return { importInProgress, importJson };
};

export default useRevisionDetailsJsonImport;
