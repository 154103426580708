import {
  EditableDatagrid,
  EditRowButton,
} from '@react-admin/ra-editable-datagrid';
import React, { Fragment, ReactElement, useMemo } from 'react';
import { ReferenceManyField, TextField, useRecordContext } from 'react-admin';
import { EditableDatagridCreateButton } from '../../../common/EditableDatagridCreateButton';
import commonStyles from '../../../common/commonStyles';
import DeleteWithConfirmIconButton from '../../../common/DeleteWithConfirmIconButton';
import useInvalidateResourceQueryCache from '../../../common/hooks/useInvalidateResourceQueryCache';
import {
  RESOURCE_MATERIAL_RELEASED,
  RESOURCE_MATERIAL_RELEASED_MATERIAL_STORAGE,
} from '../../../constants';
import { MaterialReleased, MaterialReleasedStatus } from '../types';
import MRMaterialStoragesCreateForm from './MRMaterialStoragesCreateForm';
import MRMaterialStoragesEditRowForm from './MRMaterialStoragesEditRowForm';
import MRMaterialStoragesFooter from './MRMaterialStoragesFooter';

interface MRMaterialStoragesFormProps {
  isShowMode?: boolean;
}

const MRMaterialStoragesForm: React.FC<MRMaterialStoragesFormProps> = ({
  isShowMode,
}): ReactElement => {
  const record = useRecordContext<MaterialReleased>();
  const isReleased = useMemo(
    () => record.status === MaterialReleasedStatus.RELEASED,
    [record]
  );

  const onRowChange = useInvalidateResourceQueryCache(
    RESOURCE_MATERIAL_RELEASED
  );

  return (
    <ReferenceManyField
      target="materialReleasedId"
      reference={RESOURCE_MATERIAL_RELEASED_MATERIAL_STORAGE}
    >
      <Fragment>
        <EditableDatagrid
          bulkActionButtons={false}
          mutationMode="pessimistic"
          createForm={
            !isShowMode && (
              <MRMaterialStoragesCreateForm
                onSuccess={onRowChange}
                parentId={record.id}
                disabled={isReleased}
              />
            )
          }
          editForm={
            <MRMaterialStoragesEditRowForm
              isReleased={isReleased}
              onSuccess={onRowChange}
            />
          }
          actions={
            isShowMode ? (
              <Fragment />
            ) : (
              <Fragment>
                <EditRowButton disabled={isReleased} />
                <DeleteWithConfirmIconButton
                  onDelete={onRowChange}
                  disabled={isReleased}
                />
              </Fragment>
            )
          }
          sx={commonStyles.formTabDatagrid}
          empty={
            !isShowMode && <EditableDatagridCreateButton label="Add Material" />
          }
        >
          <TextField label="Material" source="material.material" />
          <TextField
            label="Description"
            source="material.description"
            sortable={false}
          />
          <TextField
            label="Type"
            source="material.materialType.type"
            sortable={false}
          />
          <TextField
            label="Storage Location"
            source={isReleased ? 'storageStr' : 'storage.storage_location'}
            sortable={false}
          />
          <TextField
            label="On Hand Qty"
            source={isReleased ? 'qty' : 'materialStorage.qtyOnHand'}
            className="fixed"
            sortable={false}
          />
          <TextField
            label="Qty to Release"
            source="qtyOnReleased"
            className="fixed"
          />
          <TextField label="Comment" source="comment" className="fixed2x" />
        </EditableDatagrid>
        <MRMaterialStoragesFooter />
      </Fragment>
    </ReferenceManyField>
  );
};

export default MRMaterialStoragesForm;
