import React, { ReactElement } from 'react';
import { ReferenceInput, SelectInput } from 'react-admin';
import { UserContext, getContext } from '../../provider/userContext';
import { RESOURCE_ATTACHMENT_TYPE } from '../constants';

interface AttachmentTypeInputProps {
  sx?: object;
  alwaysOn?: boolean;
  fullWidth?: boolean;
}

const AttachmentTypeInput: React.FC<AttachmentTypeInputProps> = ({
  sx,
  fullWidth,
  ...rest
}): ReactElement => {
  const ctx: UserContext = getContext();

  return (
    <ReferenceInput
      reference={RESOURCE_ATTACHMENT_TYPE}
      source="subtypeId"
      {...rest}
      filter={{ projectId: ctx?.projectId }}
    >
      <SelectInput
        optionText="subtype"
        label="Type"
        sx={sx}
        fullWidth={fullWidth}
      />
    </ReferenceInput>
  );
};

export default AttachmentTypeInput;
