import React, { ReactElement } from 'react';
import { Labeled, TabbedShowLayout, TextField } from 'react-admin';
import Box from '@mui/material/Box';
import renderShowMetaDataFields from '../common/helpers/renderShowMetaDataFields';
import ShowComponent, { ShowComponentProps } from '../common/ShowComponent';
import CustomBooleanField from '../common/CustomBooleanField';
import DisciplineField from '../common/DisciplineField';
import commonStyles from '../common/commonStyles';
import EquipmentTypeTestListTab from '../equipmentTypeTest/EquipmentTypeTestListTab';
import AuditListTab from '../audit/AuditListTab';

const EquipmentTypeShow: React.FC<ShowComponentProps> = ({
  syncWithLocation,
  ...rest
}): ReactElement => (
  <ShowComponent {...rest}>
    <TabbedShowLayout
      sx={commonStyles.bottomMargin}
      syncWithLocation={syncWithLocation}
    >
      <TabbedShowLayout.Tab
        label="Equipment Type"
        id="equipment_type_tab"
        sx={commonStyles.formTabHeader}
      >
        <Box>
          <Labeled>
            <TextField
              source="equipType"
              label="Type"
              sx={commonStyles.flexBoxItem}
            />
          </Labeled>
          <Labeled>
            <TextField
              source="description"
              label="Description"
              sx={commonStyles.flexBoxItem}
            />
          </Labeled>
        </Box>
        <Box>
          <Labeled>
            <TextField
              source="hardSoft"
              label="Hard/Soft"
              sx={commonStyles.flexBoxItem}
            />
          </Labeled>
          <Labeled>
            <TextField
              source="grouping"
              label="Grouping"
              sx={commonStyles.flexBoxItem}
            />
          </Labeled>
          <Labeled sx={commonStyles.flexBoxItem}>
            <DisciplineField label="Discipline" source="disciplineId" />
          </Labeled>
        </Box>
        <Box>
          <Labeled>
            <CustomBooleanField
              source="metricsCount"
              label="Metrics Count"
              sx={commonStyles.flexBoxItem}
            />
          </Labeled>
        </Box>

        <Box>{renderShowMetaDataFields()}</Box>
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab
        label="Test"
        sx={commonStyles.formTabHeader}
        id="test_tab"
      >
        <EquipmentTypeTestListTab onlyShow />
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab label="History">
        <AuditListTab tableName="tbl_equipment_type" />
      </TabbedShowLayout.Tab>
    </TabbedShowLayout>
  </ShowComponent>
);

export default EquipmentTypeShow;
