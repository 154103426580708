import { Labeled, SimpleShowLayout, TabbedForm, TextField } from 'react-admin';
import React, { ReactElement } from 'react';
import Box from '@mui/material/Box';
import ShowComponent, { ShowComponentProps } from '../common/ShowComponent';
import ProjectField from '../common/ProjectField';
import LazyFormTab from '../common/LazyFormTab';
import commonStyles from '../common/commonStyles';
import renderShowMetaDataFields from '../common/helpers/renderShowMetaDataFields';
import GroupUsersTab from './components/GroupUsersTab';
import AuditListTab from '../audit/AuditListTab';

const UserGroupShow: React.FC<ShowComponentProps> = ({
  syncWithLocation,
  ...rest
}): ReactElement => (
  <ShowComponent {...rest}>
    <SimpleShowLayout sx={commonStyles.bottomMargin}>
      <TabbedForm
        toolbar={null}
        style={{ width: '100%' }}
        sx={commonStyles.tabbedFormBoldHeader}
        syncWithLocation={syncWithLocation}
      >
        <LazyFormTab
          label="User Group"
          id="user_group_tab"
          sx={commonStyles.formTabHeader}
        >
          <Box>
            <Labeled>
              <TextField
                source="userGroup"
                label="User Group"
                sx={commonStyles.flexBoxItem}
              />
            </Labeled>
            <Labeled>
              <TextField
                source="description"
                label="Description"
                sx={commonStyles.flexBoxItem}
              />
            </Labeled>
            <Labeled>
              <ProjectField />
            </Labeled>
          </Box>
          <Box>{renderShowMetaDataFields()}</Box>
        </LazyFormTab>
        <LazyFormTab
          label="Users"
          id="users_tab"
          sx={commonStyles.formTabHeader}
        >
          <GroupUsersTab />
        </LazyFormTab>
        <LazyFormTab label="History">
          <AuditListTab tableName="tbl_user_group" />
        </LazyFormTab>
      </TabbedForm>
    </SimpleShowLayout>
  </ShowComponent>
);

export default UserGroupShow;
