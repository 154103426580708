import React, { Fragment, ReactElement, useCallback } from 'react';
import { UISchemaElement } from '@jsonforms/core';
import { JsonForms } from '@jsonforms/react';
import {
  materialCells,
  materialRenderers,
} from '@jsonforms/material-renderers';
import TextControl, { TextControlTester } from './TextControl';
import DateControl, { DateControlTester } from './DateControl';
import RadioGroupControl, {
  RadioGroupControlTester,
} from './RadioGroupControl';
import CheckboxControl, { CheckboxControlTester } from './CheckboxControl';

type JSONFormType = {
  formSchema: object;
  formUiSchema: UISchemaElement;
  formData?: object;
  readonly?: boolean;
  onChange?: (errors: object[], data: object) => void;
};

export const JSONForm: React.FC<JSONFormType> = ({
  formSchema,
  formUiSchema,
  formData = {},
  readonly = true,
  onChange,
}): ReactElement => {
  const renderers = [
    ...materialRenderers,
    { tester: RadioGroupControlTester, renderer: RadioGroupControl },
    { tester: TextControlTester, renderer: TextControl },
    { tester: DateControlTester, renderer: DateControl },
    { tester: CheckboxControlTester, renderer: CheckboxControl },
  ];

  const onChangeHandle = useCallback(
    ({ errors, data }) => {
      if (onChange) {
        onChange(errors, data);
      }
    },
    [onChange]
  );

  return (
    <Fragment>
      {formSchema && formUiSchema && (
        <JsonForms
          schema={formSchema}
          uischema={formUiSchema}
          data={formData}
          renderers={renderers}
          cells={materialCells}
          readonly={readonly}
          onChange={onChangeHandle}
          validationMode={readonly ? 'NoValidation' : 'ValidateAndShow'}
        />
      )}
    </Fragment>
  );
};

export default JSONForm;
