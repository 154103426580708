import { ReferenceManyField } from 'react-admin';
import {
  EditableDatagrid,
  EditRowButton,
} from '@react-admin/ra-editable-datagrid';
import React, { Fragment, ReactElement, useCallback } from 'react';
import commonStyles from '../../../common/commonStyles';
import useInvalidateResourceQueryCache from '../../../common/hooks/useInvalidateResourceQueryCache';
import { RESOURCE_MOC } from '../../../constants';
import DeleteWithConfirmIconButton from '../../../common/DeleteWithConfirmIconButton';

interface RelatedFormProps {
  children: ReactElement;
  formRow: ReactElement;
  reference: string;
}

const RelatedForm: React.FC<RelatedFormProps> = ({
  children,
  formRow,
  reference,
}): ReactElement => {
  const invalidateMOCQueries = useInvalidateResourceQueryCache(RESOURCE_MOC);

  const onDelete = useCallback(
    async () => await invalidateMOCQueries(),
    [invalidateMOCQueries]
  );

  return (
    <ReferenceManyField target="moc_id" reference={reference}>
      <EditableDatagrid
        actions={
          <Fragment>
            <EditRowButton />
            <DeleteWithConfirmIconButton onDelete={onDelete} />
          </Fragment>
        }
        bulkActionButtons={false}
        mutationMode="pessimistic"
        createForm={formRow}
        editForm={formRow}
        sx={commonStyles.formTabDatagrid}
      >
        {children}
      </EditableDatagrid>
    </ReferenceManyField>
  );
};

export default RelatedForm;
