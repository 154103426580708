import React, { ReactElement } from 'react';
import { FunctionField, TextField } from 'react-admin';
import { MAX_RECORD_PER_PAGE } from '../../provider/constants';
import ListWithTitle from '../common/ListWithTitle';
import ListActions from '../common/ListActions';
import ColumnActions from '../common/ColumnActions';
import DatagridConfigurableRbac from '../common/DatagridConfigurableRbac';
import useDefaultFilter from '../common/hooks/useDefaultFilter';
import renderMetaDataFields from '../common/helpers/renderMetaDataFields';
import ReportViewerButton from './components/ReportViewerButton';
import ReportShow from './ReportShow';
import ReportEdit from './ReportEdit';
import { reportTypeChoices } from './constants';

const ReportList: React.FC = (): ReactElement => {
  const defaultFilter = useDefaultFilter('project');

  return (
    <ListWithTitle
      filterDefaultValues={defaultFilter}
      actions={
        <ListActions
          showAction={{
            create: false,
            import: false,
            export: false,
            topPagination: false,
          }}
        />
      }
      sort={{ field: 'reportName', order: 'ASC' }}
      pagination={null}
      perPage={MAX_RECORD_PER_PAGE}
    >
      <DatagridConfigurableRbac
        ShowComponent={<ReportShow withoutActions />}
        EditComponent={<ReportEdit syncWithLocation={false} />}
        defaultVisibleColumns={[
          'id',
          'reportType',
          'reportName',
          'description',
        ]}
      >
        <TextField source="reportType" label="Report Type" />
        <TextField source="reportName" label="Report Name" />
        <TextField source="description" label="Description" />
        {renderMetaDataFields()}
        <FunctionField
          label="Actions"
          render={(record) => {
            const isTelerik = record.reportType === reportTypeChoices[0].name;

            return (
              <ColumnActions
                label="Actions"
                showActions={{
                  edit: isTelerik,
                  delete: false,
                }}
              >
                <ReportViewerButton />
              </ColumnActions>
            );
          }}
        />
      </DatagridConfigurableRbac>
    </ListWithTitle>
  );
};

export default ReportList;
