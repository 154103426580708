import { useQueryClient } from '@tanstack/react-query';
import React, {
  ReactElement,
  useState,
  Fragment,
  useMemo,
  useCallback,
} from 'react';
import {
  WrapperField,
  Identifier,
  RowClickFunction,
  useNotify,
} from 'react-admin';
import {
  EditableDatagrid,
  EditRowButton,
} from '@react-admin/ra-editable-datagrid';
import { Box } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { GREEN_LIGHT, WHITE } from '../../../../theme/colors';
import { getErrorMessage } from '../../../../utils/UtilityFunctions';
import useDefaultFilter from '../../../common/hooks/useDefaultFilter';
import useInvalidateResourceQueryCache from '../../../common/hooks/useInvalidateResourceQueryCache';
import commonStyles from '../../../common/commonStyles';
import ListWithTitle from '../../../common/ListWithTitle';
import { RESOURCE_QR_SCANNER } from '../../../constants';
import { QrScanItem } from '../../types';
import DeleteQrItemButton from './components/DeleteQrItemButton';
import FileNameField from './components/FileNameField';
import ProcessButton from './components/ProcessButton';
import PdfView from './components/PdfView';
import useDataProvider from '../../../../provider/dataProvider/useDataProvider';

interface DataFormTabProps {
  data: QrScanItem[];
  attachmentId: Identifier;
  resource: string;
  children: ReactElement[];
  editFormRow: ReactElement;
  editMode?: boolean;
}

const DataFormTab: React.FC<DataFormTabProps> = ({
  data,
  attachmentId,
  resource,
  editFormRow,
  children,
  editMode,
}): ReactElement => {
  const notify = useNotify();
  const ids = useMemo(() => data.map((item) => item.recordId), [data]);
  const [selectedRecordId, setSelectedRecordId] = useState<Identifier>(null);
  const defaultFilter = useDefaultFilter('project', { id: ids });
  const queryClient = useQueryClient();
  const invalidateQueries =
    useInvalidateResourceQueryCache(RESOURCE_QR_SCANNER);
  const dataProvider = useDataProvider();

  const { mutate: startProcess } = useMutation({
    mutationFn: (ids: Identifier[]) => {
      return dataProvider.startProcess(RESOURCE_QR_SCANNER, {
        attachmentId,
        revisionIds: ids,
      });
    },
    onSuccess: async ({ data }) => {
      if (data?.attachmentDeletedCompletely) {
        await queryClient.invalidateQueries({
          queryKey: [RESOURCE_QR_SCANNER, 'getList'],
        });
      } else {
        await invalidateQueries();
      }
      notify('Process completed', {
        type: 'success',
      });
    },
    onError: async (error) => {
      await invalidateQueries();
      notify(getErrorMessage(error), {
        type: 'error',
        undoable: false,
      });
    },
  });

  const pdfUrl = useMemo(() => {
    const qrScanItem = data.find((item) => item.recordId === selectedRecordId);
    return qrScanItem?.downloadUrl || '';
  }, [data, selectedRecordId]);

  const handleProcess = useCallback(
    (data: Identifier[]) => {
      startProcess(data);
    },
    [startProcess]
  );

  const handleRowClick = useCallback<RowClickFunction>(
    (id) => {
      if (selectedRecordId === id) {
        setSelectedRecordId(null);
      } else {
        setSelectedRecordId(id);
      }
      return false;
    },
    [selectedRecordId]
  );

  return (
    <ListWithTitle
      title={<Fragment />}
      filterDefaultValues={defaultFilter}
      filter={null}
      resource={resource}
      actions={false}
      disableSyncWithLocation
      aside={pdfUrl ? <PdfView url={pdfUrl} /> : null}
      storeKey={`qr-scan-tab-${resource}`}
    >
      <EditableDatagrid
        bulkActionButtons={
          editMode ? (
            <ProcessButton
              data={data}
              resource={resource}
              onClick={handleProcess}
            />
          ) : null
        }
        mutationMode="pessimistic"
        actions={false}
        editForm={editMode ? editFormRow : null}
        sx={commonStyles.formTabDatagrid}
        rowClick={handleRowClick}
        rowSx={(record) => ({
          backgroundColor: record.id === selectedRecordId ? GREEN_LIGHT : WHITE,
        })}
      >
        <FileNameField data={data} />
        {children}
        {editMode ? (
          <WrapperField label="Actions">
            <Box sx={{ width: '64px' }}>
              <EditRowButton />
              <DeleteQrItemButton data={data} />
            </Box>
          </WrapperField>
        ) : null}
      </EditableDatagrid>
    </ListWithTitle>
  );
};

export default DataFormTab;
