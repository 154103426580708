import React, { Fragment, ReactElement, useEffect, useMemo } from 'react';
import { BooleanInput } from 'react-admin';
import { useFormContext, useWatch } from 'react-hook-form';
import Box from '@mui/material/Box';
import AutocompleteReferenceInput from '../../common/AutocompleteReferenceInput';
import commonStyles from '../../common/commonStyles';
import { RESOURCE_PROJECT } from '../../constants';

const ProjectSourceProjectTab: React.FC = (): ReactElement => {
  const { setValue } = useFormContext();
  const tableCopies = useWatch({ name: 'tableCopies' });
  const projectRequestParams = useMemo(() => {
    return {
      filter: {
        isActive: true,
      },
    };
  }, []);

  useEffect(() => {
    if (tableCopies?.tbl_toc) {
      setValue('tableCopies[tbl_attachment_subtype]', true);
      setValue('tableCopies[tbl_drawing_type]', true);
    }
  }, [setValue, tableCopies]);

  return (
    <Fragment>
      <Box sx={commonStyles.flexBox}>
        <AutocompleteReferenceInput
          resource={RESOURCE_PROJECT}
          label="Source Project"
          name="project_inc"
          optionText="project"
          source="sourceProjectId"
          sx={commonStyles.flexBoxItem}
          requestParams={projectRequestParams}
          includeFields={['id', 'project']}
        />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <BooleanInput
          source="tableCopies[tbl_vendor]"
          label="Contractors"
          defaultValue={false}
          sx={commonStyles.flexBoxItem}
        />
        <BooleanInput
          source="tableCopies[tbl_discipline]"
          label="Disciplines"
          defaultValue={false}
          sx={commonStyles.flexBoxItem}
        />
        <BooleanInput
          source="tableCopies[tbl_itr]"
          label="ITR Forms"
          defaultValue={false}
          sx={commonStyles.flexBoxItem}
        />
        <BooleanInput
          source="tableCopies[tbl_punch_category]"
          label="Punch Types"
          defaultValue={false}
          sx={commonStyles.flexBoxItem}
        />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <BooleanInput
          source="tableCopies[tbl_punch_priority]"
          label="Punch Priority"
          defaultValue={false}
          sx={commonStyles.flexBoxItem}
        />
        <BooleanInput
          source="tableCopies[tbl_position]"
          label="Test Positions"
          defaultValue={false}
          sx={commonStyles.flexBoxItem}
        />
        <BooleanInput
          source="tableCopies[tbl_report]"
          label="Reports"
          defaultValue={false}
          sx={commonStyles.flexBoxItem}
        />
        <BooleanInput
          source="tableCopies[tbl_toc]"
          label="TOC"
          defaultValue={false}
          sx={commonStyles.flexBoxItem}
        />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <BooleanInput
          source="tableCopies[tbl_user]"
          label="User Access"
          defaultValue={false}
          sx={commonStyles.flexBoxItem}
        />
        <BooleanInput
          source="tableCopies[tbl_attachment_subtype]"
          label="Attachment Type"
          defaultValue={tableCopies?.tbl_toc}
          sx={commonStyles.flexBoxItem}
          disabled={tableCopies?.tbl_toc}
        />
        <BooleanInput
          source="tableCopies[tbl_drawing_type]"
          label="Drawing Type"
          defaultValue={tableCopies?.tbl_toc}
          sx={commonStyles.flexBoxItem}
          disabled={tableCopies?.tbl_toc}
        />
      </Box>
    </Fragment>
  );
};

export default ProjectSourceProjectTab;
