import React, { ReactElement } from 'react';
import { RESOURCE_AUDIT_TABLE_NAME } from '../../constants';
import SmartReferenceField from '../../common/SmartReferenceField';

interface AuditTableNameFieldProps {
  label: string;
}

const AuditTableNameField: React.FC<AuditTableNameFieldProps> = ({
  label,
}): ReactElement => (
  <SmartReferenceField
    source="tableName"
    reference={RESOURCE_AUDIT_TABLE_NAME}
    label={label}
    textSource="name"
  />
);

export default AuditTableNameField;
