import React, { useCallback, useState, Fragment, ReactElement } from 'react';
import {
  useResourceContext,
  useRecordContext,
  Confirm,
  useNotify,
  useListContext,
} from 'react-admin';
import {
  FORCE_DELETE_CONFIRMATION_MESSAGE,
  FORCE_DELETE_SUCCESS_MESSAGE,
} from '../constants';
import { getErrorMessage } from '../../../utils/UtilityFunctions';
import HardDeleteButton from '../../common/HardDeleteButton';
import CircularProgress from '../../common/CircularProgress';
import useForceDelete from '../useForceDelete';
import commonStyles from '../../common/commonStyles';

type PreservationItemActivityForceDeleteButtonProps = {
  confirmTitle?: string;
  confirmContent?: string;
  label?: string;
};

const PreservationItemActivityForceDeleteButton: React.FC<
  PreservationItemActivityForceDeleteButtonProps
> = (props): ReactElement => {
  const record = useRecordContext();
  const resource = useResourceContext();
  const notify = useNotify();
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const { deletingProgress, forceDelete } = useForceDelete();

  const {
    confirmTitle = 'ra.message.delete_title',
    confirmContent = FORCE_DELETE_CONFIRMATION_MESSAGE,
    label = 'Force delete',
  } = props;
  const { refetch } = useListContext();

  const handleDelete = useCallback(async () => {
    try {
      await forceDelete(resource, record?.id);
      await refetch();
      notify(FORCE_DELETE_SUCCESS_MESSAGE, {
        type: 'success',
      });
    } catch (error) {
      notify(getErrorMessage(error), {
        type: 'error',
        undoable: false,
      });
    } finally {
      setIsOpenDialog(false);
    }
  }, [forceDelete, notify, record?.id, refetch, resource]);

  const handleDialogOpen = useCallback((event) => {
    event.stopPropagation();

    setIsOpenDialog(true);
  }, []);

  const handleDialogClose = useCallback(() => setIsOpenDialog(false), []);

  return (
    <Fragment>
      <HardDeleteButton
        onClick={handleDialogOpen}
        key="button"
        label={label}
        sx={commonStyles.blackColor}
      />
      <Confirm
        isOpen={isOpenDialog}
        loading={deletingProgress}
        title={confirmTitle}
        content={deletingProgress ? <CircularProgress /> : confirmContent}
        translateOptions={{
          name: 'Preservation Item Activity',
          id: record.id,
        }}
        onConfirm={handleDelete}
        onClose={handleDialogClose}
      />
    </Fragment>
  );
};

export default PreservationItemActivityForceDeleteButton;
