import React from 'react';
import { AutocompleteInput, ReferenceInput } from 'react-admin';
import { SxProps, Theme } from '@mui/material/styles';
import CreateAutoCompleteCategoryDialog from './CreateAutoCompleteCategoryDialog';

type AutoCompleteWithCreateOptionReferenceInputProps = {
  source: string;
  label: string;
  reference: string;
  keyValue: string;
  alwaysOn?: boolean;
  multiple?: boolean;
  defaultFilter?: object;
  sx?: SxProps<Theme>;
};

const AutoCompleteWithCreateOptionReferenceInput = ({
  source,
  label,
  reference,
  keyValue,
  alwaysOn,
  multiple,
  defaultFilter,
  sx,
}: AutoCompleteWithCreateOptionReferenceInputProps) => (
  <ReferenceInput
    source={source}
    reference={reference}
    filter={defaultFilter}
    sort={{ field: keyValue, order: 'ASC' as const }}
    alwaysOn={alwaysOn}
  >
    <AutocompleteInput
      source="id"
      label={label}
      create={
        alwaysOn ? null : (
          <CreateAutoCompleteCategoryDialog
            reference={reference}
            keyValue={keyValue}
          />
        )
      }
      multiple={multiple}
      optionText={keyValue}
      optionValue={keyValue}
      sx={sx}
    />
  </ReferenceInput>
);

export default AutoCompleteWithCreateOptionReferenceInput;
