import React, { ReactElement } from 'react';
import { ReferenceField, ReferenceFieldProps, TextField } from 'react-admin';
import useGetLinkType from './hooks/useGetLinkType';

interface SmartReferenceFieldProps extends ReferenceFieldProps {
  textSource: string;
}

const SmartReferenceField: React.FC<SmartReferenceFieldProps> = ({
  textSource,
  reference,
  ...rest
}): ReactElement => (
  <ReferenceField
    reference={reference}
    link={useGetLinkType(reference)}
    {...rest}
  >
    <TextField source={textSource} />
  </ReferenceField>
);

export default SmartReferenceField;
