import React, { ReactElement, Fragment } from 'react';
import { ReferenceManyField, Datagrid, TextField } from 'react-admin';
import useDefaultFilter from '../common/hooks/useDefaultFilter';
import EquipmentTypeTestCreateDialog from './EquipmentTypeTestCreateDialog';
import renderMetaDataFields from '../common/helpers/renderMetaDataFields';
import { RESOURCE_EQUIPMENT_TYPE_TEST } from '../constants';
import LocationField from '../common/LocationField';
import TestField from '../common/TestField';
import ColumnActions from './ColumnActions';

interface EquipmentTypeTestListTabProps {
  onlyShow?: boolean;
}

const EquipmentTypeTestListTab: React.FC<EquipmentTypeTestListTabProps> = ({
  onlyShow,
}): ReactElement => {
  const defaultFilter = useDefaultFilter('project');

  return (
    <ReferenceManyField
      reference={RESOURCE_EQUIPMENT_TYPE_TEST}
      target="equipTypeId"
      filter={defaultFilter}
      sort={{ field: 'id', order: 'ASC' }}
    >
      <Fragment>
        <Datagrid
          bulkActionButtons={false}
          rowClick={false}
          sx={{ width: '100%' }}
        >
          <TextField source="id" label="ID" />
          <TestField
            source="testId"
            textSource="test.testFormNo"
            sortBy="test.test_form_no"
          />
          <LocationField source="locationId" />
          {renderMetaDataFields()}
          {!onlyShow && <ColumnActions />}
        </Datagrid>
        {!onlyShow && <EquipmentTypeTestCreateDialog />}
      </Fragment>
    </ReferenceManyField>
  );
};

export default EquipmentTypeTestListTab;
