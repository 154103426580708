import React, { ReactElement } from 'react';
import { Labeled, TabbedShowLayout, TextField } from 'react-admin';
import { Box } from '@mui/material';
import AuditListTab from '../audit/AuditListTab';
import renderShowMetaDataFields from '../common/helpers/renderShowMetaDataFields';
import ShowComponent, { ShowComponentProps } from '../common/ShowComponent';
import commonStyles from '../common/commonStyles';
import ActionField from './ActionField';
import ResourceField from './ResourceField';
import RecordField from './RecordField';

const SecurityRolePermissionShow: React.FC<ShowComponentProps> = ({
  syncWithLocation,
  ...rest
}): ReactElement => (
  <ShowComponent {...rest}>
    <TabbedShowLayout
      sx={commonStyles.bottomMargin}
      syncWithLocation={syncWithLocation}
    >
      <TabbedShowLayout.Tab label="Role Permission">
        <Box>
          <Labeled label="Role" sx={commonStyles.flexBoxItem}>
            <TextField source="role.role" label="Role" />
          </Labeled>
          <Labeled label="Permission Type" sx={commonStyles.flexBoxItem}>
            <TextField source="permissionType" />
          </Labeled>
          <Labeled label="Resource" sx={commonStyles.flexBoxItem}>
            <ResourceField />
          </Labeled>
        </Box>
        <Box>
          <Labeled label="Role Action" sx={commonStyles.flexBoxLongItem}>
            <ActionField isShowPage />
          </Labeled>
          <Labeled label="Record" sx={commonStyles.flexBoxLongItem}>
            <RecordField />
          </Labeled>
        </Box>
        <Box>{renderShowMetaDataFields()}</Box>
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab label="History">
        <AuditListTab tableName="tbl_security_role_permission" />
      </TabbedShowLayout.Tab>
    </TabbedShowLayout>
  </ShowComponent>
);

export default SecurityRolePermissionShow;
