import { AuthProvider } from 'react-admin';
import {
  AuthenticationDetails,
  CognitoAccessToken,
  CognitoIdToken,
  CognitoRefreshToken,
  CognitoUser,
  CognitoUserPool,
  CognitoUserSession,
} from 'amazon-cognito-identity-js';
import { ErrorRequireNewPassword } from '../../ErrorRequireNewPassword';
import { api } from '../../api';
import {
  getContext,
  setContext,
  updateContext,
  UserContext,
} from '../../userContext';
import {
  sleep,
  updateDictionaryContext,
  updateLocalUISettings,
} from '../../../utils/UtilityFunctions';
import {
  clearNotificationContext,
  initNotificationContext,
} from '../../notificationContext';

export type CognitoAuthProviderOptionsPool = CognitoUserPool;

export type CognitoAuthProviderOptionsIds = {
  userPoolId: string;
  clientId: string;
  hostedUIUrl?: string;
  mode: 'oauth' | 'username';
  redirect_uri: string;
  scope: string[];
};

//https://aws.amazon.com/blogs/mobile/understanding-amazon-cognito-user-pool-oauth-2-0-grants/

export type CognitoAuthProviderOptions =
  | CognitoAuthProviderOptionsPool
  | CognitoAuthProviderOptionsIds;

export type AuthProviderExt = AuthProvider & {
  getUserPool: () => CognitoUserPool;
  getOptions: () => CognitoAuthProviderOptions;
};

export const CognitoAuthProvider = (
  options: CognitoAuthProviderOptions
): AuthProviderExt => {
  let user: CognitoUser | null = null;
  const mode = options instanceof CognitoUserPool ? 'username' : options.mode;

  const redirectToOAuth = async () => {
    setContext(undefined);
    await clearNotificationContext();
    if (mode === 'oauth') {
      const oauthOptions = options as CognitoAuthProviderOptionsIds;
      setTimeout(() => {
        window.location.href = `${oauthOptions.hostedUIUrl}/login?client_id=${
          oauthOptions.clientId
        }&response_type=token&scope=${oauthOptions.scope.join(
          '+'
        )}&redirect_uri=${oauthOptions.redirect_uri}`;
      }, 100);
    } else {
      throw 'Not supported mode:' + mode;
    }
  };

  const userPool =
    options instanceof CognitoUserPool
      ? (options as CognitoUserPool)
      : new CognitoUserPool({
          UserPoolId: options.userPoolId,
          ClientId: options.clientId,
        });

  return {
    getOptions: () => options,
    getUserPool: () => userPool,
    async login({
      username,
      password,
      newPassword,
      ...attributes
    }: {
      username: string;
      password: string;
      newPassword?: string;
      [key: string]: any; // eslint-disable-line @typescript-eslint/no-explicit-any
    }) {
      return new Promise((resolve, reject) => {
        if (newPassword) {
          if (!user) {
            return reject(new Error('User is not defined'));
          }

          return user.completeNewPasswordChallenge(newPassword, attributes, {
            onSuccess: (result) => {
              resolve(result);
            },
            onFailure: (err) => {
              reject(err);
            },
          });
        }

        user = new CognitoUser({
          Username: username,
          Pool: userPool,
        });

        const authenticationDetails = new AuthenticationDetails({
          Username: username,
          Password: password,
        });

        user.authenticateUser(authenticationDetails, {
          onSuccess: (result) => {
            resolve(result);
          },
          onFailure: (err) => {
            reject(err);
          },
          newPasswordRequired: () => {
            reject(new ErrorRequireNewPassword());
          },
        });
      });
    },

    // called when the user clicks on the logout button
    async logout() {
      return new Promise(async (resolve) => {
        setContext(undefined);
        await clearNotificationContext();
        const user = userPool.getCurrentUser();
        if (!user) {
          return resolve();
        }
        user.signOut(() => {
          resolve();
        });
      });
    },
    // called when the API returns an error
    async checkError({ status }) {
      if (status === 401 || status === 403) {
        return Promise.reject({ message: 'Unauthorized' });
      }
      return Promise.resolve();
    },
    // called when the user navigates to a new location, to check for authentication
    checkAuth() {
      return new Promise<void>((resolve, reject) => {
        const user = userPool.getCurrentUser();
        if (!user) {
          redirectToOAuth();
          return reject();
        }
        user.getSession(async (err, session) => {
          if (err || !session.isValid()) {
            if (err) {
              // eslint-disable-next-line no-console
              console.log('User getSession failed', err);
            } else {
              // eslint-disable-next-line no-console
              console.log('User session is not valid!');
            }
            redirectToOAuth();
            return reject();
          }

          return user.getUserAttributes((err) => {
            if (err) {
              // eslint-disable-next-line no-console
              console.log('User getUserAttributes failed!', err);
              redirectToOAuth();
              return reject();
            }
            return resolve();
          });
        });
      });
    },
    // called when the user navigates to a new location, to check for permissions / roles
    async getPermissions() {
      return new Promise((resolve, reject) => {
        try {
          const user = userPool.getCurrentUser();

          if (!user) {
            redirectToOAuth();
            return reject();
          }
          const ctx: UserContext = getContext();
          if (!ctx) {
            return reject({ status: 401, message: 'No Context' });
          }
          const companyName = ctx?.company;
          const projectId = ctx?.projectId;
          const effectivePermissions =
            ctx?.companies?.[companyName]?.projects?.[projectId]
              ?.effectivePermissions || [];

          return user.getSession((err, session: CognitoUserSession) => {
            if (err) {
              return reject(err);
            }
            if (!session.isValid()) {
              return reject();
            }
            return resolve(effectivePermissions);
          });
        } catch (e) {
          return reject('Could not get user permissions!');
        }
      });
    },

    async getIdentity() {
      return new Promise((resolve, reject) => {
        const ctx: UserContext = getContext();
        const user = userPool.getCurrentUser();

        if (!user) {
          return reject();
        }

        user.getSession((err, session) => {
          if (err) {
            return reject(err);
          }
          if (!session.isValid()) {
            return reject();
          }
        });

        if (ctx?.id && ctx.fullName) {
          return resolve({
            id: ctx.id,
            fullName: ctx.fullName,
            email: ctx.id,
          });
        }

        return reject();
      });
    },

    async handleCallback() {
      const urlParams = new URLSearchParams(window.location.hash.substr(1));
      const error = urlParams.get('error');
      const errorDescription = urlParams.get('error_description');
      const idToken = urlParams.get('id_token');
      const accessToken = urlParams.get('access_token');

      if (error) {
        return Promise.reject({
          logoutOnFailure: false,
          message: errorDescription,
        });
      }

      if (idToken == null || accessToken == null) {
        return Promise.reject({
          logoutOnFailure: false,
          message: 'Failed to handle login callback.',
        });
      }
      const session = new CognitoUserSession({
        IdToken: new CognitoIdToken({ IdToken: idToken }),
        RefreshToken: new CognitoRefreshToken({
          RefreshToken: null,
        }),
        AccessToken: new CognitoAccessToken({
          AccessToken: accessToken,
        }),
      });
      const user = new CognitoUser({
        Username: session.getIdToken().decodePayload()['cognito:username'],
        Pool: userPool,
        Storage: window.localStorage,
      });
      user.setSignInUserSession(session);
      // ##############################################
      // setToken
      // ##############################################
      updateContext({ token: session.getIdToken().getJwtToken() });

      try {
        const response = await api.user.getMyDetails();

        if (response.data.companies) {
          const ctx: UserContext = getContext();
          ctx.companies = response.data.companies;
          ctx.id = response.data.id;
          ctx.fullName = `${response.data.firstName} ${response.data.lastName}`;
          let selectedCompany = Object.keys(ctx.companies).find(
            (company) =>
              ctx.companies[company].isPreferred &&
              !ctx.companies[company].isCompanyAccessDisabled
          );
          if (!selectedCompany) {
            selectedCompany = Object.keys(ctx.companies).find(
              (company) => !ctx.companies[company].isCompanyAccessDisabled
            );
          }
          if (!selectedCompany) {
            return Promise.reject({
              logoutOnFailure: false,
              message: "Couldn't find preferred company!",
            });
          }
          ctx.company = selectedCompany;
          ctx.ablyToken = response.data.ablyToken;
          ctx.isRev1Admin = response.data.isRev1Admin;
          ctx.isTermsAndPrivacyPolicyAccepted =
            response.data.isTermsAndPrivacyPolicyAccepted;
          updateContext(ctx);
          initNotificationContext(ctx.ablyToken, ctx.company, ctx.id);

          const uiSettings =
            response.data.companies[selectedCompany]?.uiSettings;

          if (uiSettings) {
            updateLocalUISettings(uiSettings);
          }
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
        await sleep(5000);
        return Promise.reject({
          logoutOnFailure: false,
          message: "Couldn't get /user/my/details!",
        });
      }

      await updateDictionaryContext();

      return Promise.resolve(user);
    },
  };
};
