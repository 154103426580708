import { useEffect } from 'react';
import { useRedirect, useResourceContext } from 'react-admin';
import useCurrentProjectSettings from 'src/components/common/hooks/useCurrentProjectSettings';
import {
  RESOURCE_COMPONENT_TEST,
  RESOURCE_PRESERVATION_ITEM_ACTIVITY,
  RESOURCE_SMART_PRESERVATION_ITEM_ACTIVITY,
  RESOURCE_SMART_TAG_ITR,
  RESOURCE_SMART_TAG_ITR_REVIEW,
} from 'src/components/constants';

const useCheckCurrentProjectResourceAccessSettings = () => {
  const currentProjectSettings = useCurrentProjectSettings();
  const redirect = useRedirect();
  const resource = useResourceContext();

  useEffect(() => {
    if (currentProjectSettings) {
      if (
        resource === RESOURCE_SMART_PRESERVATION_ITEM_ACTIVITY &&
        !currentProjectSettings.isPresMobile
      ) {
        redirect(`/${RESOURCE_PRESERVATION_ITEM_ACTIVITY}`);
      }

      if (
        (resource === RESOURCE_SMART_TAG_ITR ||
          resource === RESOURCE_SMART_TAG_ITR_REVIEW) &&
        !currentProjectSettings.isItrMobile
      ) {
        redirect(`/${RESOURCE_COMPONENT_TEST}`);
      }
    }
  }, [currentProjectSettings, redirect, resource]);
};

export default useCheckCurrentProjectResourceAccessSettings;
