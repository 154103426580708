import React, { ReactElement, useState, useCallback, useEffect } from 'react';
import {
  Edit,
  ReferenceManyField,
  useGetOne,
  useListContext,
  useStore,
  useResourceContext,
  useNotify,
} from 'react-admin';
import { useParams } from 'react-router-dom';
import { Box, Tabs, Tab } from '@mui/material';
import PendingPunchFormTab from '../pendingPunch/components/PendingPunchFormTab';
import PendingPunchCreateInDialog from '../pendingPunch/components/PendingPunchCreateInDialog';
import useCanAccess from '../common/hooks/useCanAccess';
import AttachmentFormTab from '../common/AttachmentFormTab';
import EditTitle from '../common/EditTitle';
import CustomTabPanel from '../common/CustomTabPanel';
import sideFilterStyles from '../common/sideFilterStyles';
import { EditPropsWithLocation } from '../common/types';
import { ATTACHMENT_TYPE_ENUMS } from '../common/enums';
import commonStyles from '../common/commonStyles';
import { Originator } from '../submitPunch/constants';
import {
  RESOURCE_SMART_TAG_ITR,
  RESOURCE_PENDING_PUNCH,
  RESOURCE_COMPONENT_TEST,
} from '../constants';
import SmartTagITRForm from './components/SmartTagITRForm';
import { AxiosResponse } from 'axios';

interface SmartTagITREditProps extends EditPropsWithLocation {
  onCancel?: () => void;
}

const SmartTagITREdit: React.FC<SmartTagITREditProps> = (
  props
): ReactElement => {
  const { id } = useParams();
  const { onCancel } = props;
  const [value, setValue] = useState(0);
  const [pendingPunchTitle, setPendingPunchTitle] = useState(null);
  const [
    pendingPunchCreateDialogCloseDisabled,
    setPendingPunchCreateDialogCloseDisabled,
  ] = useState(false);
  const canAccessList = useCanAccess();
  const resource: string = useResourceContext();
  const [expanded, setExpanded] = useStore(`${resource}.datagrid.expanded`, []);
  // Use RESOURCE_COMPONENT_TEST for avoiding reloading on autosave
  const { data: record } = useGetOne(RESOURCE_COMPONENT_TEST, {
    id: props?.id ?? id,
  });
  const { data: itrs = [], isLoading } = useListContext();
  const notify = useNotify();

  useEffect(() => {
    if (!isLoading) {
      const listIds = itrs.map((item) => item.id);

      if (!expanded.every((id) => listIds.includes(id))) {
        setExpanded([]);
      }
    }
  }, [itrs, expanded, setExpanded, isLoading]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleClose = useCallback(() => {
    setPendingPunchTitle(null);
  }, []);

  const handleUpload = (result: AxiosResponse) => {
    if (result.status === 204) {
      notify(
        `File you uploaded is big and it will take some time to convert it to pdf. 
        Once it's converted you will be notified and the pdf file will appear in the list of attachments.`,
        { type: 'success' }
      );
    }
  };

  return (
    <Box sx={sideFilterStyles.sideEditCard}>
      <Edit
        {...props}
        mutationMode="pessimistic"
        actions={false}
        title={<EditTitle />}
      >
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange}>
            <Tab
              label="Form"
              id="form_tab"
              sx={{ ...commonStyles.formTabHeader, overflowX: 'auto' }}
            />
            <Tab
              label="Punch List"
              id="pending_punch_common_tab"
              sx={{ ...commonStyles.formTabHeader, overflowX: 'auto' }}
            />
            <Tab
              label="Attachment"
              id="attachment_tab"
              sx={commonStyles.formTabHeader}
            />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0} id="form_tab">
          <SmartTagITRForm
            onCancel={onCancel}
            setPendingPunchTitle={setPendingPunchTitle}
            setPendingPunchCreateDialogCloseDisabled={
              setPendingPunchCreateDialogCloseDisabled
            }
          />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1} id="pending_punch_common_tab">
          <PendingPunchFormTab
            targetId="componentTestId"
            readonly={!canAccessList.edit}
            outerResource={RESOURCE_SMART_TAG_ITR}
            originator={Originator.ITR}
          />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2} id="attachment_tab">
          <AttachmentFormTab
            targetId="componentTestId"
            attachmentType={[ATTACHMENT_TYPE_ENUMS.SMART_ITR]}
            resourceName={RESOURCE_SMART_TAG_ITR}
            simpleView={true}
            onUpload={handleUpload}
          />
        </CustomTabPanel>
      </Edit>
      <ReferenceManyField
        reference={RESOURCE_PENDING_PUNCH}
        target="componentTestId"
        perPage={100}
        fullWidth={true}
      >
        <PendingPunchCreateInDialog
          record={record}
          showButton={false}
          pendingPunchDescription={pendingPunchTitle}
          disableClose={pendingPunchCreateDialogCloseDisabled}
          onClose={handleClose}
          originator={Originator.ITR}
        />
      </ReferenceManyField>
    </Box>
  );
};

export default SmartTagITREdit;
