import React, { Fragment, ReactElement } from 'react';
import { DateField, Labeled, TextField } from 'react-admin';
import Box from '@mui/material/Box';
import commonStyles from '../../common/commonStyles';

const InstrumentationShowTab: React.FC = (): ReactElement => (
  <Fragment>
    <Box>
      <Labeled label="Stem Position" sx={commonStyles.flexBoxItem}>
        <TextField source="instrumentation.stemPosition" />
      </Labeled>
      <Labeled label="Max Open Close Time" sx={commonStyles.flexBoxItem}>
        <TextField source="instrumentation.maxOpenCloseTime" />
      </Labeled>
      <Labeled label="Fail Open Close" sx={commonStyles.flexBoxItem}>
        <TextField source="instrumentation.failOpenClose" />
      </Labeled>
      <Labeled label="Signal Type" sx={commonStyles.flexBoxItem}>
        <TextField source="instrumentation.signalType" />
      </Labeled>
      <Labeled label="Mounted On" sx={commonStyles.flexBoxItem}>
        <TextField source="instrumentation.mountedOn" />
      </Labeled>
    </Box>
    <Box>
      <Labeled label="Inst Rg Min" sx={commonStyles.flexBoxItem}>
        <TextField source="instrumentation.instRgMin" />
      </Labeled>
      <Labeled label="Inst Rg Max" sx={commonStyles.flexBoxItem}>
        <TextField source="instrumentation.instRgMax" />
      </Labeled>
      <Labeled label="Inst Rg UoM" sx={commonStyles.flexBoxItem}>
        <TextField source="instrumentation.instRgUom" />
      </Labeled>
      <Labeled label="Cal Rg Min" sx={commonStyles.flexBoxItem}>
        <TextField source="instrumentation.calRgMin" />
      </Labeled>
      <Labeled label="Cal Rg Max" sx={commonStyles.flexBoxItem}>
        <TextField source="instrumentation.calRgMax" />
      </Labeled>
      <Labeled label="Cal Rg UoM" sx={commonStyles.flexBoxItem}>
        <TextField source="instrumentation.calRgUom" />
      </Labeled>
    </Box>
    <Box>
      <Labeled label="Min Press" sx={commonStyles.flexBoxItem}>
        <TextField source="instrumentation.pressMin" />
      </Labeled>
      <Labeled label="Max Press" sx={commonStyles.flexBoxItem}>
        <TextField source="instrumentation.pressMax" />
      </Labeled>
      <Labeled label="UoM Press" sx={commonStyles.flexBoxItem}>
        <TextField source="instrumentation.pressUom" />
      </Labeled>
      <Labeled label="Min Temp" sx={commonStyles.flexBoxItem}>
        <TextField source="instrumentation.tempMin" />
      </Labeled>
      <Labeled label="Max Temp" sx={commonStyles.flexBoxItem}>
        <TextField source="instrumentation.tempMax" />
      </Labeled>
      <Labeled label="UoM Temp" sx={commonStyles.flexBoxItem}>
        <TextField source="instrumentation.tempUom" />
      </Labeled>
    </Box>
    <Box>
      <Labeled label="Actuator Type" sx={commonStyles.flexBoxItem}>
        <TextField source="instrumentation.actuatorType" />
      </Labeled>
      <Labeled label="Pwr Src" sx={commonStyles.flexBoxItem}>
        <TextField source="instrumentation.pwrSrc" />
      </Labeled>
      <Labeled label="Top" sx={commonStyles.flexBoxItem}>
        <TextField source="instrumentation.top" />
      </Labeled>
      <Labeled label="Vendor Index" sx={commonStyles.flexBoxItem}>
        <TextField source="instrumentation.vendorIndex" />
      </Labeled>
      <Labeled label="Loop Name" sx={commonStyles.flexBoxItem}>
        <TextField source="instrumentation.loopName" />
      </Labeled>
      <Labeled label="Loop Service" sx={commonStyles.flexBoxItem}>
        <TextField source="instrumentation.loopService" />
      </Labeled>
    </Box>
    <Box>
      <Labeled label="Equip Name" sx={commonStyles.flexBoxItem}>
        <TextField source="instrumentation.equipName" />
      </Labeled>
      <Labeled label="IO Type Name" sx={commonStyles.flexBoxItem}>
        <TextField source="instrumentation.ioTypeName" />
      </Labeled>
      <Labeled label="System IO Cable" sx={commonStyles.flexBoxItem}>
        <TextField source="instrumentation.systemIoCab" />
      </Labeled>
      <Labeled label="Criticality" sx={commonStyles.flexBoxItem}>
        <TextField source="instrumentation.criticality" />
      </Labeled>
      <Labeled label="Status" sx={commonStyles.flexBoxItem}>
        <TextField source="instrumentation.status" />
      </Labeled>
    </Box>
    <Box>
      <Labeled label="Inst Location" sx={commonStyles.flexBoxItem}>
        <TextField source="instrumentation.instLocation" />
      </Labeled>
      <Labeled label="Location Plan" sx={commonStyles.flexBoxItem}>
        <TextField source="instrumentation.locationPlan" />
      </Labeled>
      <Labeled label="Heat Trace" sx={commonStyles.flexBoxItem}>
        <TextField source="instrumentation.heatTrace" />
      </Labeled>
      <Labeled label="Scope Area" sx={commonStyles.flexBoxItem}>
        <TextField source="instrumentation.scopeArea" />
      </Labeled>
      <Labeled label="Notes" sx={commonStyles.flexBoxItem}>
        <TextField source="instrumentation.notes" />
      </Labeled>
    </Box>
    <Box>
      <Labeled label="Process Conn Detail" sx={commonStyles.flexBoxItem}>
        <TextField source="instrumentation.processConnDetail" />
      </Labeled>
      <Labeled label="Support Conn Detail" sx={commonStyles.flexBoxItem}>
        <TextField source="instrumentation.supportConnDetail" />
      </Labeled>
      <Labeled label="Electrical Conn Detail" sx={commonStyles.flexBoxItem}>
        <TextField source="instrumentation.electConnDetail" />
      </Labeled>
      <Labeled label="Inst Air Conn Detail" sx={commonStyles.flexBoxItem}>
        <TextField source="instrumentation.instAirConnDetail" />
      </Labeled>
      <Labeled label="Installed" sx={commonStyles.flexBoxItem}>
        <TextField source="instrumentation.installed" />
      </Labeled>
    </Box>
    <Box>
      <Labeled label="Updated At" sx={commonStyles.flexBoxItem}>
        <DateField source="instrumentation.updatedAt" />
      </Labeled>
      <Labeled label="Updated By" sx={commonStyles.flexBoxItem}>
        <TextField source="instrumentation.updatedBy" />
      </Labeled>
      <Labeled label="Updated By Name" sx={commonStyles.flexBoxItem}>
        <TextField source="instrumentation.updatedByName" />
      </Labeled>
    </Box>
  </Fragment>
);

export default InstrumentationShowTab;
