import React, { ReactElement, useEffect, useMemo } from 'react';
import {
  FunctionField,
  useRecordContext,
  useRefresh,
  useResourceContext,
} from 'react-admin';
import { useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import get from 'lodash/get';
import TabbedEditForm from '../common/TabbedEditForm';
import LazyFormTab from '../common/LazyFormTab';
import commonStyles from '../common/commonStyles';
import { EditPropsWithLocation } from '../common/types';
import PermissionsTab from './components/PermissionsTab';
import ProjectsInputField from './components/ProjectsInputField';
import UserForm from './UserForm';
import {
  getContext,
  updateContext,
  UserContext,
} from '../../provider/userContext';
import {
  getEffectivePermissionWithProjectId,
  makeRequestBody,
} from './helpers';

const UserEdit: React.FC<EditPropsWithLocation> = (props): ReactElement => {
  const ctx: UserContext = getContext();
  const resource = useResourceContext();
  const queryClient = useQueryClient();
  const record = useRecordContext();
  const { id } = useParams();
  const userId = get(record, 'id', id);
  const refresh = useRefresh();
  const mutationOptions = useMemo(() => {
    return {
      onSuccess: async (data) => {
        if (data.firstName && data.lastName && ctx.id === userId) {
          const fullName = `${data.firstName} ${data.lastName}`;

          await updateContext({ fullName });
          refresh();
        }
      },
    };
  }, [ctx.id, refresh, userId]);

  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: [resource, 'getOne', { id: String(userId) }],
    });
  }, [queryClient, userId, resource]);

  const transform = (data) => makeRequestBody(data, ctx?.company);

  return (
    <TabbedEditForm
      {...props}
      transform={transform}
      syncWithLocation={false}
      mutationOptions={mutationOptions}
    >
      <LazyFormTab label="User" id="user_tab" sx={commonStyles.formTabHeader}>
        <UserForm />
      </LazyFormTab>
      <LazyFormTab
        label="Permissions"
        id="permissions_tab"
        sx={commonStyles.formTabHeader}
      >
        <FunctionField
          sx={commonStyles.fullWidth}
          render={(user) => {
            const effectivePermissions = getEffectivePermissionWithProjectId(
              get(user, `companies.${ctx?.company}.projects`, []),
              ctx?.projectId
            );
            return (
              <PermissionsTab
                effectivePermissions={effectivePermissions}
                userId={user.id}
              />
            );
          }}
        />
      </LazyFormTab>
      <LazyFormTab
        label="Projects"
        id="projects_tab"
        sx={commonStyles.formTabHeader}
      >
        <ProjectsInputField />
      </LazyFormTab>
    </TabbedEditForm>
  );
};

export default UserEdit;
