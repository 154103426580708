import React, { Fragment, ReactElement } from 'react';
import { maxLength } from 'react-admin';
import Box from '@mui/material/Box';
import commonStyles from '../../common/commonStyles';
import TextInput from '../..//common/TextInput';

const InstrumentationForm: React.FC = (): ReactElement => (
  <Fragment>
    <Box sx={commonStyles.flexBox}>
      <TextInput
        source="instrumentation.stemPosition"
        label="Stem Position"
        validate={[maxLength(20)]}
        sx={commonStyles.flexBoxItem}
      />
      <TextInput
        source="instrumentation.maxOpenCloseTime"
        label="Max Open Close Time"
        validate={[maxLength(20)]}
        sx={commonStyles.flexBoxItem}
      />
      <TextInput
        source="instrumentation.failOpenClose"
        label="Fail Open Close"
        validate={[maxLength(20)]}
        sx={commonStyles.flexBoxItem}
      />
      <TextInput
        source="instrumentation.signalType"
        label="Signal Type"
        validate={[maxLength(20)]}
        sx={commonStyles.flexBoxItem}
      />
      <TextInput
        source="instrumentation.mountedOn"
        label="Mounted On"
        validate={[maxLength(20)]}
        sx={commonStyles.flexBoxItem}
      />
    </Box>
    <Box sx={commonStyles.flexBox}>
      <TextInput
        source="instrumentation.instRgMin"
        label="Inst Rg Min"
        validate={[maxLength(20)]}
        sx={commonStyles.flexBoxItem}
      />
      <TextInput
        source="instrumentation.instRgMax"
        label="Inst Rg Max"
        validate={[maxLength(20)]}
        sx={commonStyles.flexBoxItem}
      />
      <TextInput
        source="instrumentation.instRgUom"
        label="Inst Rg UoM"
        validate={[maxLength(20)]}
        sx={commonStyles.flexBoxItem}
      />
      <TextInput
        source="instrumentation.calRgMin"
        label="Cal Rg Min"
        validate={[maxLength(20)]}
        sx={commonStyles.flexBoxItem}
      />
      <TextInput
        source="instrumentation.calRgMax"
        label="Cal Rg Max"
        validate={[maxLength(20)]}
        sx={commonStyles.flexBoxItem}
      />
      <TextInput
        source="instrumentation.calRgUom"
        label="Cal Rg UoM"
        validate={[maxLength(20)]}
        sx={commonStyles.flexBoxItem}
      />
    </Box>
    <Box sx={commonStyles.flexBox}>
      <TextInput
        source="instrumentation.pressMin"
        label="Min Press"
        validate={[maxLength(20)]}
        sx={commonStyles.flexBoxItem}
      />
      <TextInput
        source="instrumentation.pressMax"
        label="Max Press"
        validate={[maxLength(20)]}
        sx={commonStyles.flexBoxItem}
      />
      <TextInput
        source="instrumentation.pressUom"
        label="UoM Press"
        validate={[maxLength(20)]}
        sx={commonStyles.flexBoxItem}
      />
      <TextInput
        source="instrumentation.tempMin"
        label="Min Temp"
        validate={[maxLength(20)]}
        sx={commonStyles.flexBoxItem}
      />
      <TextInput
        source="instrumentation.tempMax"
        label="Max Temp"
        validate={[maxLength(20)]}
        sx={commonStyles.flexBoxItem}
      />
      <TextInput
        source="instrumentation.tempUom"
        label="UoM Temp"
        validate={[maxLength(20)]}
        sx={commonStyles.flexBoxItem}
      />
    </Box>
    <Box sx={commonStyles.flexBox}>
      <TextInput
        source="instrumentation.actuatorType"
        label="Actuator Type"
        validate={[maxLength(20)]}
        sx={commonStyles.flexBoxItem}
      />
      <TextInput
        source="instrumentation.pwrSrc"
        label="Pwr Src"
        validate={[maxLength(20)]}
        sx={commonStyles.flexBoxItem}
      />
      <TextInput
        source="instrumentation.top"
        label="Top"
        validate={[maxLength(20)]}
        sx={commonStyles.flexBoxItem}
      />
      <TextInput
        source="instrumentation.vendorIndex"
        label="Vendor Index"
        validate={[maxLength(20)]}
        sx={commonStyles.flexBoxItem}
      />
      <TextInput
        source="instrumentation.loopName"
        label="Loop Name"
        validate={[maxLength(20)]}
        sx={commonStyles.flexBoxItem}
      />
      <TextInput
        source="instrumentation.loopService"
        label="Loop Service"
        validate={[maxLength(20)]}
        sx={commonStyles.flexBoxItem}
      />
    </Box>
    <Box sx={commonStyles.flexBox}>
      <TextInput
        source="instrumentation.equipName"
        label="Equip Name"
        validate={[maxLength(20)]}
        sx={commonStyles.flexBoxItem}
      />
      <TextInput
        source="instrumentation.ioTypeName"
        label="IO Type Name"
        validate={[maxLength(20)]}
        sx={commonStyles.flexBoxItem}
      />
      <TextInput
        source="instrumentation.systemIoCab"
        label="System IO Cable"
        validate={[maxLength(20)]}
        sx={commonStyles.flexBoxItem}
      />
      <TextInput
        source="instrumentation.criticality"
        label="Criticality"
        validate={[maxLength(20)]}
        sx={commonStyles.flexBoxItem}
      />
      <TextInput
        source="instrumentation.status"
        label="Status"
        validate={[maxLength(20)]}
        sx={commonStyles.flexBoxItem}
      />
    </Box>
    <Box sx={commonStyles.flexBox}>
      <TextInput
        source="instrumentation.instLocation"
        label="Inst Location"
        validate={[maxLength(20)]}
        sx={commonStyles.flexBoxItem}
      />
      <TextInput
        source="instrumentation.locationPlan"
        label="Location Plan"
        validate={[maxLength(20)]}
        sx={commonStyles.flexBoxItem}
      />
      <TextInput
        source="instrumentation.heatTrace"
        label="Heat Trace"
        validate={[maxLength(20)]}
        sx={commonStyles.flexBoxItem}
      />
      <TextInput
        source="instrumentation.scopeArea"
        label="Scope Area"
        validate={[maxLength(20)]}
        sx={commonStyles.flexBoxItem}
      />
      <TextInput
        source="instrumentation.notes"
        label="Notes"
        validate={[maxLength(20)]}
        sx={commonStyles.flexBoxItem}
      />
    </Box>
    <Box sx={commonStyles.flexBox}>
      <TextInput
        source="instrumentation.processConnDetail"
        label="Process Conn Detail"
        validate={[maxLength(20)]}
        sx={commonStyles.flexBoxItem}
      />
      <TextInput
        source="instrumentation.supportConnDetail"
        label="Support Conn Detail"
        validate={[maxLength(20)]}
        sx={commonStyles.flexBoxItem}
      />
      <TextInput
        source="instrumentation.electConnDetail"
        label="Electrical Conn Detail"
        validate={[maxLength(20)]}
        sx={commonStyles.flexBoxItem}
      />
      <TextInput
        source="instrumentation.instAirConnDetail"
        label="Inst Air Conn Detail"
        validate={[maxLength(20)]}
        sx={commonStyles.flexBoxItem}
      />
      <TextInput
        source="instrumentation.installed"
        label="Installed"
        validate={[maxLength(20)]}
        sx={commonStyles.flexBoxItem}
      />
    </Box>
  </Fragment>
);

export default InstrumentationForm;
