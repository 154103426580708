import React, { ReactElement, useMemo } from 'react';
import {
  AutocompleteInput,
  AutocompleteArrayInput,
  GetListParams,
  ReferenceInput,
  ReferenceArrayInput,
} from 'react-admin';
import { LOOKUP_PAGE_SIZE } from './constants';

type CombinedComponentProps = React.ComponentProps<typeof ReferenceInput> &
  React.ComponentProps<typeof ReferenceArrayInput> &
  React.ComponentProps<typeof AutocompleteInput> &
  React.ComponentProps<typeof AutocompleteArrayInput>;

interface AutocompleteReferenceInputProps
  extends Omit<Partial<CombinedComponentProps>, 'children'> {
  resource: string;
  requestParams?: Partial<GetListParams>;
  disabled?: boolean;
  handleOnChange?: (values, records) => void;
  includeFields?: string[];
  excludeFields?: string[];
  additionalParams?: Record<string, unknown>;
}

const AutocompleteReferenceInput: React.FC<AutocompleteReferenceInputProps> = (
  props
): ReactElement => {
  const {
    multiple,
    source,
    resource,
    enableGetChoices,
    requestParams,
    label,
    name,
    page,
    perPage = LOOKUP_PAGE_SIZE,
    queryOptions: paramsQueryOptions,
    disabled,
    handleOnChange,
    id,
    includeFields,
    excludeFields,
    additionalParams,
    ...rest
  } = props;
  const { filter, sort } = requestParams ?? {};
  const { meta: paramsMeta } = paramsQueryOptions ?? {};

  const queryOptions = useMemo(
    () => ({
      ...paramsQueryOptions,
      meta: {
        ...paramsMeta,
        ...additionalParams,
        includeFields,
        excludeFields,
      },
    }),
    [
      additionalParams,
      paramsMeta,
      paramsQueryOptions,
      includeFields,
      excludeFields,
    ]
  );

  const referenceProps = {
    source,
    reference: resource,
    filter,
    label,
    page,
    perPage,
    queryOptions,
    sort,
    enableGetChoices: disabled ? () => false : enableGetChoices,
  };
  const autocompleteProps = {
    ...rest,
    source,
    resource,
    label,
    disabled,
    onChange: handleOnChange,
  };

  const ReferenceComponent = multiple ? ReferenceArrayInput : ReferenceInput;
  const AutocompleteComponent = multiple
    ? AutocompleteArrayInput
    : AutocompleteInput;

  const filterToQuery = (value) => ({
    [name]: value,
  });

  return (
    <ReferenceComponent {...referenceProps}>
      <AutocompleteComponent
        {...autocompleteProps}
        id={id || autocompleteProps.source}
        filterToQuery={name ? filterToQuery : undefined}
      />
    </ReferenceComponent>
  );
};

export default AutocompleteReferenceInput;
