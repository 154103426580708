import { RowForm, useRowContext } from '@react-admin/ra-editable-datagrid';
import React, { ReactElement, useCallback } from 'react';
import { Identifier } from 'react-admin';
import useInvalidateResourceQueryCache from '../../../common/hooks/useInvalidateResourceQueryCache';
import { RESOURCE_CERT_GATING } from '../../../constants';
import useInvalidateManyReferenceQueryCache from '../../../common/hooks/useInvalidateManyReferenceQueryCache';

interface GatingFormRowProps {
  recordId: Identifier;
  projectId: Identifier;
  children: ReactElement;
  reference: string;
}

const GatingFormRow: React.FC<GatingFormRowProps> = ({
  children,
  projectId,
  recordId,
  reference,
}): ReactElement => {
  const { close } = useRowContext();
  const invalidateCertTemplateQueries =
    useInvalidateResourceQueryCache(RESOURCE_CERT_GATING);
  const invalidateReferenceQueries =
    useInvalidateManyReferenceQueryCache(reference);

  const handleSuccess = useCallback(async () => {
    await invalidateCertTemplateQueries();
    await invalidateReferenceQueries();
    close();
  }, [close, invalidateCertTemplateQueries, invalidateReferenceQueries]);

  return (
    <RowForm
      transform={(data) => ({
        ...data,
        certTemplateId: recordId,
        projectId,
      })}
      mutationOptions={{
        onSuccess: handleSuccess,
      }}
    >
      {children}
    </RowForm>
  );
};

export default GatingFormRow;
