import React, { Fragment, ReactElement, useCallback } from 'react';
import { BooleanInput, CreateProps, FileField, SimpleForm } from 'react-admin';
import { Toolbar, Box } from '@mui/material';
import useDataProvider from '../../provider/dataProvider/useDataProvider';
import { required } from '../../utils/UtilityFunctions';
import { RawAttachment } from '../attachment/types';
import SaveButton from '../common/SaveButton';
import CustomNumberInput from '../common/CustomNumberInput';
import CustomDateInput from '../common/CustomDateInput';
import CustomFileInput from '../common/CustomFileInput';
import useUpload from '../common/hooks/useUpload';
import { ATTACHMENT_TYPE_ENUMS } from '../common/enums';
import commonStyles from '../common/commonStyles';
import { RESOURCE_ATTACHMENT_REVISION } from '../constants';

type AttachmentRevisionCreate = {
  file: RawAttachment;
  revision: string;
  revisionDate: string;
  isActive: boolean;
};

interface AttachmentRevisionCreateFormProps extends CreateProps {
  onSubmit?: () => void;
}

const AttachmentRevisionCreateForm: React.FC<
  AttachmentRevisionCreateFormProps
> = ({ onSubmit, record }): ReactElement => {
  const dataProvider = useDataProvider();
  const { upload } = useUpload({
    onSuccess: onSubmit,
    createRecord: dataProvider.createFormData,
  });

  const handleSubmit = useCallback(
    async (data: AttachmentRevisionCreate) => {
      const newData = {
        attachmentType: ATTACHMENT_TYPE_ENUMS.GENERAL,
        attachmentId: record.id,
        files: [data.file.rawFile],
        isActive: String(data.isActive),
        revisionDate: data?.revisionDate || new Date(),
        revision: data?.revision,
      };

      await upload(RESOURCE_ATTACHMENT_REVISION, newData);
    },
    [upload, record]
  );

  return (
    <SimpleForm
      id="create_revision_form"
      onSubmit={handleSubmit}
      toolbar={
        <Toolbar>
          <SaveButton />
        </Toolbar>
      }
    >
      <CustomFileInput
        source="file"
        label={false}
        sx={commonStyles.uploadField}
        validate={[required()]}
      >
        <Fragment>
          <FileField source="src" title="title" target="_blank" />
          <Box sx={commonStyles.flexBox}>
            <CustomNumberInput
              source="revision"
              label="Revision"
              isPositive
              sx={commonStyles.flexBoxItem}
            />
            <CustomDateInput
              source="revisionDate"
              label="Revision Date"
              sx={commonStyles.flexBoxItem}
            />
          </Box>
          <Box sx={commonStyles.flexBox}>
            <BooleanInput label="Active" source="isActive" />
          </Box>
        </Fragment>
      </CustomFileInput>
    </SimpleForm>
  );
};

export default AttachmentRevisionCreateForm;
