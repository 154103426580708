import { Toolbar } from '@mui/material';
import React, { useCallback, ReactElement } from 'react';
import {
  CreateProps,
  Identifier,
  FileField,
  SimpleForm,
  useResourceContext,
} from 'react-admin';
import useInvalidateResourceQueryCache from '../../common/hooks/useInvalidateResourceQueryCache';
import { UserContext, getContext } from '../../../provider/userContext';
import { isEmpty, required } from '../../../utils/UtilityFunctions';
import { ATTACHMENT_TYPE_ENUMS } from '../../common/enums';
import SaveButton from '../../common/SaveButton';
import { RESOURCE_ATTACHMENT } from '../../constants';
import useUpload from '../../common/hooks/useUpload';
import commonStyles from '../../common/commonStyles';
import AttachmentTypeInput from '../../common/AttachmentTypeInput';
import CustomFileInput from '../../common/CustomFileInput';
import { RawAttachment } from '../types';
import { ACCEPTED_FORMATS_IMAGE_ALL } from '../../common/constants';
import { AxiosResponse } from 'axios';

type UploadAttachments = {
  attachments: RawAttachment[];
  fileName: string;
  description: string;
  subtypeId?: number;
};

interface AttachmentCreateProps extends CreateProps {
  targetIdFieldName: string;
  targetIdFieldValue: Identifier;
  onUpload?: (result: AxiosResponse | AxiosResponse[]) => void;
  isImage: boolean;
  withoutProjectId?: boolean;
  attachmentType?: ATTACHMENT_TYPE_ENUMS;
}

const AttachmentCreateForm: React.FC<AttachmentCreateProps> = ({
  targetIdFieldName,
  targetIdFieldValue,
  onUpload,
  isImage,
  withoutProjectId,
  attachmentType = ATTACHMENT_TYPE_ENUMS.GENERAL,
}): ReactElement => {
  const { upload } = useUpload({
    onSuccess: onUpload,
  });
  const ctx: UserContext = getContext();
  const resource = useResourceContext();
  const invalidateAttachmentQueries =
    useInvalidateResourceQueryCache(RESOURCE_ATTACHMENT);

  const handleSubmit = useCallback(
    async (data: UploadAttachments) => {
      const newData = {
        files: data.attachments.map((attachment) => attachment.rawFile),
        attachmentType,
        [targetIdFieldName]: targetIdFieldValue,
        resourceName: resource,
        ...(!isEmpty(data.fileName) && {
          fileName: data.fileName.split('.')[0],
        }),
        ...(!isEmpty(data.description) && {
          description: data.description,
        }),
        ...(!isEmpty(data.subtypeId) && {
          subtypeId: data.subtypeId,
        }),
        ...(!withoutProjectId ? { projectId: String(ctx?.projectId) } : {}),
      };

      await upload(RESOURCE_ATTACHMENT, newData);
      await invalidateAttachmentQueries();
    },
    [
      targetIdFieldName,
      targetIdFieldValue,
      resource,
      withoutProjectId,
      upload,
      invalidateAttachmentQueries,
      ctx?.projectId,
      attachmentType,
    ]
  );

  return (
    <SimpleForm
      id="upload_attachment_form"
      onSubmit={handleSubmit}
      toolbar={
        <Toolbar>
          <SaveButton />
        </Toolbar>
      }
    >
      <CustomFileInput
        source="attachments"
        label={false}
        sx={commonStyles.uploadField}
        multiple
        validate={[required()]}
        accept={isImage ? ACCEPTED_FORMATS_IMAGE_ALL : undefined}
      >
        <FileField source="src" title="title" target="_blank" />
      </CustomFileInput>
      {!isImage && <AttachmentTypeInput />}
    </SimpleForm>
  );
};

export default AttachmentCreateForm;
