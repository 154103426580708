import { AuthProvider } from 'react-admin';
import {
  AuthProviderExt,
  CognitoAuthProvider,
} from './providers/cognitoAuthProvider';
import { ApiKeyAuthProvider } from './providers/apiKeyAuthProvider';
import { getContext, UserContext } from '../userContext';
import { environment } from '../api';

export let authProvider: AuthProviderExt | AuthProvider = undefined;

export const getAuthProvider = (
  getForceCognitoProvider?: boolean
): AuthProviderExt | AuthProvider => {
  if (getForceCognitoProvider) {
    return getCognitoAuthProvider();
  }

  if (!authProvider) {
    const ctx: UserContext = getContext();
    const urlParams = new URLSearchParams(window.location.search);

    if (urlParams.has('apiKey') || ctx?.apiKey) {
      const smartITRUser = urlParams.get('smartITRUser');
      const apiKey = urlParams.get('apiKey');
      const companyName = urlParams.get('companyName');
      const projectId = urlParams.get('projectId');

      authProvider = ApiKeyAuthProvider({
        smartITRUser,
        apiKey,
        companyName,
        projectId,
      });
    } else {
      authProvider = getCognitoAuthProvider();
    }
  }

  return authProvider;
};

const getCognitoAuthProvider = (): AuthProviderExt | AuthProvider => {
  let cognitoAuthProvider: AuthProviderExt | AuthProvider = undefined;
  switch (environment) {
    case 'local':
      cognitoAuthProvider = CognitoAuthProvider({
        mode: 'oauth',
        clientId: '6vg5gb2dushi3iu3acvvp01eqv',
        userPoolId: 'eu-west-1_RYSpkn9SP',
        hostedUIUrl: 'https://auth-v2.tracker-check-dev.trackersuite.co',
        redirect_uri: 'http://localhost:3000/auth-callback',
        scope: ['openid', 'email', 'profile', 'aws.cognito.signin.user.admin'],
      });
      break;
    case 'dev':
      cognitoAuthProvider = CognitoAuthProvider({
        mode: 'oauth',
        clientId: '6vg5gb2dushi3iu3acvvp01eqv',
        userPoolId: 'eu-west-1_RYSpkn9SP',
        hostedUIUrl: 'https://auth-v2.tracker-check-dev.trackersuite.co',
        redirect_uri: 'https://tracker-check-dev.trackersuite.co/auth-callback',
        scope: ['openid', 'email', 'profile', 'aws.cognito.signin.user.admin'],
      });
      break;
    case 'prod':
      cognitoAuthProvider = CognitoAuthProvider({
        mode: 'oauth',
        clientId: '6f1vdnuftu8fmqchgthppol556',
        userPoolId: 'us-east-2_UUvS1i0cM',
        hostedUIUrl: 'https://auth-v2.tracker-check.trackersuite.co',
        redirect_uri: 'https://tracker-check.trackersuite.co/auth-callback',
        scope: ['openid', 'email', 'profile', 'aws.cognito.signin.user.admin'],
      });
      break;
    default:
      throw `Unknown environment:${environment}`;
  }

  return cognitoAuthProvider;
};
