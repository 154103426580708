import React, { Fragment, ReactElement, useMemo } from 'react';
import { Badge } from '@mui/material';
import { Box } from '@mui/system';
import Tooltip from '../../common/Tooltip';
import AutocompleteReferenceInput from '../../common/AutocompleteReferenceInput';
import commonStyles from '../../common/commonStyles';
import { RESOURCE_SECURITY_GROUP } from '../../constants';
import { getRoleGroupValidator } from '../helpers';

interface GroupInputProps {
  label: string;
  source: string;
}

const GroupInput: React.FC<GroupInputProps> = ({
  label,
  source,
}): ReactElement => {
  const requestParams = useMemo(() => {
    return {
      sort: { field: 'groupName', order: 'ASC' as const },
      filter: { isDeleted: false },
    };
  }, []);

  return (
    <Fragment>
      <AutocompleteReferenceInput
        resource={RESOURCE_SECURITY_GROUP}
        requestParams={requestParams}
        source={source}
        label={label}
        name="groupName_inc"
        optionText="groupName"
        multiple
        validate={[getRoleGroupValidator()]}
        sx={{ ...commonStyles.flexBoxSelectItem, marginRight: 0 }}
        includeFields={['id', 'groupName']}
      />
      <Tooltip title="To control access, select one or more Groups. In most cases, selecting a single Group will suffice. Leave the Roles field blank.">
        <Box sx={{ marginTop: '20px', marginRight: '20px', fontSize: 12 }}>
          <Badge
            badgeContent="i"
            color="primary"
            sx={{ paddingLeft: '12px', paddingBottom: '5px' }}
          />
        </Box>
      </Tooltip>
    </Fragment>
  );
};

export default GroupInput;
