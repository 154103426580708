import React, { Fragment, ReactElement, useState } from 'react';
import {
  AppBar as BaseAppBar,
  RefreshIconButton,
  AppBarProps,
  TitlePortal,
} from 'react-admin';
import Logo from './components/common/Logo';
import ProjectSwitch from './components/project/ProjectSwitch';
import UserMenu from './components/userMenu/UserMenu';
import { ReactComponent as RefreshIcon } from './assets/icons/functionalIcons/Refresh.svg';
import NotificationBase from './components/notification/NotificationBase';
import { getContext, UserContext } from './provider/userContext';
import { ContactSupportModal } from './components/support/ContactSupportModal';
import SubscriptionInfo from './components/subscriptionInfo/SubscriptionInfo';
import ModalApproval from './components/modalApproval/ModalApproval';

const AppBar: React.FC<AppBarProps> = (props): ReactElement => {
  const ctx: UserContext = getContext();
  const [supportModalOpen, setSupportModalOpen] = useState(false);

  const handleOnClose = (_e, reason: 'backdropClick' | 'escapeKeyDown') => {
    if (reason === 'backdropClick') return;
    setSupportModalOpen(false);
  };

  const handleFeedbackFormClick = () => {
    setSupportModalOpen(true);
  };

  return (
    <BaseAppBar
      {...props}
      sx={{ borderRadius: '4px' }}
      toolbar={
        <Fragment>
          <ProjectSwitch />
          {ctx?.id ? <ModalApproval /> : null}
          {ctx?.id && ctx?.ablyToken && ctx?.projectId ? (
            <NotificationBase />
          ) : null}
          <RefreshIconButton icon={<RefreshIcon />} />
        </Fragment>
      }
      userMenu={<UserMenu handleFeedbackFormClick={handleFeedbackFormClick} />}
    >
      <Logo />
      <TitlePortal align="center" />
      <ContactSupportModal open={supportModalOpen} onClose={handleOnClose} />
      <SubscriptionInfo />
    </BaseAppBar>
  );
};

export default AppBar;
