import React, { ReactElement } from 'react';
import { TextField } from 'react-admin';
import { RESOURCE_PRESERVATION_SERIES } from '../constants';
import SmartLinkField from './SmartLinkField';

interface PreservationSeriesFieldProps {
  label?: string;
  source?: string;
}

const PreservationSeriesField: React.FC<PreservationSeriesFieldProps> = ({
  label,
  source,
}): ReactElement => (
  <SmartLinkField
    label={label}
    source={source}
    resource={RESOURCE_PRESERVATION_SERIES}
    sortBy="preservationSeries.id"
  >
    <TextField source="preservationSeriesId" />
  </SmartLinkField>
);

PreservationSeriesField.defaultProps = {
  label: 'Preservation Series',
  source: 'preservationSeriesId',
};

export default PreservationSeriesField;
