import React, { ReactElement } from 'react';
import { ArrayInput, SimpleFormIterator } from 'react-admin';
import { getContext, UserContext } from '../../../provider/userContext';
import { required } from '../../../utils/UtilityFunctions';
import ProjectInput from './ProjectInput';
import RoleInput from './RoleInput';
import GroupInput from './GroupInput';
import UserGroupInput from './UserGroupInput';

const ProjectsInputField: React.FC = (): ReactElement => {
  const ctx: UserContext = getContext();

  return (
    <ArrayInput
      label="Projects"
      source={`companies.${ctx?.company}.projects`}
      name={`companies.${ctx?.company}.projects`}
      validate={[required()]}
    >
      <SimpleFormIterator inline>
        <ProjectInput source="id" label="Project" isRequired />
        <GroupInput source="groups" label="Groups *" />
        <RoleInput source="roles" label="Roles" />
        <UserGroupInput source="userGroups" label="User Groups" />
      </SimpleFormIterator>
    </ArrayInput>
  );
};

export default ProjectsInputField;
