import { Datagrid, TextField } from 'react-admin';
import React, { ReactElement } from 'react';
import commonStyles from '../common/commonStyles';
import Box from '@mui/material/Box';
import DownloadButton from '../common/DownloadButton';
import FreeTextSearchFilter from '../common/FreeTextSearchFilter';
import renderMetaDataFields from '../common/helpers/renderMetaDataFields';
import useDefaultFilter from '../common/hooks/useDefaultFilter';
import ListWithTitle from '../common/ListWithTitle';
import { RESOURCE_ATTACHMENT } from '../constants';
import useResourceTitle from '../common/hooks/useResourceTitle';
import { setPageTitle } from '../common/helpers/setPageTitle';
import ListActions from '../common/ListActions';

const PrintedBooksList: React.FC = (): ReactElement => {
  const title: string = useResourceTitle(true);
  setPageTitle(title);
  const defaultFilter = useDefaultFilter('project', {
    resourceName: 'book',
    attachmentType: 'GENERAL',
    isDeleted: false,
  });

  return (
    <ListWithTitle
      title={title}
      resource={RESOURCE_ATTACHMENT}
      filters={<FreeTextSearchFilter />}
      filterDefaultValues={defaultFilter}
      actions={
        <ListActions
          showAction={{
            import: false,
            export: false,
            effective: false,
            create: false,
            select: false,
            expand: false,
            collapse: false,
          }}
        />
      }
      sort={{ field: 'fileName', order: 'ASC' }}
      storeKey={'printed-books-list'}
    >
      <Datagrid bulkActionButtons={false} rowClick={false}>
        <TextField source="fileName" />
        {renderMetaDataFields()}
        <Box sx={commonStyles.columnActionsBox}>
          <DownloadButton />
        </Box>
      </Datagrid>
    </ListWithTitle>
  );
};

export default PrintedBooksList;
