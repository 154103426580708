import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import {
  ControlProps,
  RankedTester,
  rankWith,
  isDateControl,
} from '@jsonforms/core';
import { useFormContext } from 'react-hook-form';
import { withJsonFormsControlProps } from '@jsonforms/react';
import { Unwrapped } from '@jsonforms/material-renderers';
import { Grid } from '@mui/material';
import { getCurrentDate, isValidDate } from './helpers';
import { isDateField } from '../../../utils/UtilityFunctions';
import StepAction from './StepAction';

const { MaterialDateControl } = Unwrapped;

export const DateControl: React.FC<ControlProps> = (props): ReactElement => {
  const formContext = useFormContext();
  const formData = formContext?.watch('formData');
  const [stepAction, setStepAction] = useState({});
  const isDate = isDateField(props.path);
  const format = props?.uischema?.options?.dateSaveFormat;
  const currentDate = getCurrentDate(format);

  const handleOnClick = useCallback(() => {
    if (props.enabled && isDate && !props.data) {
      props.handleChange(props.path, currentDate);
    }
  }, [props, currentDate, isDate]);

  const handleOnChange = useCallback(
    (path: string, value) => {
      props.handleChange(path, isValidDate(value, format) ? value : '');
    },
    [props, format]
  );

  useEffect(() => {
    if (formData?.stepActions?.[props.path]) {
      setStepAction(formData.stepActions[props.path]);
    }
  }, [formData, setStepAction, props]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <span onClick={handleOnClick}>
          <MaterialDateControl {...props} handleChange={handleOnChange} />
        </span>
        {stepAction && <StepAction data={stepAction} />}
      </Grid>
    </Grid>
  );
};

export const DateControlTester: RankedTester = rankWith(5, isDateControl);

export default withJsonFormsControlProps(DateControl);
