import React, { ReactElement } from 'react';
import { minValue, SimpleForm } from 'react-admin';
import { Toolbar } from '@mui/material';
import Box from '@mui/material/Box';
import StorageInput from '../../../../common/StorageInput/StorageInput';
import CustomNumberInput from '../../../../common/CustomNumberInput';
import commonStyles from '../../../../common/commonStyles';
import { required } from 'ra-core/dist/cjs/form/validate';
import SaveButton from '../../../../common/SaveButton';
import {
  useDictionaryLabelsWithResource,
  integer,
} from '../../../../../utils/UtilityFunctions';

interface StorageTabFormProps {
  onSubmit: (data: Record<string, string>) => void;
  record?: Record<string, string>;
}

const StorageTabForm: React.FC<StorageTabFormProps> = ({
  onSubmit,
  record,
}): ReactElement => {
  const { labels } = useDictionaryLabelsWithResource();

  return (
    <SimpleForm
      id="material_storage_form"
      onSubmit={onSubmit}
      toolbar={
        <Toolbar>
          <SaveButton />
        </Toolbar>
      }
    >
      <Box sx={{ ...commonStyles.flexBox, ...commonStyles.fullWidth }}>
        <StorageInput
          canCreate
          sx={commonStyles.halfWidth}
          defaultValue={record?.storageId || ''}
        />
        <CustomNumberInput
          source="qtyOnHand"
          name="qtyOnHand"
          label={labels['qtyOnHand'] || 'Qty On Hand'}
          sx={commonStyles.halfWidth}
          validate={[required(), minValue(0), integer()]}
          defaultValue={Number(record?.qtyOnHand || 0)}
        />
      </Box>
    </SimpleForm>
  );
};

export default StorageTabForm;
