import React, { ReactElement, useCallback, useState } from 'react';
import { TextInput, minLength } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { required } from '../../utils/UtilityFunctions';
import { generateRandomCode } from '../user/helpers';

interface PinCodeInputProps {
  source?: string;
  label?: string;
}

const PinCodeInput: React.FC<PinCodeInputProps> = ({
  source,
  label,
}): ReactElement => {
  const { setValue } = useFormContext();
  const [isError, setIsError] = useState(false);

  const onChange = useCallback(
    (e) => {
      const currentValue = e.target.value.slice(0, 4).replace(/[^\d]/g, '');

      setIsError(currentValue.length < 4);
      setValue(source, currentValue);
    },
    [setValue, source]
  );

  return (
    <TextInput
      error={isError}
      label={label}
      helperText={isError ? 'Only 4-digit numbers are allowed.' : ''}
      source={source}
      onChange={onChange}
      validate={[required(), minLength(4)]}
      defaultValue={generateRandomCode()}
    />
  );
};

PinCodeInput.defaultProps = {
  source: 'pinCode',
  label: 'Pin Code (4-digit number)',
};

export default PinCodeInput;
