import React, { ReactElement } from 'react';
import { TextField } from 'react-admin';
import { RESOURCE_POSITION } from '../constants';
import SmartLinkField from './SmartLinkField';

interface PositionFieldProps {
  label?: string;
  source?: string;
  sx?: object;
}
const PositionField: React.FC<PositionFieldProps> = ({
  label,
  source,
  sx,
}): ReactElement => (
  <SmartLinkField
    label={label}
    source={source}
    resource={RESOURCE_POSITION}
    sortBy="position.position"
  >
    <TextField source="position.position" sx={sx} />
  </SmartLinkField>
);

PositionField.defaultProps = { label: 'Position', source: 'positionId' };

export default PositionField;
