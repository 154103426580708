import React, { ReactElement } from 'react';
import { ReferenceManyField, useRecordContext } from 'react-admin';
import { EditableDatagrid } from '@react-admin/ra-editable-datagrid';
import { RESOURCE_MATERIAL_COMPONENT } from '../constants';
import DeleteWithConfirmIconButton from './DeleteWithConfirmIconButton';
import MaterialRowForm from './MaterialRowForm';
import MaterialComponentDatagridColumns from './MaterialComponentDatagridColumns';
import commonStyles from './commonStyles';

const MaterialComponentFormTab: React.FC = (): ReactElement => {
  const record = useRecordContext();

  return (
    <ReferenceManyField
      target="componentId"
      reference={RESOURCE_MATERIAL_COMPONENT}
    >
      <EditableDatagrid
        bulkActionButtons={false}
        mutationMode="pessimistic"
        editForm={null}
        createForm={
          <MaterialRowForm targetId={record.id} targetField="componentId" />
        }
        sx={commonStyles.formTabDatagrid}
        actions={<DeleteWithConfirmIconButton />}
      >
        {MaterialComponentDatagridColumns()}
      </EditableDatagrid>
    </ReferenceManyField>
  );
};

export default MaterialComponentFormTab;
