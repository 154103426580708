import React, { ReactElement, useEffect } from 'react';
import { Button, Filter } from 'react-admin';
import { Card, CardContent } from '@mui/material';
import isEmpty from 'lodash/isEmpty';
import MobileTestStatusInput from '../../componentTest/components/MobileTestStatusInput';
import { ContactCategories } from '../../common/contactInput/constants';
import useSidebarFilters from '../../common/hooks/useSidebarFilters';
import TestFormNoInput from '../../common/TestFormNoInput';
import DisciplineInput from '../../common/DisciplineInput';
import SubsystemInput from '../../common/SubsystemInput';
import WorkPackInput from '../../common/WorkPackInput';
import AssignedInput from '../../common/Assigned/AssignedInput';
import sideFilterStyles from '../../common/sideFilterStyles';
import VendorInput from '../../common/VendorInput/VendorInput';
import { RESOURCE_SMART_TAG_ITR } from '../../constants';
import ComponentInput from '../../common/ComponentInput';
import LocationInput from '../../common/LocationInput';
import TestTypeInput from '../../common/TestTypeInput';
import { SideFilter } from '../../common/types';
import { mobileTestStatuses } from '../constants';

const SmartTagITRSidebarFilters: React.FC<SideFilter> = ({
  defaultFilter,
}): ReactElement => {
  const { data, isLoading, filterValues, setFilters, handleClearFilters } =
    useSidebarFilters(RESOURCE_SMART_TAG_ITR, defaultFilter);

  useEffect(() => {
    if (filterValues) {
      setFilters({ ...defaultFilter, ...filterValues }, []);
    }
    // eslint-disable-next-line
  }, [JSON.stringify(filterValues), setFilters, JSON.stringify(defaultFilter)]);

  if (!data || (!data.length && isEmpty(filterValues)) || isLoading) {
    return null;
  }

  return (
    <Card sx={sideFilterStyles.sideFiltersCard}>
      <CardContent sx={sideFilterStyles.sideFiltersCardContainer}>
        <Filter>
          <ComponentInput
            label="Tag"
            alwaysOn
            multiple
            sx={sideFilterStyles.sideFiltersSelectInput}
          />
          <TestFormNoInput
            label="ITR"
            alwaysOn
            multiple
            sx={sideFilterStyles.sideFiltersSelectInput}
          />
          <TestTypeInput
            label="ITR Type"
            source="test->testTypeId"
            alwaysOn
            multiple
            sx={sideFilterStyles.sideFiltersSelectInput}
          />
          <SubsystemInput
            source="component->subsystemId"
            alwaysOn
            multiple
            sx={sideFilterStyles.sideFiltersSelectInput}
          />
          <DisciplineInput
            source="component->disciplineId"
            alwaysOn
            multiple
            sx={sideFilterStyles.sideFiltersSelectInput}
          />
          <LocationInput
            alwaysOn
            multiple
            sx={sideFilterStyles.sideFiltersSelectInput}
          />
          <WorkPackInput
            source="component->workPackId"
            alwaysOn
            multiple
            sx={sideFilterStyles.sideFiltersSelectInput}
          />
          <AssignedInput
            label="Responsible"
            category={ContactCategories.itrEmpWorkGrp}
            alwaysOn
            sx={sideFilterStyles.sideFiltersSelectInput}
          />
          <VendorInput source="responsibleVendorId" alwaysOn multiple />
          <MobileTestStatusInput
            label="Status"
            source="mobileTestStatus"
            fullWidth
            alwaysOn
            multiple
            sx={sideFilterStyles.sideFiltersSelectInput}
            choices={mobileTestStatuses}
          />
        </Filter>
        <Button label="Clear filters" onClick={handleClearFilters} />
      </CardContent>
    </Card>
  );
};

export default SmartTagITRSidebarFilters;
