import React, { ReactElement } from 'react';
import { useRecordContext } from 'react-admin';
import SubsystemField from 'src/components/common/SubsystemField';
import { getCertDetailsSubsystemSource } from './helpers';

interface CertDetailsSubsystemFieldProps {
  label?: string;
}

const CertDetailsSubsystemField: React.FC<CertDetailsSubsystemFieldProps> = ({
  label,
}): ReactElement => {
  const record = useRecordContext();
  const { source, textSource } = getCertDetailsSubsystemSource(record);

  return (
    <SubsystemField source={source} textSource={textSource} label={label} />
  );
};

CertDetailsSubsystemField.defaultProps = {
  label: 'Subsystem',
};

export default CertDetailsSubsystemField;
