import React, { Fragment, ReactElement } from 'react';
import { TabbedForm, useRecordContext } from 'react-admin';
import { JsonInput } from 'react-admin-json-view';
import { Box } from '@mui/system';
import FormToolBar from '../../attachment/components/FormToolBar';
import useTemplateUpdateCanAccess from '../../common/hooks/useTemplateUpdateCanAccess';
import LazyFormTab from '../../common/LazyFormTab';
import CustomNumberInput from '../../common/CustomNumberInput';
import CustomDateInput from '../../common/CustomDateInput';
import commonStyles from '../../common/commonStyles';
import UpdateRevisionFileButton from './UpdateRevisionFileButton';
import DownloadJSONButton from './DownloadJSONButton';
import ImportJSONButton from './ImportJSONButton';

interface AttachmentRevisionEditTabbedFormProps {
  onClose: () => void;
  onSubmit: (data) => Promise<void>;
}

const AttachmentRevisionEditTabbedForm: React.FC<
  AttachmentRevisionEditTabbedFormProps
> = ({ onClose, onSubmit }): ReactElement => {
  const record = useRecordContext();
  const templateUpdateEnabled = useTemplateUpdateCanAccess(
    record?.attachment?.resourceName
  );

  return (
    <TabbedForm
      id="edit_attachment_revision_form"
      toolbar={
        <FormToolBar onCancel={onClose} deleteButtonProps={{ show: false }} />
      }
      onSubmit={onSubmit}
      syncWithLocation={false}
      record={record}
    >
      <LazyFormTab label="Revision" id="revision_tab">
        <Fragment>
          <CustomNumberInput
            source="revision"
            label="Revision"
            isPositive
            sx={commonStyles.flexBoxItem}
          />
          <CustomDateInput source="revisionDate" label="Revision Date" />
          {templateUpdateEnabled && <UpdateRevisionFileButton />}
        </Fragment>
      </LazyFormTab>
      <LazyFormTab label="Details" id="revision_details_tab">
        <JsonInput
          source="details"
          jsonString={false}
          reactJsonOptions={{
            name: null,
            collapsed: true,
            enableClipboard: true,
            displayDataTypes: false,
          }}
        />
        <Box sx={commonStyles.flexBox}>
          <DownloadJSONButton />
          {templateUpdateEnabled && <ImportJSONButton />}
        </Box>
      </LazyFormTab>
    </TabbedForm>
  );
};

export default AttachmentRevisionEditTabbedForm;
