import React, { ReactElement } from 'react';
import { TextField } from 'react-admin';
import { RESOURCE_PUNCH_PRIORITY } from '../../../../constants';
import SmartLinkField from '../../../../common/SmartLinkField';

interface PunchPriorityFieldProps {
  label?: string;
  source?: string;
  sx?: object;
}

const PunchPriorityField: React.FC<PunchPriorityFieldProps> = ({
  label,
  source,
  sx,
}): ReactElement => (
  <SmartLinkField
    label={label}
    source={source}
    resource={RESOURCE_PUNCH_PRIORITY}
    sortBy="punchPriority.priority"
  >
    <TextField source="punchPriority.priority" sx={sx} />
  </SmartLinkField>
);

PunchPriorityField.defaultProps = {
  label: 'Punch Priority',
  source: 'punchPriorityId',
};

export default PunchPriorityField;
