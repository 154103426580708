import React, {
  ReactElement,
  Fragment,
  useCallback,
  useState,
  useEffect,
} from 'react';
import {
  TextField,
  useStore,
  RowClickFunction,
  useResourceContext,
} from 'react-admin';
import { Button } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import useDefaultFilter from '../common/hooks/useDefaultFilter';
import ListWithTitle from '../common/ListWithTitle';
import MobileTestStatusField from '../componentTest/components/MobileTestStatusField';
import SmartTagITRSidebarFilters from './components/SmartTagITRSidebarFilters';
import renderMetaDataFields from '../common/helpers/renderMetaDataFields';
import DatagridConfigurableRbac from '../common/DatagridConfigurableRbac';
import FreeTextSearchFilter from '../common/FreeTextSearchFilter';
import FilterMyItemsButton from '../common/FilterMyItemsButton';
import { getSideFiltersKey } from '../common/helpers/storeKeys';
import BulkActionButtons from './components/BulkActionButtons';
import AssignedField from '../common/Assigned/AssignedField';
import SubsystemField from '../common/SubsystemField';
import { RESOURCE_SMART_TAG_ITR } from '../constants';
import ComponentField from '../common/ComponentField';
import WorkPackField from '../common/WorkPackField';
import LocationField from '../common/LocationField';
import ColumnActions from '../common/ColumnActions';
import LegendButton from '../common/LegendButton';
import SystemField from '../common/SystemField';
import ListActions from '../common/ListActions';
import SmartTagITREdit from './SmartTagITREdit';
import VendorField from '../common/VendorField';
import TestField from '../common/TestField';
import { RED } from '../../theme/colors';
import { SmartTagItr } from './types';
import useCheckCurrentProjectResourceAccessSettings from '../common/hooks/useCheckCurrentProjectResourceAccessSettings';

const legends = [
  {
    label: 'Rejected ITR',
    color: RED,
  },
];

const SmartTagITRList: React.FC = (): ReactElement => {
  useCheckCurrentProjectResourceAccessSettings();

  const queryClient = useQueryClient();
  const resource: string = useResourceContext();
  const sideFilter = getSideFiltersKey(resource);
  const [showSideFilter] = useStore(sideFilter, false);
  const [selectedRecordId, setSelectedRecordId] = useState<number | null>(null);
  const [expanded, setExpanded] = useStore(`${resource}.datagrid.expanded`, []);
  const defaultFilter = useDefaultFilter('project', {
    isDeleted: false,
    isMobile: true,
    mobileTestStatus: ['NONE', 'REJECTED'],
  });

  const rowClick: RowClickFunction = useCallback(
    async (id, resource): Promise<false> => {
      if (!expanded.length || !expanded.includes(id)) {
        await queryClient.invalidateQueries({
          queryKey: [resource, 'getOne', { id: String(id) }],
        });
        setExpanded([id]);
      } else {
        setExpanded([]);
      }

      return false;
    },
    [expanded, setExpanded, queryClient]
  );

  const onCancel = useCallback(() => setExpanded([]), [setExpanded]);

  useEffect(() => {
    setSelectedRecordId(!expanded?.length ? null : expanded[0]);
  }, [setSelectedRecordId, expanded]);

  const getRowStyle = (record: SmartTagItr) => ({
    backgroundColor: expanded.includes(record.id)
      ? 'rgba(0, 0, 0, 0.04)'
      : 'initial',
    borderLeftColor: getRowBorderColor(record),
    borderLeftWidth: 8,
    borderLeftStyle: 'solid',
  });

  const getRowBorderColor = (record: SmartTagItr) => {
    switch (record.mobileTestStatus) {
      case 'REJECTED':
        return RED;
      default:
        return 'transparent';
    }
  };

  return (
    <ListWithTitle
      resource={RESOURCE_SMART_TAG_ITR}
      filters={<FreeTextSearchFilter />}
      filterDefaultValues={defaultFilter}
      actions={
        <ListActions
          sideFilter={sideFilter}
          defaultFilter={defaultFilter}
          showAction={{
            import: false,
            export: false,
            effective: false,
            create: false,
            select: true,
          }}
          actions={
            <>
              <FilterMyItemsButton defaultFilter={defaultFilter} />
              <LegendButton legends={legends} />
            </>
          }
        />
      }
      sort={{ field: 'id', order: 'ASC' }}
      aside={
        <Fragment>
          {showSideFilter && (
            <SmartTagITRSidebarFilters defaultFilter={defaultFilter} />
          )}
          {selectedRecordId && (
            <SmartTagITREdit
              syncWithLocation={false}
              id={selectedRecordId}
              resource={RESOURCE_SMART_TAG_ITR}
              onCancel={onCancel}
            />
          )}
        </Fragment>
      }
    >
      <DatagridConfigurableRbac
        bulkActionButtons={<BulkActionButtons onUpdate={onCancel} />}
        defaultVisibleColumns={[
          'id',
          'componentId',
          'testId',
          'assignedEmail',
          'mobileTestStatus',
          'pendingPunchCounts.mobilePunch',
        ]}
        rowClick={rowClick}
        rowStyle={getRowStyle}
        hover
      >
        <TextField source="id" label="ID" />
        <ComponentField source="componentId" label="Tag" />
        <TextField
          source="component.tagDescription"
          label="Description"
          sortBy="component.tag_description"
        />
        <TestField
          source="testId"
          textSource="test.testFormNo"
          sortBy="test.test_form_no"
          label="ITR"
        />
        <TextField
          source="test.testName"
          label="ITR Name"
          sortBy="test.test_name"
        />
        <AssignedField label="Responsible" sortable={false} />
        <VendorField
          source="responsibleVendorId"
          textSource="responsibleVendor.vendor"
          sortBy="`responsibleVendor.vendor`"
        />
        <MobileTestStatusField source="mobileTestStatus" label="Status" />
        <TextField
          source="pendingPunchCounts.mobilePunch"
          label="Mobile Punch"
          sortBy="`pendingPunchCounts.mobilePunch`"
        />
        <SubsystemField
          source="component.subsystemId"
          textSource="component.subsystem.subsystem"
          sortBy="`component.subsystem.subsystem`"
        />
        <TextField
          source="component.subsystem.description"
          label="SubSystem Description"
          sortBy="`component.subsystem.description`"
        />
        <LocationField />
        <WorkPackField
          source="component.workPackId"
          textSource="component.workPack.workPack"
          sortBy="`component.workPack.workPack`"
        />
        <SystemField
          source="component.subsystem.systemId"
          textSource="component.subsystem.system.systemName"
          sortBy="`component.subsystem.system.systemName`"
          label="System"
        />
        <TextField
          source="component.subsystem.system.description"
          label="System Description"
          sortBy="`component.subsystem.system.description`"
        />
        {renderMetaDataFields()}
        <ColumnActions
          label="Actions"
          showActions={{ edit: false, delete: false, show: false }}
        >
          <Button>Show/Hide</Button>
        </ColumnActions>
      </DatagridConfigurableRbac>
    </ListWithTitle>
  );
};

export default SmartTagITRList;
