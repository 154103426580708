import React, { Fragment, ReactElement, useCallback, useState } from 'react';
import {
  ResourceContextProvider,
  useNotify,
  SimpleForm,
  useGetList,
  useUpdateMany,
  useListContext,
  useUnselectAll,
  Button,
  maxLength,
} from 'react-admin';
import { useQueryClient } from '@tanstack/react-query';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Box,
} from '@mui/material';
import useCurrentProjectSettings from '../../common/hooks/useCurrentProjectSettings';
import {
  useDictionaryLabelsWithResource,
  getErrorMessage,
} from '../../../utils/UtilityFunctions';
import { CompletedType } from '../../preservationItemActivity/constants';
import { ContactCategories } from '../../common/contactInput/constants';
import ContactInput from '../../common/contactInput/ContactInput';
import CustomDateInput from '../../common/CustomDateInput';
import SaveOnlyToolbar from '../../common/SaveOnlyToolbar';
import commonStyles from '../../common/commonStyles';
import { getValidateRequiredFunc } from '../../preservationItemActivity/validators';
import { YesNoEnum } from '../../common/types';

interface BulkEditButtonProps {
  resource: string;
  onUpdate?: () => void;
}

const BulkCloseOutButton: React.FC<BulkEditButtonProps> = ({
  resource,
  onUpdate,
}): ReactElement => {
  const notify = useNotify();
  const { labels } = useDictionaryLabelsWithResource();
  const queryClient = useQueryClient();
  const [updateMany] = useUpdateMany();
  const { selectedIds } = useListContext();
  const unselectAll = useUnselectAll(resource);
  const [idsForUpdate, setIdsForUpdate] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const { presAutomation } = useCurrentProjectSettings();
  const { data: records = [] } = useGetList(resource, {
    filter: { id: selectedIds },
  });

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const onError = useCallback(
    (error) => {
      notify(getErrorMessage(error), {
        type: 'error',
        undoable: false,
      });
      setIsOpen(false);
    },
    [setIsOpen, notify]
  );

  const closeOut = useCallback(
    async () =>
      await updateMany(
        resource,
        { ids: selectedIds, data: { isClosed: true } },
        {
          onSuccess: async () => {
            queryClient.removeQueries({ queryKey: [resource], exact: false });
            notify(`${selectedIds.length} elements updated`, {
              type: 'success',
            });
            unselectAll();
            setIsOpen(false);
            onUpdate();
          },
          onError,
        }
      ),
    [
      selectedIds,
      resource,
      queryClient,
      unselectAll,
      setIsOpen,
      onError,
      notify,
      onUpdate,
      updateMany,
    ]
  );

  const handleCheck = useCallback(() => {
    const ids = [];
    for (const record of records) {
      if (record?.['completed'] === CompletedType.ISSUED) {
        notify("Preservations with Status Issued can't be Close Out", {
          type: 'error',
        });
        return;
      }

      if (
        presAutomation === YesNoEnum.YES &&
        !!record?.['storagePreservation'] &&
        !record?.['verifiedBy']
      ) {
        ids.push(record?.['id']);
      }
    }

    if (ids.length > 0) {
      setIsOpen(true);
      setIdsForUpdate(ids);
    } else if (selectedIds.length > 0) {
      closeOut();
    }
  }, [
    records,
    presAutomation,
    selectedIds,
    setIsOpen,
    setIdsForUpdate,
    closeOut,
    notify,
  ]);

  const handleSubmit = useCallback(
    async (data) => {
      await updateMany(
        resource,
        { ids: idsForUpdate, data },
        {
          onSuccess: async () => {
            await closeOut();
          },
          onError,
        }
      );
    },
    [idsForUpdate, closeOut, updateMany, onError, resource]
  );

  const formProps = {
    id: 'bulk_close_out_form',
    onSubmit: handleSubmit,
    toolbar: <SaveOnlyToolbar />,
  };

  return (
    <ResourceContextProvider value={resource}>
      <Fragment>
        <Button label="Close Out Preservation(s)" onClick={handleCheck}>
          <CheckIcon />
        </Button>

        <Dialog fullWidth maxWidth="sm" open={isOpen} onClose={handleClose}>
          <DialogTitle>Close Out Process</DialogTitle>
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>

          <DialogContent>
            <SimpleForm {...formProps}>
              <Box sx={commonStyles.flexBox}>
                <ContactInput
                  source="verifiedBy"
                  label={labels['verifiedBy'] || 'Verified By'}
                  sx={commonStyles.flexBoxItem}
                  validate={[
                    maxLength(75),
                    getValidateRequiredFunc('verifiedDate', 'Verified Date'),
                  ]}
                  category={ContactCategories.preservationEmpWorkGrp}
                />
                <CustomDateInput
                  source="verifiedDate"
                  label={labels['verifiedDate'] || 'Verified Date'}
                  sx={commonStyles.flexBoxItem}
                  validate={[
                    getValidateRequiredFunc('verifiedBy', 'Verified By'),
                  ]}
                />
              </Box>
            </SimpleForm>
          </DialogContent>
        </Dialog>
      </Fragment>
    </ResourceContextProvider>
  );
};

export default BulkCloseOutButton;
