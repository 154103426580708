import React, { ReactElement, useCallback } from 'react';
import { RESOURCE_COMPONENT_DRAWING } from '../constants';
import ActivitiesTab from '../common/ActivitiesTab';
import HistoryTab from '../common/HistoryTab';
import LazyFormTab from '../common/LazyFormTab';
import TabbedEditForm from '../common/TabbedEditForm';
import MetaDataInputs from '../common/MetaDataInputs';
import commonStyles from '../common/commonStyles';
import { EditPropsWithLocation } from '../common/types';
import CommentFormTab from '../common/CommentFormTab';
import AttachmentFormTab from '../common/AttachmentFormTab';
import { activityCategory, commentType } from '../common/constants';
import ComponentForm from './components/ComponentForm';
import ElectricalFormTab from './components/ElectricalFormTab';
import PipingFormTab from './components/PipingFormTab';
import MechanicalFormTab from './components/MechanicalFormTab';
import InstrumentationFormTab from './components/InstrumentationFormTab';
import DrawingFormTab from '../common/DrawingFormTab';
import TestITRsTab from '../common/TagITRsTab';
import TestAttachmentTab from './components/TestAttachmentTab';
import PreservationAttachmentTab from './components/PreservationAttachmentTab';
import PunchFormShow from '../common/PunchFormShow';
import MaterialComponentFormTab from '../common/MaterialComponentFormTab';

const ComponentEdit: React.FC<EditPropsWithLocation> = (
  props
): ReactElement => {
  const commentTabTransformFunc = useCallback(
    (data, recordId) => ({
      ...data,
      componentId: recordId,
      commentType: commentType.component,
    }),
    []
  );

  return (
    <TabbedEditForm {...props}>
      <LazyFormTab
        label="Component"
        id="component_tab"
        sx={commonStyles.formTabHeader}
      >
        <ComponentForm />
        <MetaDataInputs />
      </LazyFormTab>
      <ElectricalFormTab />
      <PipingFormTab />
      <MechanicalFormTab />
      <InstrumentationFormTab />
      <LazyFormTab
        label="Comment"
        id="comment_tab"
        sx={commonStyles.formTabHeader}
      >
        <CommentFormTab
          targetId="component_id"
          commentType={commentType.component}
          transformFunc={commentTabTransformFunc}
        />
      </LazyFormTab>
      <LazyFormTab
        label="Drawings"
        id="drawing_tab"
        sx={commonStyles.formTabHeader}
      >
        <DrawingFormTab
          target="componentId"
          reference={RESOURCE_COMPONENT_DRAWING}
        />
      </LazyFormTab>
      <LazyFormTab
        label="Attachment"
        id="attachment_tab"
        sx={commonStyles.formTabHeader}
      >
        <AttachmentFormTab targetId="componentId" />
      </LazyFormTab>
      <LazyFormTab
        label="Tag-Test/ITR Attachment"
        id="test_attachment_tab"
        sx={commonStyles.formTabHeader}
      >
        <TestAttachmentTab />
      </LazyFormTab>
      <LazyFormTab
        label="Preservation Attachment"
        id="preservation_attachment_tab"
        sx={commonStyles.formTabHeader}
      >
        <PreservationAttachmentTab />
      </LazyFormTab>
      <LazyFormTab
        label="Activities"
        id="activity_tab"
        sx={commonStyles.formTabHeader}
      >
        <ActivitiesTab category={activityCategory.component} />
      </LazyFormTab>
      <LazyFormTab
        label="Tests / ITRs"
        id="tag-itrs_tab"
        sx={commonStyles.formTabHeader}
      >
        <TestITRsTab />
      </LazyFormTab>
      <LazyFormTab
        label="Punch Items"
        id="punch_items_tab"
        sx={commonStyles.formTabHeader}
      >
        <PunchFormShow target="componentId" />
      </LazyFormTab>
      <LazyFormTab
        label="Materials"
        id="materials_tab"
        sx={commonStyles.formTabHeader}
      >
        <MaterialComponentFormTab />
      </LazyFormTab>

      <HistoryTab tableName="tbl_component" />
    </TabbedEditForm>
  );
};

export default ComponentEdit;
