import { activityCategory } from '../constants';
import { ActivityCategory } from '../types';
import useCurrentProjectSettings from './useCurrentProjectSettings';

const useEnforceCertGatingSetting = (category: ActivityCategory) => {
  const currentProjectSettings = useCurrentProjectSettings();

  switch (category) {
    case activityCategory.system:
      return currentProjectSettings.enforceCertGatingSys;
    case activityCategory.subSystem:
      return currentProjectSettings.enforceCertGatingSs;
    case activityCategory.component:
    default:
      return currentProjectSettings.enforceCertGatingComponent;
  }
};

export default useEnforceCertGatingSetting;
