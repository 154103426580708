import { RED, YELLOW } from '../../theme/colors';
import { CertificateDetails } from './types';

export const checkDeleteIsAllowed = (
  certDetails: CertificateDetails
): boolean =>
  !certDetails.generatedBy &&
  !certDetails.generatedDate &&
  !certDetails.acceptedBy &&
  !certDetails.acceptedDate &&
  !certDetails.isVerified &&
  !certDetails.certSignedBy;

export const legends = [
  {
    label: 'Status NONE',
    color: RED,
  },
  {
    label: 'Status IN PROGRESS',
    color: YELLOW,
  },
];

export const getRowStyle = (record, expanded = []) => {
  return {
    backgroundColor: expanded.includes(record.id)
      ? 'rgba(0, 0, 0, 0.04)'
      : 'initial',
    borderLeftColor: getRowBorderColor(record),
    borderLeftWidth: 8,
    borderLeftStyle: 'solid',
  };
};

export const getRowBorderColor = (record) => {
  switch (record?.status) {
    case 'NONE':
      return RED;
    case 'IN_PROGRESS':
      return YELLOW;
    default:
      return 'transparent';
  }
};
