import { TextField } from 'react-admin';
import React, { ReactElement } from 'react';
import FreeTextSearchFilter from '../common/FreeTextSearchFilter';
import DatagridConfigurableRbac from '../common/DatagridConfigurableRbac';
import ColumnActions from '../common/ColumnActions';
import useDefaultFilter from '../common/hooks/useDefaultFilter';
import ListWithTitle from '../common/ListWithTitle';
import BookEdit from './BookEdit';
import BookShow from './BookShow';
import renderMetaDataFields from '../common/helpers/renderMetaDataFields';
import ListActions from '../common/ListActions';
import { BookSectionsLink } from './BookSectionsLink';

const BookList: React.FC = (): ReactElement => {
  const defaultFilter = useDefaultFilter('project');

  return (
    <ListWithTitle
      filters={<FreeTextSearchFilter />}
      filterDefaultValues={defaultFilter}
      actions={<ListActions />}
      sort={{ field: 'title', order: 'ASC' }}
    >
      <DatagridConfigurableRbac
        ShowComponent={<BookShow withoutActions />}
        EditComponent={<BookEdit syncWithLocation={false} />}
        defaultVisibleColumns={['id', 'title', 'description']}
      >
        <TextField source="id" label="ID" />
        <TextField source="title" label="Title" />
        <TextField source="description" label="Description" />
        {renderMetaDataFields()}
        <ColumnActions label="Actions">
          <BookSectionsLink />
        </ColumnActions>
      </DatagridConfigurableRbac>
    </ListWithTitle>
  );
};

export default BookList;
