import React, { ReactElement, useState, useCallback, Fragment } from 'react';
import { ListProps, useStore, useResourceContext } from 'react-admin';
import SmartPreservationItemActivitySidebarFilters from './components/SmartPreservationItemActivitySidebarFilters';
import SmartPreservationItemActivityListDatagrid from './components/SmartPreservationItemActivityListDatagrid';
import SmartPreservationItemActivityEdit from './SmartPreservationItemActivityEdit';
import { useDictionaryLabelsWithResource } from '../../utils/UtilityFunctions';
import { RESOURCE_SMART_PRESERVATION_ITEM_ACTIVITY } from '../constants';
import FreeTextSearchFilter from '../common/FreeTextSearchFilter';
import FilterMyItemsButton from '../common/FilterMyItemsButton';
import { getSideFiltersKey } from '../common/helpers/storeKeys';
import useDefaultFilter from '../common/hooks/useDefaultFilter';
import { legends } from './components/helpers';
import ListWithTitle from '../common/ListWithTitle';
import LegendButton from '../common/LegendButton';
import ListActions from '../common/ListActions';
import useCheckCurrentProjectResourceAccessSettings from '../common/hooks/useCheckCurrentProjectResourceAccessSettings';

const PreservationItemActivityList: React.FC<ListProps> = (): ReactElement => {
  useCheckCurrentProjectResourceAccessSettings();

  const { preferenceKey } = useDictionaryLabelsWithResource();
  const resource: string = useResourceContext();
  const sideFilter = getSideFiltersKey(resource);
  const [showSideFilter] = useStore(sideFilter, false);
  const [selectedRecordId, setSelectedRecordId] = useState<number | null>(null);
  const [, setExpanded] = useStore(`${resource}.datagrid.expanded`, []);
  const defaultFilter = useDefaultFilter('project', {
    isDeleted: false,
    isMobile: true,
    isClosed: false,
  });

  const onCancel = useCallback(() => setExpanded([]), [setExpanded]);

  return (
    <ListWithTitle
      resource={RESOURCE_SMART_PRESERVATION_ITEM_ACTIVITY}
      filters={<FreeTextSearchFilter />}
      filterDefaultValues={defaultFilter}
      actions={
        <ListActions
          sideFilter={sideFilter}
          preferenceKey={preferenceKey}
          defaultFilter={defaultFilter}
          showAction={{
            import: false,
            export: true,
            effective: false,
            create: false,
            select: true,
          }}
          actions={
            <Fragment>
              <FilterMyItemsButton
                defaultFilter={defaultFilter}
                label="My Preservations"
              />
              <LegendButton legends={legends} />
            </Fragment>
          }
        />
      }
      sort={{ field: 'id', order: 'ASC' }}
      aside={
        <Fragment>
          {showSideFilter && (
            <SmartPreservationItemActivitySidebarFilters
              defaultFilter={defaultFilter}
            />
          )}
          {selectedRecordId && (
            <SmartPreservationItemActivityEdit
              syncWithLocation={false}
              id={selectedRecordId}
              resource={RESOURCE_SMART_PRESERVATION_ITEM_ACTIVITY}
              onCancel={onCancel}
            />
          )}
        </Fragment>
      }
    >
      <SmartPreservationItemActivityListDatagrid
        setSelectedRecordId={setSelectedRecordId}
      />
    </ListWithTitle>
  );
};

export default PreservationItemActivityList;
