import pickBy from 'lodash/pickBy';

const getUISettings = () => {
  return pickBy(localStorage, (value, key) => {
    return (
      key.endsWith('version') ||
      key.endsWith('autoSaveEnabled') ||
      key.endsWith('datagrid.availableColumns') ||
      key.endsWith('datagrid.columns') ||
      key.endsWith('dict.availableColumns') ||
      key.endsWith('dict.columns')
    );
  });
};

export default getUISettings;
