import React, {
  ChangeEvent,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useRecordContext } from 'react-admin';
import { AutocompleteInputChangeReason } from '@mui/base/useAutocomplete/useAutocomplete';
import { Autocomplete, TextField } from '@mui/material';
import {
  filterOptions,
  getContactItem,
  getDialogTitle,
  getInitialValue,
} from '../../preservationItemActivity/helpers';
import useInputs from '../hooks/useInputs';
import useDebounce from '../hooks/useDebounce';
import useOptionList from './hooks/useOptionList';
import ContactCreateDialog from '../ContactCreateDialog';
import { AssignedInputProps } from './types';
import { GroupHeader, GroupItems } from './styles';

const AssignedWithCreateInput: React.FC<AssignedInputProps> = ({
  label,
  category,
  sx,
  disabled,
}): ReactElement => {
  const [filters, setFilters] = useState({ q: '' });
  const record = useRecordContext();
  const options = useOptionList(category, filters);
  const contacts = useMemo(
    () =>
      options
        .filter((option) => option.group === 'Contacts')
        .map((item) => ({ name: item.option.title })),
    [options]
  );

  const {
    contactId,
    // assignedGroupId,
    assignedUserGroupId,
    assignedEmail,
  } = useInputs();
  const [currentValue, setCurrentValue] = useState(null);
  const [open, toggleOpen] = useState(false);
  const [dialogValue, setDialogValue] = useState('');

  useEffect(() => {
    if (record) {
      setCurrentValue(getInitialValue(record));
    }
  }, [record]);

  const onChange = useCallback(
    (value) => {
      const assignedType = value?.option.assignedType ?? null;

      const newValue = value?.option.value;
      let newEmail = null;
      // let newGroup = null;
      let newUserGroup = null;
      let newContact = null;

      switch (assignedType) {
        case 'assignedEmail':
          newEmail = newValue;
          break;
        // case 'assignedGroupId':
        //   newGroup = newValue;
        //   break;
        case 'assignedUserGroupId':
          newUserGroup = newValue;
          break;
        case 'assignedContactId':
          newContact = newValue;
          break;
      }

      assignedEmail.onChange(newEmail);
      // assignedGroupId.onChange(newGroup);
      assignedUserGroupId.onChange(newUserGroup);
      contactId.onChange(newContact);
      setCurrentValue(value);
    },
    [
      assignedEmail,
      // assignedGroupId,
      assignedUserGroupId,
      contactId,
    ]
  );

  const handleChange = useCallback(
    (_, newValue) => {
      if (newValue && newValue.isNew && newValue.option.value) {
        newValue.option.title = getDialogTitle(newValue.option.title);

        toggleOpen(true);
        setCurrentValue(newValue);
        setDialogValue(newValue.option.title);
      } else {
        onChange(newValue);
      }
    },
    [onChange]
  );

  const onCreate = useCallback(
    (newContact) => {
      const contactItem = getContactItem(newContact);

      handleChange(null, contactItem);
      toggleOpen(false);
    },
    [handleChange]
  );

  const onCancel = useCallback(() => {
    toggleOpen(false);
    setCurrentValue(getInitialValue(record));
    setDialogValue('');
  }, [record]);

  const debounce = useDebounce();
  const handleDebouncedInputChange = (
    event: ChangeEvent<HTMLInputElement>,
    value: string,
    reason: AutocompleteInputChangeReason
  ) => {
    debounce(() => {
      if (event && reason === 'input') {
        setFilters({ q: event?.target?.value });
      }
    });
  };

  return (
    <>
      <Autocomplete
        disabled={disabled}
        id="assignedInput"
        options={options}
        groupBy={(item) => item.group}
        getOptionLabel={(item) => item.option.title}
        onInputChange={handleDebouncedInputChange}
        onChange={handleChange}
        filterOptions={filterOptions(true)}
        value={currentValue}
        sx={sx}
        selectOnFocus
        clearOnBlur
        isOptionEqualToValue={(option, value) => option.id === value?.id}
        renderInput={(params) => <TextField {...params} label={label} />}
        renderGroup={(params) => (
          <li key={params.key}>
            <GroupHeader>{params.group}</GroupHeader>
            <GroupItems>{params.children}</GroupItems>
          </li>
        )}
        freeSolo
      />
      <ContactCreateDialog
        listOfContacts={contacts}
        category={category}
        value={dialogValue}
        changeValue={setDialogValue}
        onCreate={onCreate}
        onCancel={onCancel}
        open={open}
      />
    </>
  );
};

AssignedWithCreateInput.defaultProps = {
  label: 'Assigned',
  disabled: false,
};

export default AssignedWithCreateInput;
