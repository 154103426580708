import React, { ReactElement } from 'react';
import { maxLength } from 'react-admin';
import Box from '@mui/material/Box';
import HistoryTab from '../common/HistoryTab';
import LazyFormTab from '../common/LazyFormTab';
import MetaDataInputs from '../common/MetaDataInputs';
import TabbedEditForm from '../common/TabbedEditForm';
import commonStyles from '../common/commonStyles';
import { PunchTemplate } from './types';
import { EditPropsWithLocation } from '../common/types';
import TextInput from '../common/TextInput';
import PunchCategoryInput from '../common/PunchCategoryInput';
import AttachmentRevisionFormTab from '../attachmentRevision/AttachmentRevisionFormTab';
import {
  required,
  useDictionaryLabelsWithResource,
} from '../../utils/UtilityFunctions';

const PunchTemplateEdit: React.FC<EditPropsWithLocation<PunchTemplate>> = (
  props
): ReactElement => {
  const { labels } = useDictionaryLabelsWithResource();

  return (
    <TabbedEditForm {...props}>
      <LazyFormTab
        label="Punch Template"
        id="punch_template_tab"
        sx={commonStyles.formTabHeader}
      >
        <Box sx={commonStyles.flexBox}>
          <TextInput
            source="templateName"
            label="Template Name"
            name="templateName"
            validate={[required(), maxLength(100)]}
            sx={commonStyles.flexBoxLongItem}
          />
        </Box>
        <Box sx={commonStyles.flexBox}>
          <TextInput
            source="description"
            label="Description"
            name="description"
            validate={[maxLength(100)]}
            sx={commonStyles.flexBoxLongItem}
          />
        </Box>
        <Box sx={commonStyles.flexBox}>
          <PunchCategoryInput
            source="punchCategoryId"
            label={labels['punchCatId']}
            sx={commonStyles.flexBoxLongItem}
          />
        </Box>
        <MetaDataInputs />
      </LazyFormTab>
      <LazyFormTab
        label="Revisions"
        id="attachment_revision_tab"
        sx={commonStyles.formTabHeader}
      >
        <AttachmentRevisionFormTab
          targetId="punchTemplateId"
          withoutProjectId
        />
      </LazyFormTab>
      <HistoryTab tableName="tbl_punch_template" />
    </TabbedEditForm>
  );
};

export default PunchTemplateEdit;
