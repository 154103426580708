import React, { ReactElement } from 'react';
import { maxLength } from 'react-admin';
import Box from '@mui/material/Box';
import HistoryTab from '../common/HistoryTab';
import LazyFormTab from '../common/LazyFormTab';
import MetaDataInputs from '../common/MetaDataInputs';
import TabbedEditForm from '../common/TabbedEditForm';
import ProjectInput from '../common/ProjectInput';
import TextInput from '../common/TextInput';
import commonStyles from '../common/commonStyles';
import { EditPropsWithLocation } from '../common/types';
import { UserGroup } from './types';
import GroupUsersTab from './components/GroupUsersTab';
import { required } from '../../utils/UtilityFunctions';

const UserGroupEdit: React.FC<EditPropsWithLocation<UserGroup>> = (
  props
): ReactElement => (
  <TabbedEditForm {...props}>
    <LazyFormTab
      label="User Group"
      id="user_group_tab"
      sx={commonStyles.formTabHeader}
    >
      <Box sx={commonStyles.flexBox}>
        <TextInput
          source="userGroup"
          label="User Group"
          validate={[required(), maxLength(75)]}
          sx={commonStyles.flexBoxItem}
        />
        <TextInput
          source="description"
          label="Description"
          validate={maxLength(255)}
          sx={commonStyles.flexBoxItem}
          fullWidth
        />
        <ProjectInput sx={commonStyles.flexBoxItem} isRequired />
      </Box>
      <MetaDataInputs />
    </LazyFormTab>
    <LazyFormTab label="Users" id="users_tab" sx={commonStyles.formTabHeader}>
      <GroupUsersTab />
    </LazyFormTab>
    <HistoryTab tableName="tbl_user_group" />
  </TabbedEditForm>
);

export default UserGroupEdit;
