import { useResourceContext } from 'react-admin';
import {
  RESOURCE_ACTIVITY_PHASE,
  RESOURCE_ACTIVITY_TYPE,
  RESOURCE_ATTACHMENT,
  RESOURCE_AUDIT,
  RESOURCE_AUDIT_TABLE_NAME,
  RESOURCE_BOOK,
  RESOURCE_BOOK_SECTION,
  RESOURCE_COMMENT,
  RESOURCE_COMPONENT,
  RESOURCE_COMPONENT_ACTIVITY,
  RESOURCE_CONTACT,
  RESOURCE_DICTIONARY,
  RESOURCE_DISCIPLINE,
  RESOURCE_DISCIPLINE_TEST,
  RESOURCE_DIVISION,
  RESOURCE_DRAWING_DISCIPLINE,
  RESOURCE_DRAWING_TYPE,
  RESOURCE_EQUIPMENT_TYPE,
  RESOURCE_GROUP,
  RESOURCE_IMPORT,
  RESOURCE_LOCATION,
  RESOURCE_MODULE,
  RESOURCE_POSITION,
  RESOURCE_PRESERVATION_ITEM,
  RESOURCE_PROJECT,
  RESOURCE_PUNCH,
  RESOURCE_PUNCH_CATEGORY,
  RESOURCE_PUNCH_PRIORITY,
  RESOURCE_SECURITY_GROUP,
  RESOURCE_SECURITY_ROLE,
  RESOURCE_SECURITY_ROLE_PERMISSION,
  RESOURCE_STRUCTURE_ASSET,
  RESOURCE_STRUCTURE_ASSET_GROUP,
  RESOURCE_STRUCTURE_REGION,
  RESOURCE_SUBSYSTEM,
  RESOURCE_SUBSYSTEM_ACTIVITY,
  RESOURCE_SYSTEM,
  RESOURCE_SYSTEM_ACTIVITY,
  RESOURCE_TEST_TYPE,
  RESOURCE_USER,
  RESOURCE_USER_GROUP,
  RESOURCE_VENDOR,
  RESOURCE_WORK_PACK,
  RESOURCE_TEST,
  RESOURCE_COMPONENT_TEST,
  RESOURCE_MOC,
  RESOURCE_MOC_TYPE,
  RESOURCE_DRAWING,
  RESOURCE_PRESERVATION_ITEM_ACTIVITY,
  RESOURCE_PRESERVATION_SERIES,
  RESOURCE_EQUIPMENT_TYPE_TEST,
  RESOURCE_CERT_TEMPLATE,
  RESOURCE_CERT_GATING,
  RESOURCE_DRAWING_PREFIX,
  CUSTOM_ROUTE_BOOK_GENERATION,
  RESOURCE_CERT_DETAIL,
  RESOURCE_SMART_TAG_ITR,
  RESOURCE_SMART_TAG_ITR_REVIEW,
  RESOURCE_PENDING_PUNCH,
  RESOURCE_QR_SCANNER,
  RESOURCE_CONFIGURATION,
  RESOURCE_PRINTED_BOOK,
  RESOURCE_REPORT,
  RESOURCE_SUBSYSTEM_DRAWING,
  RESOURCE_GROUP_CONTACT,
  RESOURCE_COMPONENT_DRAWING,
  RESOURCE_TEST_POSITION,
  RESOURCE_CERT_GATING_TEST,
  RESOURCE_CERT_GATING_PUNCH,
  RESOURCE_CERT_GATING_PRED_CERT,
  RESOURCE_DRAWING_SUBSYSTEM,
  RESOURCE_DRAWING_COMPONENT,
  RESOURCE_ATTACHMENT_TYPE,
  RESOURCE_PUNCH_TEMPLATE,
  RESOURCE_SMART_CERT_DETAIL,
  RESOURCE_SUBMIT_PUNCH,
  RESOURCE_EQUIPMENT_TYPE_WITH_TEST,
  RESOURCE_LUCID_DIAGRAM,
  RESOURCE_SMART_PRESERVATION_ITEM_ACTIVITY,
  RESOURCE_MATERIAL_TYPE,
  RESOURCE_STORAGE,
  RESOURCE_MATERIAL,
  RESOURCE_MATERIAL_RELEASED,
  RESOURCE_MATERIAL_COMPONENT,
  RESOURCE_MATERIAL_PURCHASE_ORDER,
  RESOURCE_MATERIAL_RELEASED_MATERIAL_STORAGE,
  RESOURCE_MATERIAL_PURCHASE_ORDER_MATERIAL,
  RESOURCE_MATERIAL_STORAGE,
} from '../../constants';

export const titleFromResource = {
  [RESOURCE_ATTACHMENT]: ['Attachment', 'Attachments'],
  [RESOURCE_ACTIVITY_TYPE]: ['Activity Type', 'Activity Types'],
  [RESOURCE_PROJECT]: ['Project', 'Projects'],
  [RESOURCE_MODULE]: ['Module', 'Modules'],
  [RESOURCE_LOCATION]: ['Location', 'Locations'],
  [RESOURCE_DISCIPLINE]: ['Discipline', 'Disciplines'],
  [RESOURCE_DICTIONARY]: ['Dictionary', 'Dictionaries'],
  [RESOURCE_SYSTEM]: ['System', 'Systems'],
  [RESOURCE_SYSTEM_ACTIVITY]: ['System Activity', 'System Activities'],
  [RESOURCE_COMMENT]: ['Comment', 'Comments'],
  [RESOURCE_WORK_PACK]: ['Work Pack', 'Work Packs'],
  [RESOURCE_PUNCH_CATEGORY]: ['Punch Category', 'Punch Categories'],
  [RESOURCE_PUNCH_PRIORITY]: ['Punch Priority', 'Punch Priorities'],
  [RESOURCE_DIVISION]: ['Division', 'Divisions'],
  [RESOURCE_STRUCTURE_ASSET]: ['Asset', 'Assets'],
  [RESOURCE_GROUP]: ['Group', 'Groups'],
  [RESOURCE_STRUCTURE_REGION]: ['Region', 'Regions'],
  [RESOURCE_SUBSYSTEM]: ['SubSystem', 'SubSystems'],
  [RESOURCE_SUBSYSTEM_ACTIVITY]: ['SubSystem Activity', 'SubSystem Activities'],
  [RESOURCE_AUDIT]: ['Audit'],
  [RESOURCE_AUDIT_TABLE_NAME]: ['Audit Table Name', 'Audit Table Names'],
  [RESOURCE_STRUCTURE_ASSET_GROUP]: ['Asset Group', 'Asset Groups'],
  [RESOURCE_TEST_TYPE]: ['Test Type', 'Test Types'],
  [RESOURCE_DRAWING_TYPE]: ['Drawing Type', 'Drawing Types'],
  [RESOURCE_SECURITY_ROLE]: ['Role', 'Roles'],
  [RESOURCE_SECURITY_GROUP]: ['Group', 'Groups'],
  [RESOURCE_SECURITY_ROLE_PERMISSION]: ['Role Permission', 'Role Permissions'],
  [RESOURCE_POSITION]: ['Position', 'Positions'],
  [RESOURCE_DRAWING_DISCIPLINE]: ['Drawing Discipline', 'Drawing Disciplines'],
  [RESOURCE_ACTIVITY_PHASE]: ['Activity Phase', 'Activity Phases'],
  [RESOURCE_VENDOR]: ['Vendor', 'Vendors'],
  [RESOURCE_EQUIPMENT_TYPE]: ['Equipment Type', 'Equipment Types'],
  [RESOURCE_EQUIPMENT_TYPE_TEST]: [
    'Equipment Type Test',
    'Equipment Type Tests',
  ],
  [RESOURCE_EQUIPMENT_TYPE_WITH_TEST]: [
    'Equipment Type Test',
    'Equipment Type Tests',
  ],
  [RESOURCE_USER]: ['User', 'Users'],
  [RESOURCE_DISCIPLINE_TEST]: ['Discipline Test', 'Discipline Tests'],
  [RESOURCE_USER_GROUP]: ['User Group', 'User Groups'],
  [RESOURCE_IMPORT]: ['Import'],
  [RESOURCE_COMPONENT]: ['Component', 'Components'],
  [RESOURCE_COMPONENT_ACTIVITY]: ['Component Activity', 'Component Activities'],
  [RESOURCE_PRESERVATION_ITEM]: ['Preservation Item', 'Preservation Items'],
  [RESOURCE_PRESERVATION_ITEM_ACTIVITY]: [
    'Preservation Item Activity',
    'Preservation Items Activities',
  ],
  [RESOURCE_PRESERVATION_SERIES]: [
    'Preservation Series',
    'Preservation Series',
  ],
  [RESOURCE_CONTACT]: ['Contact', 'Contacts'],
  [RESOURCE_BOOK]: ['Book', 'Books'],
  [RESOURCE_BOOK_SECTION]: ['Book Section', 'Book Sections'],
  [CUSTOM_ROUTE_BOOK_GENERATION]: ['Generate Book(s)', 'Generate Book(s)'],
  [RESOURCE_TEST]: ['ITR Template', 'ITR Templates'],
  [RESOURCE_COMPONENT_TEST]: ['Tag ITR', 'Tag ITRs'],
  [RESOURCE_PUNCH]: ['Punch', 'Punch List'],
  [RESOURCE_SUBMIT_PUNCH]: ['Submit Punch', 'Submit Punch List'],
  [RESOURCE_MOC]: ['MoC', 'MoCs'],
  [RESOURCE_MOC_TYPE]: ['MoC Type', 'MoC Types'],
  [RESOURCE_DRAWING]: ['Drawing', 'Drawings'],
  [RESOURCE_CERT_TEMPLATE]: ['Certificate Template', 'Certificate Templates'],
  [RESOURCE_CERT_GATING]: ['Certificate Gating', 'Certificates Gating'],
  [RESOURCE_DRAWING_PREFIX]: ['Drawing Prefix', 'Drawing Prefixes'],
  [RESOURCE_CERT_DETAIL]: ['Certificate Detail', 'Certificate Details'],
  [RESOURCE_SMART_CERT_DETAIL]: ['Smart Certificate', 'Smart Certificates'],
  [RESOURCE_SMART_TAG_ITR]: ['Smart Tag ITR', 'Smart Tag ITRs'],
  [RESOURCE_SMART_TAG_ITR_REVIEW]: [
    'Smart Tag ITR Review',
    'Smart Tag ITR Reviews',
  ],
  [RESOURCE_PENDING_PUNCH]: ['Smart Punch', 'Smart Punch List'],
  [RESOURCE_QR_SCANNER]: ['QR Scan', 'QR Scan List'],
  [RESOURCE_CONFIGURATION]: ['Configuration', 'Configurations'],
  [RESOURCE_PRINTED_BOOK]: ['Printed Book', 'Printed Books'],
  [RESOURCE_REPORT]: ['Report', 'Reports'],
  [RESOURCE_SUBSYSTEM_DRAWING]: ['SubSystem Drawing', 'SubSystem Drawings'],
  [RESOURCE_DRAWING_SUBSYSTEM]: ['Drawing Subsystem', 'Drawing Subsystems'],
  [RESOURCE_GROUP_CONTACT]: ['Contact', 'Contacts'],
  [RESOURCE_COMPONENT_DRAWING]: ['Component Drawing', 'Component Drawings'],
  [RESOURCE_DRAWING_COMPONENT]: ['Drawing Component', 'Drawing Components'],
  [RESOURCE_TEST_POSITION]: ['Test Position', 'Test Positions'],
  [RESOURCE_CERT_GATING_TEST]: [
    'Certificate Test Type',
    'Certificate Test Types',
  ],
  [RESOURCE_CERT_GATING_PUNCH]: [
    'Certificate Punch Priority',
    'Certificate Punch Priorities',
  ],
  [RESOURCE_CERT_GATING_PRED_CERT]: ['Certificate', 'Certificates'],
  [RESOURCE_ATTACHMENT_TYPE]: ['Attachment Type', 'Attachment Types'],
  [RESOURCE_PUNCH_TEMPLATE]: ['Punch Template', 'Punch Templates'],
  [RESOURCE_LUCID_DIAGRAM]: ['Diagram', 'Diagrams'],
  [RESOURCE_SMART_PRESERVATION_ITEM_ACTIVITY]: [
    'Smart Preservation',
    'Smart Preservations',
  ],
  [RESOURCE_MATERIAL_TYPE]: ['Material Type', 'Material Types'],
  [RESOURCE_STORAGE]: ['Storage', 'Storages'],
  [RESOURCE_MATERIAL_STORAGE]: ['Material Storage', 'Material Storages'],
  [RESOURCE_MATERIAL]: ['Material', 'Materials'],
  [RESOURCE_MATERIAL_RELEASED]: ['Released Material', 'Released Materials'],
  [RESOURCE_MATERIAL_RELEASED_MATERIAL_STORAGE]: [
    'Released Material',
    'Released Materials',
  ],
  [RESOURCE_MATERIAL_COMPONENT]: ['Material', 'Material'],
  [RESOURCE_MATERIAL_PURCHASE_ORDER]: ['PO Request', 'PO Requests'],
  [RESOURCE_MATERIAL_PURCHASE_ORDER_MATERIAL]: ['PO Request', 'PO Requests'],
};

const useResourceTitle = (plural = false) => {
  const resource = useResourceContext();
  return (
    titleFromResource?.[resource]?.[Number(plural)] ||
    titleFromResource?.[resource]?.[0] ||
    'ADD TITLE'
  );
};

export default useResourceTitle;
