import React, { ReactElement } from 'react';
import { Labeled, SimpleShowLayout, TextField } from 'react-admin';
import { ShowInDialogButton } from '@react-admin/ra-form-layout';
import commonStyles from '../../common/commonStyles';
import Box from '@mui/material/Box';
import renderShowMetaDataFields from '../../common/helpers/renderShowMetaDataFields';
import DownloadButton from '../../common/DownloadButton';
import { ReactComponent as ShowIcon } from '../../../assets/icons/functionalIcons/Eye.svg';
import AttachmentTypeField from '../../common/AttachmentTypeField';

interface AttachmentShowButtonProps {
  isImage?: boolean;
}
const AttachmentShowButton: React.FC<AttachmentShowButtonProps> = ({
  isImage,
}): ReactElement => (
  <ShowInDialogButton
    inline
    fullWidth
    maxWidth="md"
    onClick={(event) => {
      event?.stopPropagation();
    }}
    icon={
      <div className="showButton" data-test="attachmentShowButton">
        <ShowIcon fill={isImage ? 'white' : 'black'} />
      </div>
    }
  >
    <SimpleShowLayout sx={commonStyles.bottomMargin}>
      <Box>
        <Labeled label="File name" sx={commonStyles.flexBoxItem} fullWidth>
          <TextField source="fileName" />
        </Labeled>
        <Box sx={commonStyles.flexBox}>
          <Labeled label="Description" sx={commonStyles.flexBoxItem} fullWidth>
            <TextField source="description" />
          </Labeled>
          <Labeled label="Type" sx={commonStyles.flexBoxItem} fullWidth>
            <AttachmentTypeField source="subtypeId" />
          </Labeled>
        </Box>
      </Box>
      <Box>
        {renderShowMetaDataFields()}
        <DownloadButton />
      </Box>
    </SimpleShowLayout>
  </ShowInDialogButton>
);

export default AttachmentShowButton;
