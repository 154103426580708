import { ReferenceManyField, TextField, useRecordContext } from 'react-admin';
import {
  EditableDatagrid,
  EditRowButton,
} from '@react-admin/ra-editable-datagrid';
import React, { ReactElement } from 'react';
import SubsystemRowForm from './SubsystemRowForm';
import SubsystemField from '../../../common/SubsystemField';
import commonStyles from '../../../common/commonStyles';
import {
  RESOURCE_SUBSYSTEM_DRAWING,
  RESOURCE_DRAWING_SUBSYSTEM,
} from '../../../constants';
import DeleteWithConfirmIconButton from '../../../common/DeleteWithConfirmIconButton';

const SubsystemFormTab: React.FC = (): ReactElement => {
  const record = useRecordContext();

  return (
    <ReferenceManyField
      target="drawing_id"
      reference={RESOURCE_SUBSYSTEM_DRAWING}
    >
      <EditableDatagrid
        bulkActionButtons={false}
        mutationMode="pessimistic"
        createForm={<SubsystemRowForm recordId={record.id} />}
        editForm={<SubsystemRowForm recordId={record.id} />}
        sx={commonStyles.formTabDatagrid}
        actions={
          <>
            <EditRowButton />
            <DeleteWithConfirmIconButton
              resourceAlias={RESOURCE_DRAWING_SUBSYSTEM}
            />
          </>
        }
      >
        <SubsystemField sx={commonStyles.formTabInput} />
        <TextField
          label="Description"
          source="subsystem.description"
          sx={commonStyles.flexBoxLongItem}
        />
      </EditableDatagrid>
    </ReferenceManyField>
  );
};

export default SubsystemFormTab;
