import React, { ReactElement } from 'react';
import { GetListParams } from 'react-admin';
import { SxProps, Theme } from '@mui/material/styles';
import { required } from '../../utils/UtilityFunctions';
import { RESOURCE_PROJECT } from '../constants';
import AutocompleteReferenceInput from './AutocompleteReferenceInput';

interface ProjectInputProps {
  label?: string;
  source?: string;
  name?: string;
  optionText?: string;
  isRequired?: boolean;
  alwaysOn?: boolean;
  multiple?: boolean;
  defaultValue?: number;
  sx?: SxProps<Theme>;
  requestParams?: Partial<GetListParams>;
}

const ProjectInput: React.FC<ProjectInputProps> = ({
  isRequired,
  ...rest
}): ReactElement => (
  <AutocompleteReferenceInput
    {...rest}
    resource={RESOURCE_PROJECT}
    validate={isRequired ? required() : undefined}
    includeFields={['id', 'project']}
    additionalParams={{ skipListAdditionalData: true }}
  />
);

ProjectInput.defaultProps = {
  label: 'Project Name',
  source: 'projectId',
  optionText: 'project',
  name: 'project_inc',
};

export default ProjectInput;
