import React, { ReactElement, useCallback } from 'react';
import { FunctionField } from 'react-admin';
import { truncateLongMessage } from '../../../../../utils/UtilityFunctions';
import commonStyles from '../../../../common/commonStyles';
import { QrScanItem } from '../../../types';

interface FileNameFieldProps {
  label?: string;
  data: QrScanItem[];
}

const FileNameField: React.FC<FileNameFieldProps> = ({
  data,
}): ReactElement => {
  const getFileName = useCallback(
    (record) => {
      const fileName = data.find(
        (item) => item.recordId === record.id
      )?.fileName;
      const fileNameParts = fileName.split('.');
      fileNameParts[0] = truncateLongMessage(fileNameParts[0], 30, '···');
      return fileNameParts.join('.');
    },
    [data]
  );

  return <FunctionField render={getFileName} sx={commonStyles.rowFormField} />;
};

FileNameField.defaultProps = { label: 'File Name' };

export default FileNameField;
