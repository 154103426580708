import React, { ReactElement, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { useRecordContext } from 'react-admin';
import { diff } from 'just-diff';
import { getContext, UserContext } from '../../../provider/userContext';
import { CertificateDetails } from '../../certDetails/types';
import JSONForm from '../../common/JSONForm/JSONForm';
import useCanAccess from '../../common/hooks/useCanAccess';

const JSONFormTab: React.FC = (): ReactElement => {
  const record = useRecordContext();
  const ctx: UserContext = getContext();
  const { getValues, setValue } = useFormContext<CertificateDetails>();
  const canAccessList = useCanAccess();

  const onChange = useCallback(
    (errors: object[], data: object) => {
      // Remove Required function in JSON forms
      // https://trackertech.monday.com/boards/195709125/views/141874149/pulses/7315991016
      // if (data && errors.length === 0) {
      if (data) {
        const formData = getValues('formData') || {};
        const diffObj = diff(formData, data);
        if (diffObj.length > 0 && diffObj?.[0]?.path?.[0]) {
          data['stepActions'] = {
            ...(data['stepActions'] || {}),
            [diffObj[0].path[0]]: {
              updatedBy: ctx?.fullName,
              updatedAt: new Date().toISOString(),
            },
          };
        }

        setValue('formData', data, { shouldDirty: true });
      }
    },
    [getValues, setValue, ctx?.fullName]
  );
  return (
    <JSONForm
      formSchema={record?.formSchema}
      formUiSchema={record?.formUiSchema}
      formData={record?.formData}
      readonly={!canAccessList.edit}
      onChange={onChange}
    />
  );
};

export default JSONFormTab;
