import React, { ReactElement, useCallback } from 'react';
import commonStyles from '../common/commonStyles';
import TabbedEditForm from '../common/TabbedEditForm';
import LazyFormTab from '../common/LazyFormTab';
import MetaDataInputs from '../common/MetaDataInputs';
import HistoryTab from '../common/HistoryTab';
import { EditPropsWithLocation } from '../common/types';
import PreservationItemForm from './PreservationItemForm';
import { PreservationItemType } from './types';
import CommentFormTab from '../common/CommentFormTab';
import { commentType } from '../common/constants';
import { RESOURCE_PRESERVATION_ITEM } from '../constants';
import { useRedirect } from 'react-admin';
import { PreservationSeriesListTab } from './components/PreservationSeriesListTab';
import SaveWithConfirmButton from './components/SaveWithConfirmButton';

const PreservationItemEdit: React.FC<
  EditPropsWithLocation<PreservationItemType>
> = (props): ReactElement => {
  const redirect = useRedirect();

  const commentTabTransformFunc = useCallback(
    (data, recordId) => ({
      ...data,
      preservationItemId: recordId,
      commentType: commentType.preservation_item,
    }),
    []
  );
  const onSuccess = async () => {
    redirect('list', RESOURCE_PRESERVATION_ITEM);
  };

  return (
    <TabbedEditForm
      {...props}
      mutationOptions={{ onSuccess }}
      autoSave={false}
      SaveButtonComponent={<SaveWithConfirmButton />}
    >
      <LazyFormTab
        label="Preservation item"
        id="preservation_item_tab"
        sx={commonStyles.formTabHeader}
      >
        <PreservationItemForm />
        <MetaDataInputs />
      </LazyFormTab>
      <LazyFormTab
        label="Series"
        id="series_tab"
        sx={commonStyles.formTabHeader}
      >
        <PreservationSeriesListTab edit />
      </LazyFormTab>
      <LazyFormTab
        label="Comment"
        id="comment_tab"
        sx={commonStyles.formTabHeader}
      >
        <CommentFormTab
          targetId="preservation_item_id"
          commentType={commentType.preservation_item}
          transformFunc={commentTabTransformFunc}
        />
      </LazyFormTab>
      <HistoryTab tableName="tbl_preservation_item" />
    </TabbedEditForm>
  );
};

export default PreservationItemEdit;
