import moment from 'moment/moment';

export const getCurrentDate = (format?: string): string =>
  moment(new Date()).format(format || 'YYYY-MM-DD');

export const getLocaleFormatDate = (
  date: moment.Moment,
  localeDateFormat: string
): string =>
  moment(date.format(localeDateFormat), localeDateFormat).format('YYYY-MM-DD');

export const isValidDate = (date: string, format?: string) => {
  return date && moment(date, format || 'YYYY-MM-DD').isValid();
};

export const getOptions = (schema) => {
  let options = [];
  if (schema?.oneOf) {
    options = schema.oneOf.map((item) => ({
      label: item.title,
      value: item.const,
    }));
  }
  if (schema?.enum) {
    options = schema.enum.map((item) => ({ label: item, value: item }));
  }
  return options;
};
