import React, { useEffect, useState, ReactElement, useCallback } from 'react';
import {
  RankedTester,
  rankWith,
  ControlProps,
  isStringControl,
  optionIs,
  and,
} from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import { Unwrapped } from '@jsonforms/material-renderers';
import { useFormContext } from 'react-hook-form';
import { Grid } from '@mui/material';
import { getOptions } from './helpers';
import StepAction from './StepAction';

const { MaterialEnumArrayRenderer } = Unwrapped;

export const CheckboxControl: React.FC<ControlProps> = (
  props
): ReactElement => {
  const formContext = useFormContext();
  const formData = formContext?.watch('formData');
  const [stepAction, setStepAction] = useState({});
  const options = getOptions(props?.schema);

  useEffect(() => {
    if (formData?.stepActions?.[props.path]) {
      setStepAction(formData.stepActions[props.path]);
    }
  }, [formData, setStepAction, props]);

  // Use EnumArrayRenderer but return string or undefined instead of array
  // For custom single checkbox field with string value
  const addItem = useCallback(
    (path, val) => props.handleChange(path, val),
    [props]
  );

  const removeItem = useCallback(
    (path) => props.handleChange(path, undefined),
    [props]
  );

  return (
    <Grid container>
      <Grid item xs={12}>
        <MaterialEnumArrayRenderer
          {...props}
          options={options}
          addItem={addItem}
          removeItem={removeItem}
        />
        {stepAction && <StepAction data={stepAction} />}
      </Grid>
    </Grid>
  );
};

export const isCheckboxControl = and(
  isStringControl,
  optionIs('format', 'checkbox')
);

export const CheckboxControlTester: RankedTester = rankWith(
  21,
  isCheckboxControl
);

export default withJsonFormsControlProps(CheckboxControl);
