import React, { ReactElement } from 'react';
import { TextField } from 'react-admin';
import { SxProps, Theme } from '@mui/material/styles';
import { RESOURCE_ACTIVITY_PHASE } from '../constants';
import SmartLinkField from './SmartLinkField';

interface ActivityPhaseFieldProps {
  label?: string;
  source?: string;
  sx?: SxProps<Theme>;
}

const ActivityPhaseField: React.FC<ActivityPhaseFieldProps> = ({
  label,
  source,
  sx,
}): ReactElement => (
  <SmartLinkField
    label={label}
    source={source}
    resource={RESOURCE_ACTIVITY_PHASE}
    sortBy="activityPhase.phase"
  >
    <TextField source="activityPhase.phase" sx={sx} />
  </SmartLinkField>
);

ActivityPhaseField.defaultProps = {
  label: 'Phase',
  source: 'activityPhaseId',
};

export default ActivityPhaseField;
