import { usePermissions } from 'react-admin';
import { getFilteredMenuItems, processMenuItems } from './helper';
import { menuItems } from './constants';
import useCurrentProjectSettings from '../common/hooks/useCurrentProjectSettings';

const useGetMenuItems = () => {
  const { isLoading, permissions } = usePermissions();
  const currentProjectSettings = useCurrentProjectSettings();

  if (isLoading) {
    return [];
  }

  const processedMenuItems = processMenuItems(menuItems, permissions);

  return getFilteredMenuItems(processedMenuItems, currentProjectSettings);
};

export default useGetMenuItems;
