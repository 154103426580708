/* eslint-disable*/
import React, { ReactElement } from 'react';
import {
  Datagrid,
  ListContextProvider,
  useGetList,
  useList,
  useRecordContext,
  TextField,
  DateField,
  ListBase,
} from 'react-admin';
import { Box } from '@mui/material';
import AttachmentRevision from '../../attachment/components/AttachmentRevision';
import AttachmentShowButton from '../../attachment/components/AttachmentShowButton';
import DownloadButton from '../../common/DownloadButton';
import AttachmentEmpty from '../../common/AttachmentEmpty';
import { ATTACHMENT_TYPE_ENUMS } from '../../common/enums';
import { LOOKUP_PAGE_SIZE } from '../../common/constants';
import commonStyles from '../../common/commonStyles';
import {
  RESOURCE_ATTACHMENT,
  RESOURCE_PRESERVATION_ITEM_ACTIVITY,
} from '../../constants';

const PreservationAttachmentTab: React.FC = (): ReactElement => {
  const record = useRecordContext();
  const { data: preservations, isLoading: isLoading1 } = useGetList(
    RESOURCE_PRESERVATION_ITEM_ACTIVITY,
    {
      filter: { componentId: record.id },
      pagination: {
        page: 1,
        perPage: LOOKUP_PAGE_SIZE,
      },
    }
  );
  const ids = preservations?.map((test) => test.id);

  const { data: attachments, isLoading: isLoading2 } = useGetList(
    RESOURCE_ATTACHMENT,
    {
      filter: {
        preservationItemActivityId: ids,
        isDeleted: false,
        attachmentType: ATTACHMENT_TYPE_ENUMS.GENERAL,
      },
      pagination: {
        page: 1,
        perPage: LOOKUP_PAGE_SIZE,
      },
    }
  );

  const listContext = useList({
    data: attachments,
    isLoading: isLoading1 || isLoading2,
  });

  return (
    <ListBase resource="attachment">
      <ListContextProvider value={listContext}>
        <Datagrid
          bulkActionButtons={false}
          rowClick={false}
          empty={<AttachmentEmpty />}
          expand={<AttachmentRevision onlyShow />}
          expandSingle
        >
          <TextField source="id" />
          <TextField source="fileName" />
          <DateField source="updatedAt" label="Updated At" />
          <TextField source="updatedByName" label="Updated By Name" />
          <Box sx={commonStyles.columnActionsBox}>
            <AttachmentShowButton />
            <DownloadButton />
          </Box>
        </Datagrid>
      </ListContextProvider>
    </ListBase>
  );
};

export default PreservationAttachmentTab;
