import React, { ReactElement } from 'react';
import { Toolbar } from '@mui/material';
import Box from '@mui/material/Box';
import SaveButton from '../../../../common/SaveButton';
import VendorInput from '../../../../common/VendorInput/VendorInput';
import TextInput from '../../../../common/TextInput';
import commonStyles from '../../../../common/commonStyles';
import { SimpleForm } from 'react-admin';
import {
  useDictionaryLabelsWithResource,
  required,
} from '../../../../../utils/UtilityFunctions';

interface VendorTabFormProps {
  onSubmit: (data: Record<string, string>) => void;
  record?: Record<string, string>;
}

const VendorTabForm: React.FC<VendorTabFormProps> = ({
  onSubmit,
  record,
}): ReactElement => {
  const { labels } = useDictionaryLabelsWithResource();

  return (
    <SimpleForm
      id="material_vendor"
      onSubmit={onSubmit}
      toolbar={
        <Toolbar>
          <SaveButton />
        </Toolbar>
      }
    >
      <Box sx={{ ...commonStyles.flexBox, ...commonStyles.fullWidth }}>
        <VendorInput
          label="Vendor"
          defaultValue={record?.vendorId || ''}
          sx={commonStyles.halfWidth}
          validate={required()}
          canCreate
        />
        <TextInput
          source="vendorPartNo"
          name="vendorPartNo"
          label={labels['partNumber'] || 'Vendor Part No'}
          defaultValue={record?.vendorPartNo || ''}
          sx={commonStyles.halfWidth}
        />
      </Box>
      <Box sx={{ ...commonStyles.flexBox, ...commonStyles.fullWidth }}>
        <TextInput
          source="description"
          name="description"
          label={labels['vendorDescription'] || 'Vendor Description'}
          defaultValue={record?.description || ''}
          sx={commonStyles.halfWidth}
        />
        <TextInput
          source="location"
          name="location"
          label={labels['vendorLocation'] || 'Location'}
          defaultValue={record?.location || ''}
          sx={commonStyles.halfWidth}
        />
      </Box>
    </SimpleForm>
  );
};

export default VendorTabForm;
