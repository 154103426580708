import React, { ReactElement } from 'react';
import { TextField } from 'react-admin';
import { RESOURCE_PUNCH_CATEGORY } from '../constants';
import SmartLinkField from './SmartLinkField';

interface PunchCategoryFieldProps {
  label?: string;
  source?: string;
}

const PunchCategoryField: React.FC<PunchCategoryFieldProps> = ({
  label,
  source,
}): ReactElement => (
  <SmartLinkField
    label={label}
    source={source}
    resource={RESOURCE_PUNCH_CATEGORY}
    sortBy="punchCat.category"
  >
    <TextField source="punchCat.category" />
  </SmartLinkField>
);

PunchCategoryField.defaultProps = {
  label: 'Punch Category',
  source: 'punchCatId',
};

export default PunchCategoryField;
