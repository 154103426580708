import React, { ReactElement } from 'react';
import { AutocompleteInput, ReferenceInput } from 'react-admin';
import { SxProps, Theme } from '@mui/material/styles';
import { getContext, UserContext } from '../../../provider/userContext';
import { RESOURCE_USER_GROUP } from '../../constants';
import { LOOKUP_PAGE_SIZE } from '../../common/constants';

interface UserGroupsInputProps {
  source: string;
  label: string;
  fullWidth?: boolean;
  sx?: SxProps<Theme>;
  page?: number;
  perPage?: number;
}

const UserGroupsInput: React.FC<UserGroupsInputProps> = ({
  source,
  label,
  fullWidth,
  sx,
  page,
  perPage,
}): ReactElement => {
  const ctx: UserContext = getContext();
  const projectId: number = ctx.projectId;

  return (
    <ReferenceInput
      source={source}
      reference={RESOURCE_USER_GROUP}
      sort={{ field: 'userGroup', order: 'ASC' as const }}
      page={page ?? 1}
      perPage={perPage ?? LOOKUP_PAGE_SIZE}
      filter={{ projectId }}
    >
      <AutocompleteInput
        source="id"
        label={label}
        multiple
        optionText="userGroup"
        fullWidth={fullWidth}
        sx={sx}
      />
    </ReferenceInput>
  );
};

export default UserGroupsInput;
