import React, { ReactElement } from 'react';
import {
  CreateProps,
  ReferenceManyField,
  Datagrid,
  DateField,
  TextField,
} from 'react-admin';
import useAttachmentCanAccess from '../common/hooks/useAttachmentCanAccess';
import DeleteWithConfirmIconButton from '../common/DeleteWithConfirmIconButton';
import DownloadButton from '../common/DownloadButton';
import PreviewButton from '../common/PreviewButton';
import AttachmentEmpty from '../common/AttachmentEmpty';
import ColumnActions from '../common/ColumnActions';
import { ATTACHMENT_TYPE_ENUMS } from '../common/enums';
import { RESOURCE_ATTACHMENT } from '../constants';
import AttachmentShowButton from './components/AttachmentShowButton';
import AttachmentEditButton from './components/AttachmentEditButton';
import AttachmentRevision from './components/AttachmentRevision';

export interface AttachmentListProps extends CreateProps {
  targetId: string;
  onlyShow?: boolean;
  attachmentType?: string[];
  onDelete?: (record) => void;
  simpleView?: boolean;
  resourceName?: string;
}

const AttachmentList: React.FC<AttachmentListProps> = ({
  targetId,
  onlyShow,
  attachmentType = [ATTACHMENT_TYPE_ENUMS.GENERAL],
  onDelete,
  simpleView = false,
  resourceName,
}): ReactElement => {
  const onlyShowAccess = useAttachmentCanAccess(onlyShow);

  return (
    <ReferenceManyField
      reference={RESOURCE_ATTACHMENT}
      target={targetId}
      perPage={100}
      fullWidth={true}
      filter={{
        isDeleted: false,
        attachmentType,
        resourceName,
      }}
    >
      <Datagrid
        bulkActionButtons={false}
        rowClick={false}
        empty={<AttachmentEmpty />}
        expand={
          simpleView ? null : <AttachmentRevision onlyShow={onlyShowAccess} />
        }
        expandSingle
      >
        <TextField source="id" />
        <TextField source="fileName" />
        <TextField source="subtype.subtype" label="Type" />

        {!simpleView && <DateField source="updatedAt" label="Updated At" />}
        <TextField source="updatedByName" label="Updated By Name" />

        <ColumnActions
          label="Actions"
          showActions={{ show: false, edit: false, delete: false }}
        >
          <PreviewButton />
          <AttachmentShowButton />
          {!onlyShowAccess && <AttachmentEditButton />}
          <DownloadButton />
          {!onlyShowAccess && (
            <DeleteWithConfirmIconButton redirect={false} onDelete={onDelete} />
          )}
        </ColumnActions>
      </Datagrid>
    </ReferenceManyField>
  );
};

export default AttachmentList;
