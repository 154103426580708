import React, { useEffect } from 'react';
import { Datagrid, TextField, useUnselectAll } from 'react-admin';
import { RESOURCE_TEMPLATE } from '../../constants';
import FreeTextSearchFilter from '../FreeTextSearchFilter';
import { ListWithPagination } from '../ListWithPagination';
import { TemplateType } from '../types';
import BulkCopyTemplatesButton from './BulkCopyTemplatesButton';

interface CopyTemplatesButtonProps {
  templateType: TemplateType;
  onSuccess: () => void;
  resource?: string;
  lambdaMode?: boolean;
}

const CopyTemplatesDatagrid: React.FC<CopyTemplatesButtonProps> = ({
  templateType,
  onSuccess,
  resource,
  lambdaMode,
}) => {
  const unselectAll = useUnselectAll(RESOURCE_TEMPLATE);

  useEffect(() => {
    return () => {
      unselectAll();
    };
  }, [unselectAll]);

  return (
    <ListWithPagination
      resource={RESOURCE_TEMPLATE}
      filters={<FreeTextSearchFilter />}
      filterDefaultValues={{ templateType }}
      actions={false}
      disableSyncWithLocation
    >
      <Datagrid
        bulkActionButtons={
          <BulkCopyTemplatesButton
            templateType={templateType}
            onSuccess={onSuccess}
            resourceContext={resource}
            lambdaMode={lambdaMode}
          />
        }
      >
        <TextField source="name" label="Name" />
        <TextField source="description" label="Description" />
      </Datagrid>
    </ListWithPagination>
  );
};

export default CopyTemplatesDatagrid;
