import { useGetList } from 'react-admin';
import { RESOURCE_USER_GROUP } from '../../constants';
import { getContext, UserContext } from '../../../provider/userContext';

const useUserGroupsList = () => {
  const ctx: UserContext = getContext();

  return useGetList(RESOURCE_USER_GROUP, {
    filter: { projectId: ctx?.projectId },
    sort: { field: 'userGroup', order: 'ASC' },
  });
};

export default useUserGroupsList;
