import React, { ReactElement } from 'react';
import { TextField } from 'react-admin';
import DatagridConfigurableRbac from '../common/DatagridConfigurableRbac';
import ListActions from '../common/ListActions';
import ListWithTitle from '../common/ListWithTitle';
import FreeTextSearchFilter from '../common/FreeTextSearchFilter';
import CustomBooleanField from '../common/CustomBooleanField';
import ColumnActions from '../common/ColumnActions';
import { getContext, UserContext } from '../../provider/userContext';
import { RESOURCE_DIVISION } from '../constants';
import RoleField from './components/RoleField';
import GroupField from './components/GroupField';
import UserGroupField from './components/UserGroupField';
import UserShow from './UserShow';
import UserEdit from './UserEdit';
import SmartReferenceField from '../common/SmartReferenceField';

const UserList: React.FC = (): ReactElement => {
  const ctx: UserContext = getContext();
  const companySource = `companies.${ctx?.company}`;
  const projectSource = `${companySource}.currentProject`;

  return (
    <ListWithTitle
      filters={<FreeTextSearchFilter />}
      sort={{ field: 'id', order: 'ASC' }}
      actions={<ListActions showAction={{ effective: true }} />}
    >
      <DatagridConfigurableRbac
        ShowComponent={<UserShow withoutActions />}
        EditComponent={<UserEdit syncWithLocation={false} noCache />}
        sx={{
          '& .column-actions': { textAlign: 'center' },
          '& .column-priority': { minWidth: '120px' },
        }}
        defaultVisibleColumns={[
          'id',
          'firstName',
          'lastName',
          `${companySource}.defaultDivisionId`,
          `${projectSource}.groups`,
          `${projectSource}.roles`,
          `${projectSource}.userGroups`,
        ]}
      >
        <TextField source="id" label="Email" />
        <TextField source="firstName" label="First Name" />
        <TextField source="lastName" label="Last Name" />
        <TextField source="jobTitle" label="Job Title" />
        <TextField source="externalCompany" label="Company" />
        <TextField source="pinCode" label="Pin Code" />
        <SmartReferenceField
          label="Division"
          source={`${companySource}.defaultDivisionId`}
          reference={RESOURCE_DIVISION}
          sortable={false}
          textSource="divisionName"
        />
        <GroupField
          label="Groups"
          source={`${projectSource}.groups`}
          sortable={false}
        />
        <RoleField
          label="Roles"
          source={`${projectSource}.roles`}
          sortable={false}
        />
        <UserGroupField
          label="User Groups"
          source={`${projectSource}.userGroups`}
          sortable={false}
        />
        <CustomBooleanField
          source={`${companySource}.isPreferred`}
          label="Preferred Company"
        />
        <CustomBooleanField
          source={`${companySource}.isCompanyAccessDisabled`}
          label="Company Access Disabled"
        />
        <ColumnActions label="Actions" />
      </DatagridConfigurableRbac>
    </ListWithTitle>
  );
};

export default UserList;
