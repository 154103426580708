import React, { ReactElement, useCallback } from 'react';
import { TabbedForm, useRedirect, useNotify } from 'react-admin';
import { useQueryClient } from '@tanstack/react-query';
import { getContext, UserContext } from '../../provider/userContext';
import FooterToolbar from '../common/FooterToolbar';
import CreateWithTitle from '../common/CreateWithTitle';
import { ATTACHMENT_TYPE_ENUMS } from '../common/enums';
import useUpload from '../common/hooks/useUpload';
import { FormDataWithFiles } from '../attachment/types';
import { RESOURCE_PROJECT, RESOURCE_ATTACHMENT } from '../constants';
import ProjectFormDetailsTab from './components/ProjectFormDetailsTab';
import ProjectFormEnforceCertGatingTab from './components/ProjectFormEnforceCertGatingTab';
import ProjectFormInheritCertSignOffsTab from './components/ProjectFormInheritCertSignOffsTab';
import ProjectFormItrAutomationTab from './components/ProjectFormItrAutomationTab';
import ProjectFormProjectTab from './components/ProjectFormProjectTab';
import ProjectSourceProjectTab from './components/ProjectSourceProjectTab';
import ProjectFormPresAutomationTab from './components/ProjectFormPresAutomationTab';
import { Project } from './types';

const ProjectCreate: React.FC = (): ReactElement => {
  const ctx: UserContext = getContext();
  const queryClient = useQueryClient();
  const { upload } = useUpload({});
  const redirect = useRedirect();
  const notify = useNotify();
  let logo: File;

  const transform = (data: Project): Project => {
    if (data?.attachment?.rawFile) {
      logo = data.attachment.rawFile as File;
    }

    return {
      ...data,
      divisionId: ctx?.defaultDivisionId,
      tableCopies: Object.keys(data.tableCopies)
        .filter((key) => !!data.tableCopies[key])
        .sort((a, b) => a.localeCompare(b)),
    };
  };

  const onSuccess = useCallback(
    async (record: Project) => {
      if (logo) {
        const attachment: FormDataWithFiles = {
          files: [logo],
          attachmentType: ATTACHMENT_TYPE_ENUMS.LOGO,
          projectId: String(record.id),
          refProjectId: String(record.id),
          resourceName: RESOURCE_PROJECT,
        };

        await upload(RESOURCE_ATTACHMENT, attachment);
      }

      if (record.permissionCopyErrors) {
        for (const error of record.permissionCopyErrors) {
          notify(error, {
            type: 'error',
            undoable: false,
            autoHideDuration: 10000,
          });
        }
      }

      queryClient.removeQueries({
        queryKey: [RESOURCE_PROJECT, 'getOne', { id: String(record.id) }],
        exact: true,
      });

      redirect('list', RESOURCE_PROJECT);
    },
    [notify, logo, upload, redirect, queryClient]
  );

  return (
    <CreateWithTitle
      redirect="list"
      transform={transform}
      mutationOptions={{ onSuccess }}
    >
      <TabbedForm toolbar={<FooterToolbar />}>
        <TabbedForm.Tab label="Project">
          <ProjectFormProjectTab />
        </TabbedForm.Tab>
        <TabbedForm.Tab label="Details">
          <ProjectFormDetailsTab />
        </TabbedForm.Tab>
        <TabbedForm.Tab label="Source Project">
          <ProjectSourceProjectTab />
        </TabbedForm.Tab>
        <TabbedForm.Tab label="Inherit Cert Sign Offs">
          <ProjectFormInheritCertSignOffsTab />
        </TabbedForm.Tab>
        <TabbedForm.Tab label="Enforce Cert Gating">
          <ProjectFormEnforceCertGatingTab />
        </TabbedForm.Tab>
        <TabbedForm.Tab label="ITR Automation">
          <ProjectFormItrAutomationTab />
        </TabbedForm.Tab>
        <TabbedForm.Tab label="Pres Automation">
          <ProjectFormPresAutomationTab />
        </TabbedForm.Tab>
      </TabbedForm>
    </CreateWithTitle>
  );
};

export default ProjectCreate;
