import { isEmpty, omitBy, isUndefined } from 'lodash';
import {
  CreateParams,
  DataProvider,
  DeleteManyParams,
  DeleteParams,
  fetchUtils,
  GetListParams,
  GetManyParams,
  GetManyReferenceParams,
  GetOneParams,
  UpdateManyParams,
  UpdateParams,
} from 'react-admin';
import simpleRestProvider from 'ra-data-simple-rest';
import {
  ContentType,
  CRUD_API_URL,
  getHeaders,
  getRequestConfig,
} from '../../api';
import axios from 'axios';
import { processRequestParams } from '../../helpers';
import { CustomDataProvider } from '../types';

/**
 * To have a mock api,
 * 1 - uncomment fakeDataProvider() block
 * 2 - Define your own mock data
 * 2 - update getDataProvider() return object ie
 * .............
 *     getList: (resource: string, params: GetListParams) => {
 *       if (resource == 'location'){
 *         return mockDataProvider.getList(resource, params);
 *       } else {
 *         return defaultProvider.getList(resource, params);
 *       }
 *     },
 * .............
 *    Add similar if conditions to others (getOne, create, delete, update...)
 *
 */

// import fakeDataProvider from 'ra-data-fakerest';
// const mockDataProvider = fakeDataProvider({
//   location: [
//     { id: 1, location: 'Fab Yard', description: 'Fab Yard', project_id: 1 },
//     { id: 2, location: 'Warehouse - Rack 10', description: 'Warehouse - Rack 10', project_id: 1 },
//   ],
// });
const getDataProviderFactory = () => {
  const createHttpClient = (metaParams = {}) => {
    const httpClient = (url, options: fetchUtils.Options = {}) => {
      options.headers = new Headers({
        ...getHeaders(),
        ...options.headers,
      });

      const requestUrl = isEmpty(metaParams)
        ? url
        : `${url}&additionalParams=${encodeURI(JSON.stringify(metaParams))}`;

      return fetchUtils.fetchJson(requestUrl, options);
    };
    return httpClient;
  };

  const createProvider = (httpClient) =>
    simpleRestProvider(CRUD_API_URL, httpClient);

  const defaultProvider = createProvider(createHttpClient());

  return {
    getProvider: (params?): DataProvider => {
      const metaParams = omitBy(params?.meta || {}, isUndefined);
      return isEmpty(metaParams)
        ? defaultProvider
        : createProvider(createHttpClient(metaParams));
    },
    getDefaultProvider: (): DataProvider => defaultProvider,
  };
};

export const getDefaultDataProvider = (): CustomDataProvider => {
  const dataProviderFactory = getDataProviderFactory();
  const defaultProvider = dataProviderFactory.getDefaultProvider();

  //This will give us a flexibility, to call pre / post api or do additional logic or tweak params / resources
  return {
    create: (resource: string, params: CreateParams) => {
      return defaultProvider.create(resource, params);
    },
    createMany: (resource: string, params: CreateParams) => {
      return axios
        .post(
          `${CRUD_API_URL}/${resource}/create-many`,
          params.data,
          getRequestConfig()
        )
        .then((result) => ({ data: result.data[0] }));
      // return single model for react-query to mutate data without errors, NEED TO call invalidateCache after the request!!!
    },
    delete: (resource: string, params: DeleteParams) => {
      return defaultProvider.delete(resource, params);
    },
    deleteMany: (resource: string, params: DeleteManyParams) => {
      return defaultProvider.deleteMany(resource, params);
    },
    getList: (resource: string, params: GetListParams) => {
      return dataProviderFactory
        .getProvider(params)
        .getList(resource, processRequestParams<GetListParams>(params));
    },
    getMany: (resource: string, params: GetManyParams) => {
      return defaultProvider.getMany(resource, params);
    },
    getManyReference: (resource: string, params: GetManyReferenceParams) => {
      return defaultProvider.getManyReference(resource, params);
    },
    getOne: (resource: string, params: GetOneParams) => {
      return defaultProvider.getOne(resource, params);
    },
    update: (resource: string, params: UpdateParams) => {
      return defaultProvider.update(resource, params);
    },
    updateMany: (resource: string, params: UpdateManyParams) => {
      return axios.put(
        `${CRUD_API_URL}/${resource}/update-many`,
        params,
        getRequestConfig()
      );
    },
    export: (resource: string) => {
      return axios.get(`${CRUD_API_URL}/${resource}`, {
        headers: getHeaders(),
        responseType: 'blob',
      });
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    import: async (resource: string, data: Record<string, any>) => {
      return axios.post(`${CRUD_API_URL}/${resource}/import`, data, {
        headers: {
          ...getHeaders(),
          request_group: 'Import',
        },
      });
    },
    createFormData: (resource: string, data: FormData) => {
      return axios.post(
        `${CRUD_API_URL}/${resource}`,
        data,
        getRequestConfig()
      );
    },
    uploadFile: (resource: string, data: FormData) => {
      return axios.post(
        `${CRUD_API_URL}/${resource}/upload-file`,
        data,
        getRequestConfig(ContentType.formData)
      );
    },
  };
};
