import React, { Fragment, useCallback, ReactElement } from 'react';
import {
  AutocompleteInput,
  CreateProps,
  FileField,
  SimpleForm,
  maxLength,
} from 'react-admin';
import { isEmpty, required } from '../../../../utils/UtilityFunctions';
import Box from '@mui/material/Box';
import CustomNumberInput from '../../../common/CustomNumberInput';
import { FormDataWithFiles, RawAttachment } from '../../types';
import ProjectInput from '../../../common/ProjectInput';
import useUpload from '../../../common/hooks/useUpload';
import { ATTACHMENT_TYPE_ENUMS } from '../../../common/enums';
import { RESOURCE_ATTACHMENT } from '../../../constants';
import commonStyles from '../../../common/commonStyles';
import {
  ATTACHMENT_TYPE_CHOICES,
  RESOURCE_CHOICES,
  RESOURCE_NAMES,
} from './constants';
import TextInput from '../../../common/TextInput';
import AttachmentTypeInput from '../../../common/AttachmentTypeInput';
import { UserContext, getContext } from '../../../../provider/userContext';
import CustomFileInput from '../../../common/CustomFileInput';

type AdminAttachmentFormData = {
  attachment: RawAttachment;
  attachmentType: ATTACHMENT_TYPE_ENUMS;
  fileName: string;
  description: string;
  resourceId: string;
  resource: string;
  projectId?: number;
  subtypeId?: number;
};

interface AdminAttachmentFormProps extends CreateProps {
  onUpload?: () => void;
}

const AdminAttachmentForm: React.FC<AdminAttachmentFormProps> = ({
  onUpload,
}): ReactElement => {
  const { upload } = useUpload({
    onSuccess: onUpload,
  });

  const ctx: UserContext = getContext();

  const handleSubmit = useCallback(
    async (data: AdminAttachmentFormData) => {
      const newData: FormDataWithFiles = {
        files: [data.attachment.rawFile],
        attachmentType: data.attachmentType,
        [data.resource]: data.resourceId,
        resourceName: RESOURCE_NAMES[data.resource] || '',
        ...(!isEmpty(data.fileName) && {
          fileName: data.fileName.split('.')[0],
        }),
        ...(!isEmpty(data.description) && {
          description: data.description,
        }),
        ...(!isEmpty(data.subtypeId) && {
          subtypeId: data.subtypeId,
        }),
        ...(data.projectId ? { projectId: String(data.projectId) } : {}),
      };

      await upload(RESOURCE_ATTACHMENT, newData);
    },
    [upload]
  );

  return (
    <SimpleForm id="upload_attachment_form" onSubmit={handleSubmit}>
      <CustomFileInput
        source="attachment"
        label={false}
        sx={commonStyles.uploadField}
        validate={[required()]}
      >
        <Fragment>
          <FileField source="src" title="title" target="_blank" />
          <Box sx={commonStyles.uploadFieldBox}>
            <TextInput
              source="Title"
              label="Title"
              name="fileName"
              validate={[maxLength(150)]}
              sx={commonStyles.uploadTextField}
            />
            <TextInput
              source="description"
              label="Description"
              name="description"
              validate={[maxLength(255)]}
              sx={commonStyles.uploadTextField}
            />
            <Box sx={commonStyles.uploadFieldRowBox}>
              <AutocompleteInput
                label="Attachment type"
                source="attachmentType"
                choices={ATTACHMENT_TYPE_CHOICES}
                sx={commonStyles.uploadFieldRowBoxItem}
                validate={[required()]}
              />
              <AttachmentTypeInput sx={commonStyles.uploadFieldRowBoxItem} />
            </Box>
            <ProjectInput
              sx={commonStyles.uploadTextField}
              defaultValue={ctx?.projectId}
            />
            <Box sx={commonStyles.uploadFieldRowBox}>
              <AutocompleteInput
                label="Resource"
                source="resource"
                choices={RESOURCE_CHOICES}
                sx={commonStyles.uploadFieldRowBoxItem}
                validate={[required()]}
              />
              <CustomNumberInput
                label="Resource Id"
                source="resourceId"
                sx={commonStyles.uploadFieldRowBoxItem}
                fractionDigits={0}
                isPositive
                validate={[required()]}
              />
            </Box>
          </Box>
        </Fragment>
      </CustomFileInput>
    </SimpleForm>
  );
};

export default AdminAttachmentForm;
