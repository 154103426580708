import React, { ReactElement, Fragment } from 'react';
import { RESOURCE_PRESERVATION_ITEM } from '../../constants';
import BulkEditButton from '../../common/BulkEditButton';
import PreservationItemForm from '../PreservationItemForm';

const BulkActionButtons: React.FC = (): ReactElement => {
  return (
    <Fragment>
      <BulkEditButton
        resource={RESOURCE_PRESERVATION_ITEM}
        form={<PreservationItemForm bulkEditMode />}
      />
    </Fragment>
  );
};

export default BulkActionButtons;
