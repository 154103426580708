import React, { ReactElement } from 'react';
import { Button, ButtonProps } from 'react-admin';
import { styled, alpha } from '@mui/material/styles';
import { ReactComponent as DeleteIcon } from '../../assets/icons/functionalIcons/Trash.svg';

const StyledButton = styled(Button)(({ theme, label }) => ({
  color: theme.palette.error.main,
  '& > span.MuiButton-startIcon': {
    marginRight: label ? '8px' : 0,
  },
  '&:hover': {
    backgroundColor: alpha(theme.palette.error.main, 0.12),
    // Reset on mouse devices
    '@media (hover: none)': {
      backgroundColor: 'transparent',
    },
  },
}));

const HardDeleteButton: React.FC<ButtonProps> = (props): ReactElement => {
  const { className, onClick, label, ...rest } = props;

  return (
    <StyledButton
      onClick={onClick}
      className={className}
      key="button"
      label={label}
      {...rest}
    >
      <DeleteIcon />
    </StyledButton>
  );
};

export default HardDeleteButton;
