import React, { ReactElement } from 'react';
import { TextField } from 'react-admin';
import { RESOURCE_ATTACHMENT_TYPE } from '../constants';
import SmartLinkField from './SmartLinkField';

interface AttachmentTypeFieldProps {
  label?: string;
  source?: string;
}

const AttachmentTypeField: React.FC<AttachmentTypeFieldProps> = ({
  label,
  source,
}): ReactElement => (
  <SmartLinkField
    source={source}
    label={label}
    resource={RESOURCE_ATTACHMENT_TYPE}
    sortBy="subtype.subtype"
  >
    <TextField source="subtype.subtype" />
  </SmartLinkField>
);

AttachmentTypeField.defaultProps = {
  label: 'Type',
  source: 'subtypeId',
};

export default AttachmentTypeField;
