export const RESOURCE_ATTACHMENT = 'attachment';
export const RESOURCE_ATTACHMENT_TYPE = 'attachment-subtype';
export const RESOURCE_ATTACHMENT_REVISION = 'attachment-revision';
export const RESOURCE_IMPORT = 'import';
export const RESOURCE_ACTIVITY_TYPE = 'activity-type';
export const RESOURCE_PROJECT = 'project';
export const RESOURCE_MODULE = 'module';
export const RESOURCE_LOCATION = 'location';
export const RESOURCE_DISCIPLINE = 'discipline';
export const RESOURCE_DICTIONARY = 'dictionary';
export const RESOURCE_SYSTEM = 'system';
export const RESOURCE_SYSTEM_ACTIVITY = 'system-activity';
export const RESOURCE_COMMENT = 'comment';
export const RESOURCE_WORK_PACK = 'work-pack';
export const RESOURCE_PUNCH_CATEGORY = 'punch-category';
export const RESOURCE_PUNCH_TEMPLATE = 'punch-template';
export const RESOURCE_PUNCH_PRIORITY = 'punch-priority';
export const RESOURCE_DIVISION = 'division';
export const RESOURCE_STRUCTURE_ASSET = 'structure-asset';
export const RESOURCE_GROUP = 'group';
export const RESOURCE_STRUCTURE_REGION = 'structure-region';
export const RESOURCE_SUBSYSTEM = 'subsystem';
export const RESOURCE_AUDIT = 'audit';
export const RESOURCE_AUDIT_TABLE_NAME = 'audit-table-name';
export const RESOURCE_STRUCTURE_ASSET_GROUP = 'structure-asset-group';
export const RESOURCE_TEST_TYPE = 'test-type';
export const RESOURCE_DRAWING_TYPE = 'drawing-type';
export const RESOURCE_DRAWING = 'drawing';
export const RESOURCE_DRAWING_PREFIX = 'drawing-prefix';
export const RESOURCE_SECURITY_ROLE = 'security-role';
export const RESOURCE_SECURITY_GROUP = 'security-group';
export const RESOURCE_SECURITY_ROLE_PERMISSION = 'security-role-permission';
export const RESOURCE_POSITION = 'position';
export const RESOURCE_DRAWING_DISCIPLINE = 'drawing-discipline';
export const RESOURCE_ACTIVITY_PHASE = 'activity-phase';
export const RESOURCE_VENDOR = 'vendor';
export const RESOURCE_EQUIPMENT_TYPE = 'equipment-type';
export const RESOURCE_EQUIPMENT_TYPE_TEST = 'equipment-type-test';
export const RESOURCE_EQUIPMENT_TYPE_WITH_TEST = 'equipment-type-with-test';
export const RESOURCE_USER = 'user';
export const RESOURCE_DISCIPLINE_TEST = 'discipline-test';
export const RESOURCE_USER_GROUP = 'user-group';
export const RESOURCE_COMPONENT = 'component';
export const RESOURCE_COMPONENT_ACTIVITY = 'component-activity';
export const RESOURCE_PRESERVATION_ITEM = 'preservation-item';
export const RESOURCE_PRESERVATION_SERIES = 'preservation-series';
export const RESOURCE_PRESERVATION_ITEM_ACTIVITY = 'preservation-item-activity';
export const RESOURCE_SMART_PRESERVATION_ITEM_ACTIVITY =
  'smart-preservation-item-activity';
export const RESOURCE_CONTACT = 'contact';
export const RESOURCE_BOOK = 'book';
export const RESOURCE_BOOK_SECTION = 'book-section';
export const RESOURCE_BOOK_SECTION_CERT = 'book-section-cert';
export const RESOURCE_BOOK_SECTION_ENG_CODE = 'book-section-eng-code';
export const RESOURCE_BOOK_SECTION_TEST = 'book-section-test';
export const RESOURCE_PRINTED_BOOK = 'printed-book';
export const RESOURCE_COMPONENT_TEST = 'component-test';
export const RESOURCE_TEST = 'test';
export const RESOURCE_MOC = 'moc';
export const RESOURCE_MOC_TYPE = 'moc-type';
export const RESOURCE_MOC_SYSTEM = 'moc-system';
export const RESOURCE_MOC_SUBSYSTEM = 'moc-subsystem';
export const RESOURCE_MOC_COMPONENT = 'moc-component';
export const RESOURCE_PUNCH = 'punch';
export const RESOURCE_SUBMIT_PUNCH = 'submit-punch';
export const RESOURCE_SUBSYSTEM_DRAWING = 'subsystem-drawing';
export const RESOURCE_DRAWING_SUBSYSTEM = 'drawing-subsystem';
export const RESOURCE_SUBSYSTEM_ACTIVITY = 'subsystem-activity';
export const RESOURCE_GROUP_CONTACT = 'group-contact';
export const RESOURCE_CERT_TEMPLATE = 'cert-template';
export const RESOURCE_CERT_DETAIL = 'cert-detail';
export const RESOURCE_SMART_CERT_DETAIL = 'smart-cert-detail';
export const RESOURCE_CERT_GATING_PRED_CERT = 'cert-gating-pred-cert';
export const RESOURCE_CERT_GATING_PUNCH = 'cert-gating-punch';
export const RESOURCE_CERT_GATING_TEST = 'cert-gating-test';
export const RESOURCE_CERT_GATING = 'cert-gating';
export const RESOURCE_CONFIGURATION = 'configuration';
export const RESOURCE_COMPONENT_DRAWING = 'component-drawing';
export const RESOURCE_DRAWING_COMPONENT = 'drawing-component';
export const RESOURCE_ASSIGN_ITR = 'assign-itr';
export const RESOURCE_SMART_TAG_ITR_REVIEW = 'smart-tag-itr-review';
export const RESOURCE_SMART_TAG_ITR = 'smart-tag-itr';
export const RESOURCE_TEMPLATE = 'template';
export const RESOURCE_TEST_POSITION = 'test-position';
export const RESOURCE_QR_SCANNER = 'qr-scanner';
export const RESOURCE_PENDING_PUNCH = 'pending-punch';
export const RESOURCE_REPORT = 'report';
export const RESOURCE_REPORT_VIEW = 'report-view';
export const RESOURCE_NOTIFICATION = 'notification';
export const RESOURCE_DASHBOARD = 'dashboard';
export const RESOURCE_COMPANY = 'company';
export const RESOURCE_COMPANY_SUBSCRIPTION = 'company-subscription';
export const RESOURCE_LUCID_DIAGRAM = 'lucid-diagram';
export const RESOURCE_MATERIAL_TYPE = 'material-type';
export const RESOURCE_STORAGE = 'storage';
export const RESOURCE_MATERIAL = 'material';
export const RESOURCE_MATERIAL_COMPONENT = 'material-component';
export const RESOURCE_MATERIAL_VENDOR = 'material-vendor';
export const RESOURCE_MATERIAL_STORAGE = 'material-storage';
export const RESOURCE_MATERIAL_PURCHASE_ORDER_MATERIAL =
  'material-purchase-order-material';
export const RESOURCE_MATERIAL_RELEASED = 'material-released';
export const RESOURCE_MATERIAL_RELEASED_MATERIAL_STORAGE =
  'material-released-material-storage';
export const RESOURCE_MATERIAL_PURCHASE_ORDER = 'material-purchase-order';

export const CUSTOM_ROUTE_USER_PROFILE = '/my-profile';
export const CUSTOM_ROUTE_BOOK_GENERATION = 'bookgen';
export const CUSTOM_ROUTE_QR_CODE_GENERATION = 'qr-code';
export const CUSTOM_ROUTE_SUPPORT = 'support';

export const EXPORT_RECORD_LIMIT = '50000';
export const DEFAULT_ROWS_PER_PAGE = 50;
