import React, { ReactElement } from 'react';
import {
  TextField,
  BooleanField,
  useStore,
  WrapperField,
  FunctionField,
} from 'react-admin';
import Box from '@mui/material/Box';
import SvgIcon from '@mui/material/SvgIcon';
import AdminAttachmentHardDeleteButton from './components/AdminAttachmentHardDeleteButton';
import AdminAttachmentFilterSidebar from './components/AdminAttachmentFilterSidebar';
import AdminAttachmentRevisionList from './components/AdminAttachmentRevisionList';
import useDefaultFilter from '../../../components/common/hooks/useDefaultFilter';
import AttachmentTypeField from '../../../components/common/AttachmentTypeField';
import renderMetaDataFields from '../../common/helpers/renderMetaDataFields';
import DatagridConfigurableRbac from '../../common/DatagridConfigurableRbac';
import AttachmentUploadDialog from './components/AdminAttachmentAddButton';
import { titleFromResource } from '../../common/hooks/useResourceTitle';
import AttachmentShowButton from '../components/AttachmentShowButton';
import AttachmentEditButton from '../components/AttachmentEditButton';
import FreeTextSearchFilter from '../../common/FreeTextSearchFilter';
import { ListWithPagination } from '../../common/ListWithPagination';
import ListActions from '../../common/ListActions';
import ListTitle from '../../common/ListTitle';
import DownloadButton from '../../common/DownloadButton';
import PreviewButton from '../../common/PreviewButton';
import { getSideFiltersKey } from '../../common/helpers/storeKeys';
import useCanAccess from '../../common/hooks/useCanAccess';
import commonStyles from '../../common/commonStyles';
import { RESOURCE_ATTACHMENT } from '../../constants';

const AdminAttachmentList: React.FC = (): ReactElement => {
  const sideFilter = getSideFiltersKey(RESOURCE_ATTACHMENT);
  const [showSideFilter] = useStore(sideFilter, false);
  const defaultFilter = useDefaultFilter('project');
  const canAccessList = useCanAccess();

  return (
    <ListWithPagination
      title={<ListTitle />}
      filters={<FreeTextSearchFilter />}
      filterDefaultValues={defaultFilter}
      actions={
        <ListActions
          sideFilter={sideFilter}
          actions={<AttachmentUploadDialog />}
          showAction={{ create: false, import: false }}
          defaultFilter={defaultFilter}
        />
      }
      aside={
        showSideFilter ? (
          <AdminAttachmentFilterSidebar defaultFilter={defaultFilter} />
        ) : null
      }
    >
      <DatagridConfigurableRbac
        bulkActionButtons={false}
        expandSingle
        expand={<AdminAttachmentRevisionList />}
        defaultVisibleColumns={[
          'id',
          'fileName',
          'description',
          'subtypeId',
          'attachmentType',
          'isDeleted',
          'resourceName',
        ]}
      >
        <TextField source="id" label="ID" />
        <TextField source="fileName" label="File Name" />
        <TextField source="description" label="Description" sortable={false} />
        <TextField
          source="attachmentType"
          label="Attachment Type"
          sortable={false}
        />
        <AttachmentTypeField source="subtypeId" />
        <BooleanField
          source="isDeleted"
          label="Deleted"
          FalseIcon={SvgIcon}
          sortable={false}
        />
        <WrapperField
          label="Resource Name"
          source="resourceName"
          sortable={true}
        >
          <FunctionField
            label="Resource"
            render={(record) =>
              titleFromResource?.[record.resourceName]?.[0] ||
              record.resourceName
            }
          />
        </WrapperField>
        {renderMetaDataFields()}
        <WrapperField label="Action">
          <Box sx={{ ...commonStyles.columnActionsBox, minWidth: 270 }}>
            <PreviewButton />
            {canAccessList.show && <AttachmentShowButton />}
            {canAccessList.edit && <AttachmentEditButton isAdmin />}
            <DownloadButton />
            {canAccessList.delete && (
              <AdminAttachmentHardDeleteButton label="hard delete" />
            )}
          </Box>
        </WrapperField>
      </DatagridConfigurableRbac>
    </ListWithPagination>
  );
};

export default AdminAttachmentList;
