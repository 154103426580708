import React, { Fragment, ReactElement } from 'react';
import {
  Datagrid,
  DateField,
  ReferenceManyField,
  TextField,
} from 'react-admin';
import AttachmentRevisionFormTab from '../attachmentRevision/AttachmentRevisionFormTab';
import ActivityPhaseField from '../common/ActivityPhaseField';
import { RESOURCE_CERT_DETAIL } from '../constants';
import CertTemplateField from '../common/CertTemplateField';
import CustomBooleanField from '../common/CustomBooleanField';
import DisciplineField from '../common/DisciplineField';
import DownloadButton from '../common/DownloadButton';
import PreviewButton from '../common/PreviewButton';
import { CertificateDetailsShowTabProps } from './types';

const CertDetailsShowTab: React.FC<CertificateDetailsShowTabProps> = ({
  target,
  children,
}): ReactElement => (
  <ReferenceManyField target={target} reference={RESOURCE_CERT_DETAIL}>
    <Datagrid
      expand={<AttachmentRevisionFormTab targetId="certDetailId" onlyShow />}
      expandSingle
      bulkActionButtons={false}
      rowClick={false}
    >
      {children}
      <ActivityPhaseField />
      <CertTemplateField />
      <DisciplineField />
      <TextField source="generatedBy" label="Generated By" />
      <DateField
        source="generatedDate"
        label="Generated Date"
        showTime={false}
      />
      <TextField source="acceptedBy" label="Accepted By" />
      <TextField source="acceptedDate" label="Accepted Date" />
      <CustomBooleanField source="isVerified" label="Verified" />
      <Fragment>
        <PreviewButton />
        <DownloadButton />
      </Fragment>
    </Datagrid>
  </ReferenceManyField>
);

export default CertDetailsShowTab;
