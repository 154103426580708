import React, { Fragment, ReactElement, useCallback } from 'react';
import { ReferenceManyField } from 'react-admin';
import {
  EditableDatagrid,
  EditRowButton,
} from '@react-admin/ra-editable-datagrid';
import { getContext, UserContext } from '../../../../provider/userContext';
import useInvalidateResourceQueryCache from '../../../common/hooks/useInvalidateResourceQueryCache';
import DeleteWithConfirmIconButton from '../../../common/DeleteWithConfirmIconButton';
import commonStyles from '../../../common/commonStyles';
import { RESOURCE_CERT_GATING } from '../../../constants';

interface GatingFormProps {
  children: ReactElement;
  formRow: ReactElement;
  reference: string;
}

const GatingForm: React.FC<GatingFormProps> = ({
  children,
  formRow,
  reference,
}): ReactElement => {
  const ctx: UserContext = getContext();
  const invalidateCertTemplateQueries =
    useInvalidateResourceQueryCache(RESOURCE_CERT_GATING);

  const onDelete = useCallback(
    async () => await invalidateCertTemplateQueries(),
    [invalidateCertTemplateQueries]
  );

  return (
    <ReferenceManyField
      target="cert_template_id"
      reference={reference}
      filter={{ projectId: ctx?.projectId }}
    >
      <EditableDatagrid
        actions={
          <Fragment>
            <EditRowButton />
            <DeleteWithConfirmIconButton
              onDelete={onDelete}
              removeRelatedResource={false}
            />
          </Fragment>
        }
        bulkActionButtons={false}
        mutationMode="pessimistic"
        createForm={formRow}
        editForm={formRow}
        sx={commonStyles.formTabDatagrid}
      >
        {children}
      </EditableDatagrid>
    </ReferenceManyField>
  );
};

export default GatingForm;
