import React, { ReactElement } from 'react';
import { TextField } from 'react-admin';
import { RESOURCE_STRUCTURE_REGION } from '../constants';
import SmartLinkField from './SmartLinkField';

interface RegionFieldProps {
  label?: string;
  source?: string;
  sortBy?: string;
}

const RegionField: React.FC<RegionFieldProps> = ({
  label,
  source,
}): ReactElement => (
  <SmartLinkField
    label={label}
    source={source}
    resource={RESOURCE_STRUCTURE_REGION}
  >
    <TextField source="region.region" />
  </SmartLinkField>
);

RegionField.defaultProps = {
  label: 'Region',
  source: 'regionId',
  sortBy: '`region.region`',
};

export default RegionField;
