import React, { ReactElement } from 'react';
import { CreateProps, TabbedForm, FormTab } from 'react-admin';
import FooterToolbar from '../common/FooterToolbar';
import CreateWithTitle from '../common/CreateWithTitle';
import commonStyles from '../common/commonStyles';
import ProjectsInputField from './components/ProjectsInputField';
import { getContext, UserContext } from '../../provider/userContext';
import { makeRequestBody } from './helpers';
import UserForm from './UserForm';

const UserCreate: React.FC<CreateProps> = (props): ReactElement => {
  const ctx: UserContext = getContext();

  const transform = (data) => makeRequestBody(data, ctx?.company, true);

  return (
    <CreateWithTitle {...props} redirect="list" transform={transform}>
      <TabbedForm
        toolbar={<FooterToolbar />}
        sx={commonStyles.tabbedFormBoldHeader}
      >
        <FormTab label="User" id="user_tab" sx={commonStyles.formTabHeader}>
          <UserForm createMode />
        </FormTab>
        <FormTab
          label="Projects"
          id="projects_tab"
          sx={commonStyles.formTabHeader}
        >
          <ProjectsInputField />
        </FormTab>
      </TabbedForm>
    </CreateWithTitle>
  );
};

export default UserCreate;
