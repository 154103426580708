import React, { ReactElement } from 'react';
import { TextField, useStore } from 'react-admin';
import CertTemplateCategoryField from '../common/CertTemplateCategoryField';
import CertTemplateSideFilters from './components/CertTemplateSideFilters';
import renderMetaDataFields from '../common/helpers/renderMetaDataFields';
import DatagridConfigurableRbac from '../common/DatagridConfigurableRbac';
import JSONFormShowButton from '../common/JSONForm/JSONFormShowButton';
import FreeTextSearchFilter from '../common/FreeTextSearchFilter';
import { getSideFiltersKey } from '../common/helpers/storeKeys';
import useDefaultFilter from '../common/hooks/useDefaultFilter';
import CopyTemplatesButton from '../common/CopyTemplatesButton';
import CustomBooleanField from '../common/CustomBooleanField';
import { RESOURCE_CERT_TEMPLATE } from '../constants';
import DownloadButton from '../common/DownloadButton';
import ColumnActions from '../common/ColumnActions';
import ListWithTitle from '../common/ListWithTitle';
import PreviewButton from '../common/PreviewButton';
import { templateType } from '../common/constants';
import CertTemplateEdit from './CertTemplateEdit';
import CertTemplateShow from './CertTemplateShow';
import ListActions from '../common/ListActions';

const CertTemplateList: React.FC = (): ReactElement => {
  const sideFilter = getSideFiltersKey(RESOURCE_CERT_TEMPLATE);
  const [showSideFilter] = useStore(sideFilter, false);
  const defaultFilter = useDefaultFilter('division');

  return (
    <ListWithTitle
      filters={<FreeTextSearchFilter />}
      filterDefaultValues={defaultFilter}
      actions={
        <ListActions
          sideFilter={sideFilter}
          defaultFilter={defaultFilter}
          actions={
            <CopyTemplatesButton templateType={templateType.certificate} />
          }
        />
      }
      aside={
        showSideFilter ? (
          <CertTemplateSideFilters defaultFilter={defaultFilter} />
        ) : null
      }
      sort={{ field: 'certName', order: 'ASC' }}
    >
      <DatagridConfigurableRbac
        ShowComponent={<CertTemplateShow withoutActions />}
        EditComponent={<CertTemplateEdit syncWithLocation={false} />}
        defaultVisibleColumns={[
          'id',
          'certName',
          'description',
          'isVendorCert',
          'category',
        ]}
      >
        <TextField source="id" label="ID" />
        <TextField source="certName" label="Certificate" />
        <TextField source="description" label="Description" />
        <CustomBooleanField source="isVendorCert" label="Vendor" />
        <CertTemplateCategoryField />
        {renderMetaDataFields()}
        <ColumnActions label="Actions">
          <DownloadButton />
          <JSONFormShowButton title="Certificate Template Form" />
          <PreviewButton />
        </ColumnActions>
      </DatagridConfigurableRbac>
    </ListWithTitle>
  );
};

export default CertTemplateList;
