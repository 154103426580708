import React, { useCallback } from 'react';
import { Button, useGetRecordId, useRefresh, useRedirect } from 'react-admin';
import Box from '@mui/material/Box';

import commonStyles from '../../common/commonStyles';
import { updateContext } from '../../../provider/userContext';
import { TextFieldPropsWithOptionalSource } from '../../common/types';

const ColumnActions: React.FC<
  TextFieldPropsWithOptionalSource & { handleClose: () => void }
> = ({ handleClose }) => {
  const refresh = useRefresh();
  const redirect = useRedirect();
  const recordId = Number(useGetRecordId());

  const handleSelect = useCallback(() => {
    updateContext({ projectId: recordId });
    handleClose();
    refresh();
    redirect('/');
  }, [recordId, handleClose, refresh, redirect]);

  return (
    <Box sx={commonStyles.columnActionsBox}>
      <Button
        onClick={handleSelect}
        label="Select"
        className="projectSelectButton"
      />
    </Box>
  );
};

export default ColumnActions;
