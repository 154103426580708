import React, { ReactElement } from 'react';
import { TextField } from 'react-admin';
import { RESOURCE_PROJECT } from '../constants';
import SmartLinkField from './SmartLinkField';
import { FieldPropsWithOptionalSource } from './types';

interface ProjectFieldProps extends FieldPropsWithOptionalSource {
  label?: string;
  source?: string;
}
const ProjectField: React.FC<ProjectFieldProps> = ({
  label,
  source,
  ...rest
}): ReactElement => (
  <SmartLinkField
    source={source}
    label={label}
    resource={RESOURCE_PROJECT}
    sortBy="project.project"
    {...rest}
  >
    <TextField source="project.project" />
  </SmartLinkField>
);

ProjectField.defaultProps = { label: 'Project Name', source: 'projectId' };

export default ProjectField;
